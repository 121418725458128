<script>
/**
 * Custom Vue component wrapping the noUiSlider library.
 * @module NoUiSliderCustom
 * @vue-prop {number} min - The minimum value of the slider.
 * @vue-prop {number} max - The maximum value of the slider.
 * @vue-prop {string} title - The title of the slider.
 */
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import {BASE} from "@/mixins";
import {mapActions} from "vuex";
import {convertToFeet} from "@/utilities/service";

export default {
  name: "NoUiSliderCustom",
  mixins: [BASE],
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    param: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rangeStart: this.min,
      rangeEnd: this.max,
      slider: null,
      flag: true,
      isDragging: false
    };
  },
  mounted() {
    this.initializeSlider();
  },

  methods: {
    convertToFeet,
    ...mapActions(["fetchYachts"]),
    updateUrlParams(start, end) {
      const queryParams = new URLSearchParams(window.location.search);
      if (start === this.min && end === this.max) {
        queryParams.delete(this.param);
      } else {
        const paramValue = `${start}-${end}`;
        queryParams.set(this.param, paramValue);
      }
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
    },
    initializeSlider() {
      if (this.min !== undefined && this.max !== undefined) {
        const queryParams = new URLSearchParams(window.location.search);
        const paramValue = queryParams.get(this.param);
        if (paramValue) {
          const [start, end] = paramValue.split('-');
          this.rangeStart = parseFloat(start);
          this.rangeEnd = parseFloat(end);
          this.flag = !this.flag;
        }

        if (this.slider) {
          this.slider.destroy();
        }

        this.slider = noUiSlider.create(this.$refs.rangeSlider, {
          start: [this.rangeStart, this.rangeEnd],
          range: {
            min: parseFloat(this.min),
            max: parseFloat(this.max)
          },
          step: 1,
          connect: true,
          behaviour: 'drag',
          format: {
            from: value => parseFloat(value),
            to: value => Math.round(value)
          }
        });

        this.slider.on("start", () => {
          this.isDragging = true;
        });

        this.slider.on("update", values => {
          const [start, end] = values.map(value => parseFloat(value));
          this.rangeStart = start;
          this.rangeEnd = end;
        });

        this.slider.on("change", values => {
          const [start, end] = values.map(value => parseFloat(value));
          if (this.isDragging) {
            this.rangeStart = start;
            this.rangeEnd = end;
            this.updateUrlParams(start, end);
            this.flag = !this.flag;
            this.fetchYachts();
          }
          this.isDragging = false;
        });

      }
    }
  },
  watch: {
    min(newMin) {
      this.rangeStart = newMin;
      this.initializeSlider();
    },
    max(newMax) {
      this.rangeEnd = newMax;
      this.initializeSlider();
    }
  },
};
</script>


<template>
  <div class="range-item">
    <div class="range-item__title">{{ title }}</div>
    <div class="range-item__slider" ref="rangeSlider"></div>
    <div class="range-item__values">
      <div>
        <template v-if="param === 'year'"><</template>
        {{ rangeStart }}
        <template v-if="param === 'price'">€</template>
        <template v-if="param === 'length'">m. ({{ convertToFeet(rangeStart) }} ft)</template>
      </div>
      <div>{{ rangeEnd }}
        <template v-if="param === 'price'">€</template>
        <template v-if="param === 'length'">m. ({{ convertToFeet(rangeEnd) }} ft)</template>
      </div>
    </div>
  </div>
</template>

<style>
.noUi-touch-area {
  height: 25px;
  width: 25px;
  transform: translateX(-10px);
}

.noUi-handle:hover {
  transform: scale(1.5);
  transition: all 500ms ease;
}

.noUi-target {
  background-color: #d1cccc;
}
</style>

