<template>
    <h6>{{ content }}</h6>
</template>

<script>
export default {
  name: 'H6Content',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
