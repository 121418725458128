import axios from "axios";
import router from "@/router";

const state = {
    user: {
        display_name: '',
        email: '',
        first_name: '',
        last_name: '',
        births_day: null,
        phone: null,
        subscribed: false,
        favorites: [],
        orders: [],
        success: true,
        firstName: '',
        lastName: '',
        authentificated: false
    },
    token: localStorage.getItem('authToken') || null
};

const getters = {
    getUser: state => state.user,
    getToken: state => state.token,
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('authToken', token);
    },
    logoutUser(state) {
        state.user = null;
        state.token = null;
        localStorage.removeItem('authToken');
    }
};

const actions = {
    async loginUser({ commit }, {user, fromGoogle = false }) {
        const url = fromGoogle ? 'https://api.anydaycharter.com/api/google-auth' : 'https://api.anydaycharter.com/api/login';
        const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${tokenBearer}`
            },
            body: JSON.stringify(user)
        };
        try {
            const response = await fetch(url, options);

            const data = await response.json();
            commit('setToken', data.token);
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
        // window.location.reload();
    },
    async registerUser({ commit } , user) {
        if (!user.email || !user.password || !user.passwordConfirm) {
            alert("All fields are required");
            return;
        }

        if (user.password.length < 8) {
            alert("Password must be at least 8 characters");
            return;
        }

        if (user.password !== user.passwordConfirm) {
            alert("Passwords do not match");
            return;
        }

        const url = 'https://api.anydaycharter.com/api/registration';
        const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${tokenBearer}`
            },
            body: JSON.stringify({
                first_name: user.firstName,
                last_name: user.lastName,
                email: user.email,
                password: user.password,
                password_confirmation: user.passwordConfirm
            })
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();

            if (data.token) {
                commit('setToken', data.token);
                localStorage.setItem('authToken', data.token)

            } else {
                alert(data);
            }
        } catch (error) {
            console.error(error);
            alert("Registration failed");
        }
        // window.location.reload();
    },
    async getUserInfo({ commit }, {accessToken, innerUser = false}) {

        const url = !innerUser ? 'https://www.googleapis.com/oauth2/v2/userinfo' :  'https://api.anydaycharter.com/api/profile';
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            const currentUser =  response.data;
            if(currentUser) {
                if(innerUser){
                    currentUser.firstName = currentUser.first_name;
                    currentUser.lastName = currentUser.last_name;
                }else {
                    currentUser.firstName = currentUser.given_name;
                    currentUser.lastName = currentUser.family_name;
                }

                currentUser.authentificated = true;
                commit('setUser', currentUser);
                return currentUser;
            }

        } catch (error) {
            console.error('Error getting user info:', error);
            throw error;
        }
    },
    async logoutUser({ commit }) {
        commit('logoutUser');
        await router.push('/')
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
