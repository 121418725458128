<template>
  <div class="blog-inner__wrapper">
    <div class="blog-inner__image">
<!--      <picture>-->
<!--        <source :srcset="content.thumb" media="(max-width: 400px)">-->
<!--        <source :srcset="content['400']" media="(max-width: 800px)">-->
<!--        <source :srcset="content['800']" media="(min-width: 801px)">-->
        <img :src="content['800']" :alt="content.alt || content.original">
<!--      </picture>-->
      <figcaption v-if="content.caption" class="caption-blog">{{ content.caption }}</figcaption>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageContent',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
<style>

.caption-blog {
  color: #bbb7b7;
  text-align: center;
  margin-top: 10px;
  font-style: italic;
  font-size: 15px;
}
</style>