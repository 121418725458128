<template>
    <h2>{{ content }}</h2>
</template>

<script>
export default {
  name: 'H2Content',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
