<script>
import { GoogleMap, InfoWindow } from 'vue3-google-map';

export default {
  name: "BoatViewMap",
  components: {
    GoogleMap,
    InfoWindow
  },
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      apiKey: 'AIzaSyD_AWzCnbsg9m9z7bxt4PdGCsFcPEoQAtA',
      center: { lat: 0, lng: 0 },
      infoWindowOptions: {}
    };
  },
  computed: {
    getFlagClass() {
      if (this.yacht.base && this.yacht.base.country.short) {
        return `fi icon-flag fi-${this.yacht.base.country.short.toLowerCase()}`;
      }
      return '';
    }
  },
  watch: {
    yacht: {
      immediate: true,
      handler(newYacht) {
        if (newYacht && newYacht.base) {
          this.center = {
            lat: +newYacht.base.latitude,
            lng: +newYacht.base.longitude
          };
          this.infoWindowOptions = {
            position: this.center,
            content: newYacht.name
          };
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>

<template>
  <div class="modal">
    <div class="modal__close" @click="closeModal">
      <img src="/images/modal-close.svg" alt="Close icon"/>
    </div>
    <div class="modal__wrapper modal__wrapper--map">
      <div class="modal__map" id="modal__map--yacht">
        <div class="modal__map-title">Map</div>
        <div class="modal__map-wrapper modal__map-wrapper-yacht">
          <GoogleMap
              :api-key="apiKey"
              style="width: 100%; height: 100%"
              :center="center"
              :zoom="15"
          >
            <InfoWindow :options="infoWindowOptions">
              {{ yacht.name }}
            </InfoWindow>
          </GoogleMap>
        </div>
      </div>
      <div class="modal__filter">
        <div class="tab-content" id="tab-content--yacht">
          <a class="yaht" href="#">
            <div class="yaht__info" id="yacht__info">
              <div class="yaht__name">
                <span :class="getFlagClass"></span>
                <span>{{ yacht.model.name }} | {{ yacht.name }}</span>
              </div>
              <div class="yaht__place">
                {{ yacht.base.city }}, {{ yacht.base.name }}
              </div>
              <div class="yacht__preview">
                <div class="yaht__image">
                  <img src="/images/yaht-1.png" alt="Main image yacht"/>
                </div>
                <object class="yaht__actions yacht__actions">
                  <div class="yaht__rate">
                    <span>4.4</span>
                    <span>3 Reviews</span>
                  </div>
                  <a href="#">View yacht</a>
                </object>
              </div>
            </div>
          </a>
          <div class="card__lists card__lists--yacht" id="yacht">
            <ul>
              <li>
                <div class="card__list-title"><img src="/images/date.svg" alt="Year built">Year</div>
                <span></span>
                <div class="card__list-value">{{ yacht.year }}</div>
              </li>
              <li>
                <div class="card__list-title"><img src="/images/people.svg" alt="Max passengers">People
                </div>
                <span></span>
                <div class="card__list-value">{{ yacht.max_people || 'NA' }}</div>
              </li>
              <li>
                <div class="card__list-title"><img src="/images/cabins.svg" alt="Cabins amount">Cabins
                </div>
                <span></span>
                <div class="card__list-value">{{ yacht.cabins }}</div>
              </li>
              <li>
                <div class="card__list-title">
                  <img src="/images/location.svg" alt="Location place">Location
                </div>
                <span></span>
                <div class="card__list-value">Spain</div>
              </li>
              <li>
                <div class="card__list-title">
                  <img src="/images/berths.svg" alt="Berths amount">Berths
                </div>
                <span></span>
                <div class="card__list-value">{{ yacht.berths }}</div>
              </li>
              <li>
                <div class="card__list-title"><img src="/images/toilets.svg" alt="WC's amount">Toilets
                </div>
                <span></span>
                <div class="card__list-value">{{ yacht.wc }}</div>
              </li>
            </ul>
            <h2 class="card__heading-name">Other information</h2>
            <div class="card__lists card__lists--yacht" id="yacht-extra">
              <ul>
                <li>
                  <div class="card__list-title">Type of boat:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.type && yacht.type.name || 'NA' }}</div>
                </li>
                <li>
                  <div class="card__list-title">Brand:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.shipyard && yacht.shipyard.name }}</div>
                </li>
                <li>
                  <div class="card__list-title">Beam / draft:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.beam || 'NA' }} m / {{ yacht.draft || 'NA' }}
                    m
                  </div>
                </li>
                <li>
                  <div class="card__list-title">Water tank:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.waterCapacity || 'NA' }}</div>
                </li>
                <li>
                  <div class="card__list-title">Model:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.company && yacht.company.name }}</div>
                </li>
                <li>
                  <div class="card__list-title">Length:</div>
                  <span></span>
                  <div class="card__list-value">{{ parseFloat(yacht.length).toFixed(2) }} m</div>
                </li>
                <li>
                  <div class="card__list-title">Fuel tank:</div>
                  <span></span>
                  <div class="card__list-value">{{ yacht.fuelCapacity }}</div>
                </li>
                <li>
                  <div class="card__list-title">Inside showers:</div>
                  <span></span>
                  <div class="card__list-value">1</div>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style>
#tab-content--yacht {
  width: 100%;
  margin-top: 20px;
  padding: 0;
}

#modal__map--yacht {
  width: 70%;
}

@media (max-width: 767px) {
  #modal__map--yacht {
    width: 100%;
  }
}


#yacht__info {
  padding: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.yacht__preview {
  display: flex;
  gap: 30px;
}

.yacht__actions {
  gap: 30px;
  justify-content: space-between;
}

#yacht {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
}

#yacht li {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.card__lists--yacht, #yacht ul {
  width: 100%;
}

#yacht-extra {
  padding: 0;
}
.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 40px;
  min-width: 40px;
  height: 30px;
}

</style>
