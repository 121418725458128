<script>
export default {
  name: "ReviewsView"
}
</script>

<template>
  <div class="cabinet-page">
    <div class="container">
      <div class="page-head">
        <h1 class="page-head__title">Customer reviews</h1>
      </div>
      <div class="reviews-page">
        <div class="reviews-page__info">
          <div class="reviews-page__item">Customer service is not just one of our department. It’s our entire company</div>
          <div class="reviews-page__item">We spoil our customers. From the moment you make your booking until it’s time for you to return home - and even afterwards - our team is here just in case something goes awry. As a result, we’re honoured this year again to have been awarded the highest ratings from clients who have experienced us first-hand! Check out some of their reviews.</div>
          <div class="reviews-page__image"><img src="/images/reviews.png" alt="reviews"></div>
        </div>
        <div class="reviews-page__body"><img src="/images/reviews-fish.png" alt="reviews-fish"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>