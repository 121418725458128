<script>
export default {
  name: "LoginForm",
  data(){
    return {
      showForm: false
    }
  }
}
</script>

<template>
  <div class="modal modal--small" id="sign-in" v-if="showForm">
    <div class="modal__close"><img src="/images/modal-close.svg"/></div>
    <div class="modal__wrapper">
      <div class="modal__title">Sign in</div>
      <div class="sign-in"><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-1.svg"/></div>
        <div class="sign-in__text">Sign in with Facebook</div></a><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-2.svg"/></div>
        <div class="sign-in__text">Sign in with Google</div></a><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-3.svg"/></div>
        <div class="sign-in__text">Sign in with Apple</div></a>
        <div class="sign-in__devider">or</div><a class="sign-in__item" href="#">
          <div class="sign-in__icon"><img src="/images/sign-in-4.svg"/></div>
          <div class="sign-in__text">Sign in with E-mail</div></a>
        <div class="sign-in__prompt">Don't have an account yet?</div><a class="sign-in__create" href="#">Create a new account</a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>