<script>
export default {
  name: "UnderConstruction"
}
</script>

<template>
  <div class="under-construction">
    <div class="container">
      <div class="under-construction__subtitle">The site is</div>
      <div class="under-construction__title"><span>Under </span><span>Construction</span></div><img src="/images/under-construction.png">
    </div>
  </div>
</template>

<style scoped>

</style>