<script>
import LanguageSelector from "@/components/globals/LanguageSelector.vue";
import {mapGetters, mapActions } from "vuex";
import {GoogleLogin} from 'vue3-google-login';
import axios from "axios";
import FavouriteModalCard from "@/components/partials/FavouriteModalCard.vue";
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import {toggleFavorite} from "@/utilities/service";
import {restorePassword} from "@/api";

export default {
  name: "HeaderView",
  props: {
    pages: Array
  },
  directives: {
    clickOutSide,
  },
  components: {FavouriteModalCard, LanguageSelector, GoogleLogin},
  data() {
    return {
      isModalOpen: false,
      showSettingsDropdown: false,
      currentModal: null,
      showLoginFields: false,
      showModalFavorites: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        authentificated: false,
        favorites: [],
      },
      isScrolled: false,
    };
  },
  computed: {
    ...mapGetters(["getLanguages", "getCurrentLanguage", "getToken", "getFavouriteYachts"]),
    headerClass() {
      return this.$route.name === 'HomeView' ? 'header' : 'header--inner header';
    },
    userDisplayName(){
      if(this.user.firstName && this.user.lastName){
        return this.user.firstName[0] + '' + this.user.lastName[0];
      } else {
        return this.user.name[0]
      }
    },
    countFavorites(){
      return this.getFavouriteYachts ? this.getFavouriteYachts.length : 0;
    },
    dropdownStyle() {
      return {
        top: this.isScrolled ? '65px' : '90px',
        // position: this.isScrolled ? 'fixed' : 'absolute',
      };
    },
    settingsDropdownStyle(){
      return {
        top: this.isScrolled ? '60px' : '120px',
      };
    }

  },
  methods: {
    toggleFavorite,
    restorePassword,
    ...mapActions(["fetchLanguages", "setCurrentLanguage", "loginUser", "registerUser", "logoutUser", "getUserInfo", "fetchFavouriteYachts"]),
    savePageState() {
      sessionStorage.setItem('pageState', JSON.stringify({
        path: '/en',
        scrollTop: 0
      }));
    },
    toggleSettingsMenu(){
      this.showSettingsDropdown = !this.showSettingsDropdown;
      this.showModalFavorites = false;
    },
    toggleFavoritesMenu(){
      this.showModalFavorites = !this.showModalFavorites;
      this.showSettingsDropdown = false;
    },
    openModal(modalName) {
      this.isModalOpen = true;
      this.currentModal = modalName;
      this.showSettingsDropdown = false;
        document.body.style.overflow = "hidden";

      this.$nextTick(() => {
        this.$refs.overlay.classList.add("visible");
      });
    },
    closeModal() {
      this.$refs.overlay.classList.remove("visible");
      document.body.style.overflow = "auto";
      setTimeout(() => {
        this.isModalOpen = false;
        this.currentModal = null;

      }, 300);
    },

    async login() {
      try {
        await this.loginUser({user:this.user});
        this.user = await this.getUserInfo({accessToken:this.getToken, innerUser: true});
        this.closeModal();
        this.$notify({
          title: "Success!",
          text: "You successfully logged in",
        });
      } catch (error) {
        alert('Login failed: ' + error.message);
      }
    },
    async register(){
      try {
        await this.registerUser(this.user);
        this.closeModal();
        this.user = await this.getUserInfo({accessToken:this.getToken, innerUser: true});
        this.$notify({
          title: "Success!",
          text: "You successfully registered",
        });
      } catch (error) {
        alert('Register failed: ' + error.message);
      }
    },
    async handleGoogleLogin(response) {
      try {
        const tokenResponse = await this.exchangeCodeForToken(response.code);
        const accessToken = tokenResponse.access_token;

        this.user = await this.getUserInfo({accessToken:accessToken});
        await this.loginUser({user: this.user, fromGoogle: true});
        this.closeModal();
        this.$notify({
          title: "Success!",
          text: "You successfully logged in",
        });
      } catch (error) {
        console.error('Error handling Google auth:', error);
        throw error;
      }
    },

    async exchangeCodeForToken(code) {
      const url = 'https://oauth2.googleapis.com/token';
      const params = new URLSearchParams();
      params.append('code', code);
      params.append('client_id', process.env.VUE_APP_GOOGLE_CLIENT_ID);
      params.append('client_secret', process.env.VUE_APP_GOOGLE_CLIENT_SECRET);
      params.append('redirect_uri', process.env.VUE_APP_REDIRECT_URI);
      params.append('grant_type', 'authorization_code');

      try {
        const response = await axios.post(url, params);
        return response.data;
      } catch (error) {
        console.error('Error exchanging code for token:', error);
        throw error;
      }
    },

    signOut(){
      this.showSettingsDropdown=false;
      this.user.authentificated = false;
      this.logoutUser();
      this.$notify({
        title: "Success!",
        text: "You successfully logged out",
      });
    },
    handleClickOutside(event){
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.showSettingsDropdown = false;
     }
    },
    async updateUserInfo() {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        this.user = await this.getUserInfo({accessToken: this.getToken, innerUser: true});
      }
    },
    handleScroll() {
      const pageScroll = window.scrollY;
      this.isScrolled = pageScroll > 20;
    }

  },

  async beforeMount() {
    if (!this.getLanguages.length) {
      await this.fetchLanguages();
    }
    const storedLanguage = localStorage.getItem("user-locale") || "en";
    this.setCurrentLanguage(storedLanguage);

  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('scroll', this.handleScroll);
    await this.updateUserInfo();
    await this.fetchFavouriteYachts();


  },
  async beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    countFavorites(newVal, oldVal) {
      if (newVal === 0) {
        this.showModalFavorites = false;
      }
    }
  }
}
;
</script>
<template>
  <header :class="headerClass">
    <div class="container">
      <a class="logo" :href="`/${$i18n.locale}`" @click="savePageState">
        <img src="/images/logo.svg" alt="Main logo"/>
      </a>
      <nav class="nav">
        <a v-for="page in pages" :key="page.slug" :href="`/${$i18n.locale}/${page.slug}`"
           class="nav__link">{{ page.title }}</a>
      </nav>
      <LanguageSelector :currentLanguage="getCurrentLanguage" :availableLocales="getLanguages"/>
      <div class="header__actions">
        <div class="settings__dropdown" v-if="showSettingsDropdown" ref="dropdown" :style="settingsDropdownStyle">
          <div class="settings__dropdown-row">
            <div class="settings__dropdown-icon">
              <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.31523 15.7966L2.84065 20.2712C2.16947 20.9424 2.02031 21.8373 2.31862 22.6576C2.6915 23.478 3.43726 24 4.33218 24H15.6678C16.5627 24 17.3084 23.478 17.6813 22.6576C17.9796 21.8373 17.8305 20.9424 17.1593 20.2712L6.71862 9.83051C4.92879 8.04068 4.92879 5.1322 6.71862 3.34237C8.58303 1.55254 11.4915 1.55254 13.2813 3.34237C15.0712 5.1322 15.0712 8.04068 13.2813 9.83051L12.7593 10.3525" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
              </svg>

            </div>
            <div v-if="user && user.authentificated" class="settings__dropdown-title" >
              <a :href="`/${$i18n.locale}/dashboard`">{{ $t('actions.personal_account') }}</a>
            </div>
            <div v-else class="settings__dropdown-title" @click.prevent="openModal('signIn')">{{ $t('actions.sign_in') }}</div>
          </div>
          <div class="settings__dropdown-row" v-if="user && user.authentificated">
            <div class="settings__dropdown-icon">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0451 16.4318V22H2.31787V2H15.0451V7.39773" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
                <path d="M8.90881 12H19.1361" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
                <path d="M18.2269 8.5448L21.6821 12L18.2269 15.4552V8.5448Z" fill="#BF8D37"/>
              </svg>

            </div>
            <div  class="settings__dropdown-title" @click.prevent="signOut">{{ $t('actions.sign_out') }}</div>
          </div>
          <div class="settings__dropdown-row" v-else>
            <div class="settings__dropdown-icon">
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0451 16.4318V22H2.31787V2H15.0451V7.39773" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
                <path d="M8.90881 12H19.1361" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
                <path d="M18.2269 8.5448L21.6821 12L18.2269 15.4552V8.5448Z" fill="#BF8D37"/>
              </svg>

            </div>
            <div  class="settings__dropdown-title" @click.prevent="openModal('registration')">{{ $t('actions.register') }}</div>
          </div>
        </div>
        <a v-if="countFavorites" class="header__actions-link" @click.stop="toggleFavoritesMenu">
          <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0005 5.3631L15.6493 3.69957C18.0025 1.43348 21.6887 1.43348 23.9619 3.69957C26.2393 6.04542 26.2393 9.7239 23.9619 11.99L14.0005 22L4.03486 11.99C1.76165 9.7239 1.76165 6.04542 4.03486 3.69957C6.31225 1.43348 9.99842 1.43348 12.3516 3.69957L14.0005 5.3631Z" fill="#BF8D37" stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"/>
          </svg>
          <div>{{ countFavorites }}</div>
        </a>
        <a v-else class="header__actions-link" >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246"
                stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"></path>
          </svg>
        </a>

        <a class="header__actions-link" @click.stop="toggleSettingsMenu">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.3152 17.7966L7.84065 22.2712C7.16947 22.9424 7.02031 23.8373 7.31862 24.6576C7.6915 25.478 8.43726 26 9.33218 26H20.6678C21.5627 26 22.3084 25.478 22.6813 24.6576C22.9796 23.8373 22.8305 22.9424 22.1593 22.2712L11.7186 11.8305C9.92879 10.0407 9.92879 7.1322 11.7186 5.34237C13.583 3.55254 16.4915 3.55254 18.2813 5.34237C20.0712 7.1322 20.0712 10.0407 18.2813 11.8305L17.7593 12.3525"
                stroke="#BF8D37" stroke-width="3" stroke-miterlimit="22.9256"></path>
          </svg>
        </a>
      </div>
      <div class="user-info" v-if="user && user.authentificated">
        <span>{{ userDisplayName }}</span>
      </div>
    </div>

  </header>
  <div v-if="showModalFavorites" :class="['favorites__dropdown', { 'fixed-position': isScrolled }]"
       :style="dropdownStyle" v-click-out-side="toggleFavoritesMenu">
    <div class="favorites__dropdown-title">{{ $t('interface.your_list')}}</div>
    <transition-group
        name="fade-slide"
        tag="div"
        class="favorites__dropdown-rows"
    >
    <FavouriteModalCard v-for="yacht in getFavouriteYachts" :yacht="yacht" :key="yacht.slug" class="favorites__dropdown-row"/>
    </transition-group>
  </div>
  <div class="overlay" v-if="isModalOpen" ref="overlay">
    <div class="modal modal--small modal_sign_in" @click.self="closeModal">
      <div v-if="currentModal === 'signIn'">
        <div class="modal__close" @click="closeModal"><img src="/images/modal-close.svg"/></div>
        <div class="modal__wrapper">
          <div class="modal__title">{{ $t('actions.sign_in') }}</div>
          <div class="sign-in">

            <!-- Google Login Button -->
            <GoogleLogin :callback="handleGoogleLogin">
              <a class="sign-in__item" href="#">
                <div class="sign-in__icon"><img src="/images/sign-in-2.svg"/></div>
                <div class="sign-in__text">{{ $t('actions.sign_in_with_google') }}</div>
              </a>
            </GoogleLogin>

            <div class="sign-in__devider">or</div>
            <a class="sign-in__item" @click="showLoginFields=!showLoginFields">
              <div class="sign-in__icon"><img src="/images/sign-in-4.svg"/></div>
              <div class="sign-in__text">{{ $t('actions.sign_in_with_email') }}</div>
            </a>
            <div v-if="showLoginFields">
              <div class="form-group form-group__sign_in">
                <label><img src="/images/required.svg"/>{{ $t('user.email') }}</label>
                <input type="email" v-model="user.email"/>
              </div>
              <div class="form-group form-group__sign_in">
                <label><img src="/images/required.svg"/>{{ $t('user.password') }}</label>
                <input type="password" v-model="user.password"/>
                <div class="sign-in__prompt">{{ $t('actions.sign_in_with_email') }}{{ $t('actions.forgot_your_password') }}<a @click="openModal('restore-password')">{{ $t('actions.click_here') }}</a></div>
              </div>
              <div class="login-button">
                <a class="sign-in__create sign-in__create--log" @click.prevent="login">{{ $t('actions.log_in') }}</a>
              </div>

            </div>
            <div class="sign-in__prompt">{{ $t('actions.dont_have_an_account_yet') }} </div>
            <a class="sign-in__create" href="#" @click.prevent="openModal('registration')">{{ $t('actions.create_new_account') }}</a>
          </div>
        </div>
      </div>
      <div v-else-if="currentModal === 'registration'">
        <div class="modal__close" @click="closeModal"><img src="/images/modal-close.svg"/></div>
        <div class="modal__wrapper">
          <div class="modal__title">{{ $t('actions.create_new_account') }}</div>
          <div class="registration">
            <div class="form-group">
              <label>{{ $t('user.first_name') }}</label>
              <input type="text" v-model="user.firstName"/>
            </div>
            <div class="form-group">
              <label>{{ $t('user.last_name') }}</label>
              <input type="text" v-model="user.lastName"/>
            </div>
            <div class="form-group">
              <label><img src="/images/required.svg"/>{{ $t('user.email') }}</label>
              <input type="text" v-model="user.email"/>
            </div>
            <div class="form-group">
              <label><img src="/images/required.svg"/>{{ $t('user.password') }}</label>
              <input type="password" v-model="user.password"/>
            </div>
            <div class="form-group">
              <label><img src="/images/required.svg"/>{{ $t('user.password_confirm') }}</label>
              <input type="password" v-model="user.passwordConfirm"/>
            </div>
            <div class="registration__descr"><img src="/images/required.svg"/>{{ $t('actions.required_field') }}</div>
            <div class="registration__info">{{ $t('interface.by_signing_in_or_creating_account_you_agree_to_our') }} <a href="#">{{ $t('interface.terms_and_policies') }}</a> {{ $t('interface.and') }} <a href="#">{{ $t('interface.privacy_policy') }}</a> {{ $t('interface.and') }} {{ $t('interface.cookies') }}.
            </div>
            <a class="btn" href="#" @click.prevent="register"><span>{{ $t('actions.create_new_account') }}</span></a>
            <div class="sign-in__devider">or</div>

            <GoogleLogin :callback="handleGoogleLogin">
              <a class="sign-in__item" href="#">
                <div class="sign-in__icon"><img src="/images/sign-in-2.svg"/></div>
                <div class="sign-in__text">{{ $t('actions.sign_in_with_google') }}</div>
              </a>
            </GoogleLogin>

            <div class="sign-in__prompt">{{ $t('actions.already_have_an_account') }}</div>
            <a class="sign-in__create" href="#" @click.prevent="openModal('signIn')">{{ $t('actions.sign_in') }}</a>
          </div>
        </div>
      </div>
      <div v-else-if="currentModal === 'restore-password'">
        <div class="modal__close" @click="closeModal"><img src="/images/modal-close.svg"/></div>
        <div class="modal__wrapper">
          <div class="modal__title">{{ $t('actions.recover_your_account') }}</div>
          <div class="sign-in">
            <div>{{ $t('actions.kindly_enter_your_email') }} {{ $t('actions.well_send_you_recovery_letter') }}</div>


            <div  >
              <div class="form-group form-group__sign_in">
                <label><img src="/images/required.svg"/>{{ $t('actions.enter_your_email_address') }}</label>
                <input type="email" v-model="user.email"/>
              </div>

              <div class="login-button">
                <a class="sign-in__create sign-in__create--log" @click.prevent="restorePassword(user.email)">{{ $t('actions.send') }}</a>
              </div>

            </div>
            <div class="sign-in__prompt"></div>
            <a class="sign-in__create"  @click.prevent="openModal('signIn')">{{ $t('actions.back') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.favorites__dropdown {
  position: fixed;
  right: 275px;
  background-color: white;
  width: 350px;
  max-height: 600px;
  overflow-y: auto;
  z-index: 2000;
  top: 90px;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 500ms;
}
@media (max-width: 767px) {
  .favorites__dropdown {
    right: 15px;
  }
  .yaht {
    flex-direction: row;
    padding-top: 40px;
  }
}
.fixed-position {
  position: fixed;
}
.favorites__dropdown-title {
  color: #BF8D37;
  text-align: center;
  font-size: 18px;
  padding-top: 10px;
}
.favorites__dropdown-row:hover {
  background-color: #c5c2c2;
}
.favorites__dropdown::-webkit-scrollbar {
  width: 6px;
}

.favorites__dropdown::-webkit-scrollbar-thumb {
  background-color: #BF8D37;
  border-radius: 10px;
}

.favorites__dropdown::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.modal {
  max-width: 500px;
  position: relative;
  transform: scale(0.3);
  transition: transform 0.3s ease;
  top: -90px;

}


.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  height: 100dvh;
  width: 100%;
  z-index: 50000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

.overlay.visible .modal {
  transform: scale(0.8);
}

.login-button {
  cursor: pointer;
  margin-top: 20px;

}
.user-info {
  background-color: #21084D;
  color: white;
  height: 30px;
  font-size: 16px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;

  span {
    color: white;
    text-transform: uppercase;
  }
}
.settings__dropdown {
  position: fixed;
  right: 160px;
  width: 274px;
  height: auto;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 500ms;
}
.settings__dropdown-icon {
  width: 50px;
  text-align: center;
}
.settings__dropdown-row {
  display: flex;
  align-items: center;
  align-self: center;
  height: 50px;
  cursor: pointer;
}
.settings__dropdown-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.settings__dropdown-title:hover {
  color:#BF8D37;

}

@media (max-width: 767px) {
  .modal {
    left:auto;
    width: 100%;
    transform: scale(0.9);
    top: -50px;
  }
  .settings__dropdown {
    position: fixed;
    top: 65px;
    right: 42px;
  }
}
.header__actions-link div {
  position: relative;
  top: 15px;
  color:white;
  font-size: 14px;
  font-weight: 700;
}
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.modal_sign_in {
  height: fit-content;
  top:auto;
}
.form-group {
  gap:0;
}
.form-group input {
  margin: 10px 0;
}
</style>
