<script>
export default {
  name: "TermsView"
}
</script>

<template>
  <div class="container">
    <div class="page-nav">
      <div class="breadcrumbs"><a class="breadcrumbs__link" href="#">Home</a><span class="breadcrumbs__separator">/</span><span class="breadcrumbs__current">Terms and Conditions</span></div>
    </div>
    <div class="page-head">
      <div class="page-head__subtitle">Any Day charter</div>
      <h1 class="page-head__title">Terms and Conditions</h1>
    </div>
    <div class="terms">
      <p>As a condition of your use of this platform, you warrant that (i) you are at least 18 years of age and; (ii) you possess the legal authority to enter into a binding legal obligation. Any Day Charter.com reserves the right to deny access to anyone to this platform and to the services we offer.<br>These terms and conditions, as may be amended from time to time, apply to all our services directly or indirectly (through distributors) made available online, through any mobile device, by email or by telephone. By accessing, browsing, and using our website or any of our applications through whatever platform (collectively referred to as the “platform”) and/or by completing a reservation, you acknowledge and agree to have read, understood, and agreed to the terms and conditions set out below (including the privacy and cookies policy).<br>These pages, the content and infrastructure of these pages, and the online boat reservation service provided through the platform (the “service”) are owned, operated, and provided by Any Day Charter.com, a.s., Radlinskeho 47, 811 07 Bratislava, Slovakia (“Any Day Charter.com”, “us”, “we”, or “our”) and are provided for your personal, non- commercial use, subject to the terms and conditions set out below.</p>
      <h3>1. Our Service</h3>
      <p>Through the platform, we (Any Day Charter.com and its affiliate partners) provide an online platform through which all types of boat operators (for example, charters, marinas, individual boat owners, travel agents, collectively the “boat provider(s)”), can advertise their boats for rent, and through which visitors to the platform can make such rental. By making a rental through Any Day Charter.com, you enter into a direct (legally binding) contractual relationship with the boat provider from which you rent a boat. From the point at which you make your rental, we act solely as an intermediary between you and the boat provider, transmitting the details of your rental to the relevant boat provider and sending you a confirmation email on behalf of the boat provider.</p>
      <p>When offering our services, the information that we show is based on the information provided to us by the boat providers. As such, the boat providers are given access to an online system through which they are fully responsible for updating the rental price, availability, and other information which is displayed on our platform. Although we will use reasonable resources in performing our services we will not verify if, and cannot guarantee that all information is accurate, complete or correct, nor can we be held responsible for any errors (including typographical errors), any interruptions (whether due to any [temporary and/or partial] breakdown, repair, upgrade or maintenance of our platform or otherwise), inaccurate, misleading, or untrue information or non-delivery of information. Each boat provider remains responsible at all times for the accuracy, completeness, and correctness of the information (including the rental price and availability) displayed on our platform.</p>
      <p>Our services are made available for personal and non-commercial use only. Therefore, you are not allowed to use, copy, sell, display, or reproduce any content or information, products, or services available on our platform for any commercial or competitive activity.</p>
      <h3>2. Prices</h3>
      <p>Unless stated differently, all prices on the Any Day Charter.com platform are per boat for your entire reservation, including VAT and all other taxes. Please check the rental details thoroughly for any such conditions prior to making your reservation. Obvious errors and mistakes (including misprints) are not binding.</p>
      <p>Our service is free of charge, and we will not charge you for our service or add any additional commission to the boat rental price. Boat providers pay a service fee (a small percentage of the boat rental price) to us after the customer has paid the rental price.</p>
      <h3>3. Privacy and cookies</h3>
      <p>Any Day Charter.com respects your privacy. Please have a look at our privacy policy for further information.</p>
      <h3>4. Payment</h3>
      <p>Boat providers ask for the rental price to be paid (fully or partly) to guarantee the rentals. You will be asked to guarantee your rental during the reservation process. Payment is safely processed from your credit/debit card or bank account to the bank account of the boat provider through a third-party payment processor. Please check the rental details thoroughly for the payment conditions prior to making your rental.</p>
      <h3>5. Cancellation</h3>
      <p>By making a reservation on our platform, you accept and agree to the relevant cancellation policy of that boat provider, and to any additional terms and conditions of the boat provider that may apply to your rental, including services and/or products offered by the boat provider. The delivery terms and conditions of a boat provider can be obtained with the relevant boat provider.</p>
      <p>The cancellation policy is available on our platform on an individual rental page, during the reservation process, and in the confirmation email. Please note that some rentals are not eligible for cancellation or change. Please check the rental details for any such conditions prior to making your reservation. We recommend that you read the cancellation and payment policy of the rental carefully prior to making your rental and remember to make further payments on time.</p>
      <p>Please note that a rental may be cancelled (without prior notice) if the relevant (remaining) rental amount cannot be collected on the relevant payment date in accordance with the relevant payment policy of the rental. Late payment, wrong bank, debit or credit card details, invalid credit/debit cards, or insufficient funds are your responsibility. You shall not be entitled to any refund of the prepaid amount unless the boat provider agrees otherwise. If you wish to view, modify, or cancel your rental, please see the confirmation email, and follow the instructions there.</p>
      <p>To submit a claim, please contact Any Day Charter at </p>
    </div>
  </div>
</template>

<style scoped>

</style>