<template>
  <div class="blog-inner__gallery">
    <a
        v-for="image in content"
        :key="image.original"
        :href="image['800']"
        data-fancybox="gallery"
    >
      <img :src="image['400']" :alt="image.original">
    </a>
  </div>
</template>

<script>
export default {
  name: 'GalleryContent',
  props: {
    content: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.initFancyBox();
  },
  methods: {
    initFancyBox() {
      $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        buttons: [
          'slideShow',
          'fullScreen',
          'thumbs',
          'close'
        ]
      });
    }
  }
}
</script>

<style scoped>
.blog-inner__gallery {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  justify-items: center;
}

.blog-inner__gallery a {
  margin: 5px;
  width: 100%;
}

.blog-inner__gallery img {
  height: 100%;
  width: auto;
}
</style>
