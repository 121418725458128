import { createStore } from 'vuex';
import languages from './modules/languages';
import countries from './modules/countries';
import yachts from './modules/yachts';
import yachtTypes from './modules/yachtTypes';
import selectedYacht    from "./modules/selectedYacht";
import destinations from "@/store/modules/destinations";
import favouriteYachts from "@/store/modules/favouriteYachts";
import selectedDestination from "@/store/modules/selectedDestination";
import equipments from "@/store/modules/equipments";
import auth from "@/store/modules/auth";

export default createStore({
    modules: {
        languages,
        countries,
        yachts,
        yachtTypes,
        selectedYacht,
        destinations,
        favouriteYachts,
        selectedDestination,
        equipments,
        auth
    }
});
