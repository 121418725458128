<template>
    <h4>{{ content }}</h4>
</template>

<script>
export default {
  name: 'H4Content',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
