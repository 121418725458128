<script>

import {generateSlug} from "@/utilities/service";

export default {
  name: "BreadCrumbs",
  props: {
    base: {
      type: Object,
      required: false
    },
    place: {
      type: String,
      required: false
    }
  },
  mounted() {
  },
  methods: {generateSlug}
}
</script>

<template>
  <div class="breadcrumbs">
    <a class="breadcrumbs__link" :href="`/${$i18n.locale}`">{{ $t('interface.home') }}</a>
    <span v-if="place" class="breadcrumbs__separator">/</span>
    <span v-if="place" class="breadcrumbs__link" >{{ place }}</span>
    <span v-if="base && base.country" class="breadcrumbs__separator">/</span>
    <a v-if="base && base.country" class="breadcrumbs__link" :href="`/${$i18n.locale}/search?destinations=${generateSlug(base.country.name)}`">{{ base.country.name }}</a>
    <span v-if="base && base.city" class="breadcrumbs__separator">/</span>
    <a v-if="base && base.city" class="breadcrumbs__current" :href="`/${$i18n.locale}/search?destinations=city_${generateSlug(base.city)}`">{{ base.city }}</a>
    <span v-if="base && base.name" class="breadcrumbs__separator">/</span>
    <a v-if="base && base.name" class="breadcrumbs__current" :href="`/${$i18n.locale}/search?destinations=${generateSlug(base.name)}`">{{ base.name }}</a>
  </div>
</template>

<style scoped>

</style>