<script>
import TextContent from "@/components/partials/templates/TextContent.vue";
import TextareaContent from "@/components/partials/templates/TextareaContent.vue";
import RichTextContent from "@/components/partials/templates/RichTextContent.vue";
import ImageContent from "@/components/partials/templates/ImageContent.vue";
import GalleryContent from "@/components/partials/templates/GalleryContent.vue";
import {mapGetters} from "vuex";
import H1Content from "@/components/partials/templates/H1Content.vue";
import H2Content from "@/components/partials/templates/H2Content.vue";
import H3Content from "@/components/partials/templates/H3Content.vue";
import H4Content from "@/components/partials/templates/H4Content.vue";
import H5Content from "@/components/partials/templates/H5Content.vue";
import H6Content from "@/components/partials/templates/H6Content.vue";
import BlogInnerView from "@/views/blog/BlogInnerView.vue";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";

export default {
  name: "RichTemplate",
  components: {
    LoadingIndicator,
    BlogInnerView,
    TextContent,
    TextareaContent,
    RichTextContent,
    ImageContent,
    GalleryContent,
    H1Content,
    H2Content,
    H3Content,
    H4Content,
    H5Content,
    H6Content
  },
  data() {
    return {
      pageData: {},
      isBlogPage: false,
      currentSlug: "",
      showIndicator: true,
      blogData : {
        categoryTitle: "",
        created_at: ""
      }
    }
  },
  async mounted() {
    this.currentSlug = this.$route.path.split("/")[2];
    await this.fetchBlogListingPage();
    await this.fetchContent();

  },
  methods: {
    async fetchContent() {
      const locale = this.$i18n.locale;

      const url = this.isBlogPage ? 'https://api.anydaycharter.com/api/blog/' + locale + '/' + this.currentSlug : 'https://api.anydaycharter.com/api/page/' + locale + '/' + this.currentSlug;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.pageData = data.content;
        if (data.title) {
          this.pageData.title = data.title;
        }
        this.blogData = {
          categoryTitle: data.categoryTitle,
          created_at: data.created_at
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.showIndicator = false;
      }

    },
    getComponentName(type) {
      switch (type) {
        case 'text':
          return 'TextContent';
        case 'textarea':
          return 'TextareaContent';
        case 'richText':
          return 'RichTextContent';
        case 'image':
          return 'ImageContent';
        case 'gallery':
          return 'GalleryContent';
        case 'h1':
          return 'H1Content';
        case 'h2':
          return 'H2Content';
        case 'h3':
          return 'H3Content';
        case 'h4':
          return 'H4Content';
        case 'h5':
          return 'H5Content';
        case 'h6':
          return 'H6Content';
        default:
          return null;
      }
    },
    async fetchBlogListingPage() {
      const locale = this.$i18n.locale;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;

      const url = 'https://api.anydaycharter.com/api/blogs/' + locale;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.blogList = data.blogList;
        this.isBlogPage = this.checkIsBlogPage();

      } catch (error) {
        console.error(error);
      }
    },
    checkIsBlogPage() {
      return this.blogList.some(item => item.slug === this.currentSlug);
    }
  },
  computed: {
    ...mapGetters(['getCurrentLanguage']),
  },
  watch: {
    getCurrentLanguage(newLang, oldLang) {
      this.fetchContent();
    }
  },
}
</script>

<template>
  <template v-if="showIndicator" >
    <LoadingIndicator />
  </template>
  <template v-else><div v-if="isBlogPage">
    <div class="blog-inner-image"></div>
    <div class="container">
      <div class="blog-inner">
        <div class="blog-inner__head">
          <a class="blog-inner__back" :href="`/${$i18n.locale}/blog-page`"><img src="/images/prev.svg"></a>
          <div class="blog-inner__name">{{ blogData.categoryTitle }}</div>
          <div class="blog-inner__date">{{ blogData.created_at }}</div>
        </div>
        <div class="terms">
          <h1 class="blog-inner__title">{{ pageData.title }}</h1>
          <template  v-for="(slot, index) in this.pageData"
                     :key="index">
            <component :is="getComponentName(slot.type)" :content="slot.content" />
          </template>
        </div>
      </div>
    </div>
  </div>
    <div v-else class="container">
      <div class="page-nav">
        <div class="breadcrumbs"><a class="breadcrumbs__link" :href="`/${$i18n.locale}`">Home</a>
          <span class="breadcrumbs__separator">/</span>
          <span class="breadcrumbs__current">{{ pageData.title }}</span></div>
      </div>
      <div class="page-head">
        <h1 class="page-head__title">{{ pageData?.title }}</h1>
      </div>
      <div class="terms">
        <component
            v-for="(slot, index) in pageData"
            :key="index"
            :is="getComponentName(slot.type)"
            :content="slot.content"
        />
      </div>
    </div>
  </template>
</template>

<style scoped>
.container {
  margin-bottom: 80px;
  background-color: #fff;
}

</style>