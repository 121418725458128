import axios from 'axios';

const URI_API = "https://api.anydaycharter.com/api";

const state = {
    countries: []
};

const mutations = {
    /**
     * Sets the countries in the state.
     * @param {Object} state - The current state.
     * @param {Array<Object>} countries - The list of countries.
     */
    SET_COUNTRIES(state, countries) {
        state.countries = countries;
    }
};

const actions = {
    /**
     * Fetches the list of countries from the API and commits the data to the state.
     * @param {Object} context - The Vuex context.
     * @param {Function} context.commit - The commit function.
     */
    async fetchCountries({ commit }) {
        try {
            const url = `${URI_API}/countries`;
            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
            const response = await axios.get(url, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${tokenBearer}`
                }
            });
            const countries = response.data;
            commit('SET_COUNTRIES', countries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    }
};

const getters = {
    /**
     * Gets the list of countries from the state.
     * @param {Object} state - The current state.
     * @returns {Array<Object>} The list of countries.
     */
    getCountries: state => state.countries
};

export default {
    state,
    mutations,
    actions,
    getters
};
