<template>
  <div>
    <div id="rangeSlider12"></div>
    <div id="slider12value1">{{ sliderValue1 }}</div>
    <div id="slider12value2">{{ sliderValue2 }}</div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
  name: "NoUiSliderHoursRange",
  data() {
    return {
      sliderValue1: '06:00',
      sliderValue2: '12:00',
    };
  },
  methods: {
    pad2(number) {
      return number < 10 ? '0' + number : number.toString();
    },
    initializeSlider() {
      const rangeSlider12 = document.getElementById('rangeSlider12');
      const slider12value1 = document.getElementById('slider12value1');
      const slider12value2 = document.getElementById('slider12value2');

      if (rangeSlider12) {
        noUiSlider.create(rangeSlider12, {
          start: [360, 720], // Начальные значения по умолчанию - 06:00 и 12:00
          animate: false,
          step: 10,
          connect: true,
          range: {
            min: 0,
            max: 1439, // Общее количество минут в дне (24 * 60)
          },
          format: {
            to: (value) => {
              const hours = Math.floor(value / 60);
              const minutes = value % 60;
              return this.pad2(hours) + ':' + this.pad2(minutes);
            },
            from: (value) => {
              return parseFloat(value); // Преобразуем строку в число
            },
          },
        });

        rangeSlider12.noUiSlider.on('update', (values, handle) => {
          if (handle === 0) {
            this.sliderValue1 = values[handle].split('.')[0]; // Используем только целую часть
          } else {
            this.sliderValue2 = values[handle].split('.')[0];
          }
        });
        rangeSlider12.setAttribute('disabled', true);
      }
    },
  },
  mounted() {
    this.initializeSlider();
    document.addEventListener("DOMContentLoaded", function() {
      // Select elements with the class 'noUi-handle noUi-handle-lower'
      var lowerHandles = document.querySelectorAll('.noUi-handle-lower');
      // Select elements with the class 'noUi-handle noUi-handle-upper'
      var upperHandles = document.querySelectorAll('.noUi-handle-upper');

      // Add background color to each of the lower handle elements
      lowerHandles.forEach(function(element) {
        element.style.backgroundColor = '#6c6c6c';
      });

      // Add background color to each of the upper handle elements
      upperHandles.forEach(function(element) {
        element.style.backgroundColor = '#6c6c6c';
      });
    });
  },
};
</script>
<style scoped>
.noUi-horizontal {
  height: 2px;
}
#rangeSlider12 {
  display: flex;
  margin-top: 8px;
}
#slider12value1, #slider12value2 {
  color:#a8a8a8;
  width: 50%;
  display: inline-block;
  margin-top: 8px;
}
#slider12value2 {
  text-align: right;
}
.noUi-handle {
  background-color: #6c6c6c !important;
}
</style>