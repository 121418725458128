<template>
  <div class="blog-inner__wrapper">
    <div class="blog-inner__text">{{ content }}</div>
  </div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
