<script>
import {BASE} from "@/mixins";

export default {
  name: "FooterView",
  mixins: [BASE],
  props: {
    pages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      footerContent: {
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        telegram: ''
      }
    };
  },
  methods: {
    async fetchSettingsForFooter() {
      const url = 'https://api.anydaycharter.com/api/settings';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.footerContent = {
          facebook: data.facebook_link || this.footerContent.facebook,
          instagram: data.instagram_link || this.footerContent.instagram,
          linkedin: data.linkedin_link || this.footerContent.linkedin,
          mainLink: data.site_url_footer || this.footerContent.mainLink,
          telegram: data.telegram_link || this.footerContent.telegram,
          twitter: data.twitter_link || this.footerContent.twitter
        };
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    firstColumnPages() {
      return this.pages.filter(page => page.footer_column === 1);
    },
    secondColumnPages() {
      return this.pages.filter(page => page.footer_column === 2);
    },
    thirdColumnPages() {
      return this.pages.filter(page => page.footer_column === 3);
    },
    fourthColumnPages() {
      return this.pages.filter(page => page.footer_column === 4);
    }
  },
  async mounted(){
    setTimeout(async ()=> {
      await this.fetchSettingsForFooter();
    }, 1500)
}
}
</script>

<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container"><a class="logo" :href="`/${$i18n.locale}`"><img src="/images/logo.svg"
                                                                             alt="Main logo"/></a>
        <form class="footer__subscribe">
          <div class="footer__subscribe-title">{{ $t('actions.sign_up_for_newsletter')}}</div>
          <input type="email" placeholder="E-mail address"/>
          <button class="btn"><span>{{ $t('interface.subscribe')}}</span></button>
        </form>
      </div>
    </div>
    <div class="footer__nav">
      <div class="container">
        <div class="footer__list">
          <div class="footer__title">{{ $t('interface.and_day_charter')}}</div>
          <ul>
            <li v-for="page in firstColumnPages"><a :href="`/${$i18n.locale}/${page.slug}`">{{ page.title }}</a></li>
          </ul>
        </div>
        <div class="footer__list">
          <div class="footer__title">{{ $t('interface.destinations')}}</div>
          <ul>
            <li v-for="page in secondColumnPages"><a :href="`/${$i18n.locale}/${page.slug}`">{{ page.title }}</a></li>
          </ul>
<!--          <a class="footer__link" href="mailto:support@anydaycharter"><img src="/images/mail.svg" alt="Email logo"/>support@anydaycharter</a>-->
        </div>
        <div class="footer__list">
          <div class="footer__title">{{ $t('interface.terms_and_policies')}}</div>
          <ul>
            <li v-for="page in thirdColumnPages"><a :href="`/${$i18n.locale}/${page.slug}`">{{ page.title }}</a></li>
          </ul>
<!--          <a class="footer__link" href="tel:+420 290 5091 247"><img src="/images/mail.svg" alt="Phone logo"/>+420 290 5091 247</a>-->
        </div>
        <div class="footer__list">
          <div class="footer__title">{{ $t('interface.boats')}}</div>
          <ul>
            <li v-for="page in fourthColumnPages"><a :href="`/${$i18n.locale}/${page.slug}`">{{ page.title }}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.sailboat')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.catamaran')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.motorboat')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.power_catamaran')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.motor_yacht')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.gulet')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.houseboat')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.motorsailer')}}</a></li>
            <li><a href="javascript:void(0)">{{ $t('yacht.rubber_boat')}}</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="container">
        <div class="social">
          <a class="social__link" target="_blank" :href="footerContent.facebook"><img src="/images/fb.svg" alt="Facebook logo"/></a>
          <a class="social__link" target="_blank" :href="footerContent.twitter"><img src="/images/x.svg" alt="X logo"/></a>
          <a class="social__link" target="_blank" :href="footerContent.instagram"><img src="/images/inst.svg" alt="Instagram logo"/></a>
          <a class="social__link" target="_blank" :href="footerContent.linkedin"><img src="/images/in.svg" alt="Linkedin logo"/></a>
          <a class="social__link" target="_blank" :href="footerContent.telegram"><img src="/images/tg.svg" alt="Telegram logo"/></a>
        </div>
        <div class="copyright">© {{ currentYear }} <a :href="footerContent.mainLink">{{ $t('interface.anydaycharter_com')}}</a></div>
      </div>
    </div>
  </footer>
</template>

<style>
.copyright a {
  color:white;
}
</style>

