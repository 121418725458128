import { createI18n } from 'vue-i18n';

// Import translations
import enTranslations from '@/locales/en.json';
import ruTranslations from '@/locales/ru.json';

// Create a Vue I18n instance with options
const i18n = createI18n({
    legacy: false, // Use Composition API
    locale: 'en', // Default locale
    fallbackLocale: 'en', // Fallback locale
    messages: {
        en: enTranslations,
        ru: ruTranslations,
    }
});

export default i18n;