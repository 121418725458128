<template>
  <div>
    <Preloader v-if="showPreloader" />
    <HeaderView ref="headerView" :pages="headerPages" />
    <LinksAnchor />
    <RouterView @trigger-update-in-header="triggerUpdateInHeader" />
    <FooterView :pages="footerPages" />
    <OverlayBackground/>
  </div>
</template>

<script>
import HeaderView from "@/components/globals/Header.vue";
import FooterView from "@/components/globals/Footer.vue";
import Preloader from "@/components/globals/Preloader.vue";
import LinksAnchor from "@/components/globals/LinksAnchor.vue";
import OverlayBackground from "@/components/partials/modals/OverlayBackground.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'MainLayout',
  components: {OverlayBackground, LinksAnchor, Preloader, FooterView, HeaderView },
  data() {
    return {
      footerPages:[],
      headerPages:[],
      showPreloader: false,
      headContent:"",
      bodyContent:"",
      titleContent:""
    }
  },
  computed: {
    ...mapGetters(['getCurrentLanguage']),
  },
  methods : {
    ...mapActions(['setCurrentLanguage', 'fetchLanguages']),
    togglePreloader() {
      if(this.$route.name === 'HomeView'){
        this.showPreloader = true;
      }
    },
    async injectContent() {
      const url = 'https://api.anydaycharter.com/api/settings';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.headContent = data.head;
        this.bodyContent = data.body;
        this.titleContent = data.site_name;

        // Inject head content
        const headElement = document.createElement('div');
        headElement.innerHTML = this.headContent;
        document.head.appendChild(headElement);

        // Inject body content
        const bodyElement = document.createElement('div');
        bodyElement.innerHTML = this.bodyContent;
        document.body.insertAdjacentElement('afterbegin', bodyElement);

        // Update the document title
        document.title = this.titleContent;

        // Ensure scripts within the injected content are executed
        this.executeScripts(headElement);
        this.executeScripts(bodyElement);

      } catch (error) {
        console.error(error);
      }
    },
    executeScripts(element) {
      const scripts = element.getElementsByTagName('script');
      for (let script of scripts) {
        const newScript = document.createElement('script');
        newScript.text = script.text;
        for (let attr of script.attributes) {
          newScript.setAttribute(attr.name, attr.value);
        }
        document.head.appendChild(newScript).parentNode.removeChild(newScript);
      }
    },
    navigateTo(event, path) {
      event.preventDefault();
      this.$router.push(path);
    },
    updateLanguageFromUrl() {
      const lang = this.$route.path.split('/')[1];
      if (this.getCurrentLanguage !== lang) {
        this.setCurrentLanguage(lang);
        this.$i18n.locale = lang;
        document.querySelector("html").setAttribute("lang", lang);
        localStorage.setItem("user-locale", lang);
      }
    },
    async fetchAllPages() {
      const locale = this.$i18n.locale;
      const url = 'https://api.anydaycharter.com/api/pages/'+locale;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        const { footerPages, headerPages } = data.reduce((acc, page) => {
          if (page.show_on_footer) {
            acc.footerPages.push(page);
          }
          if (page.show_on_header) {
            acc.headerPages.push(page);
          }
          return acc;
        }, { footerPages: [], headerPages: [] });

        this.footerPages=footerPages;
        this.headerPages = headerPages;

      } catch (error) {
        console.error(error);
      }
    },
    triggerUpdateInHeader() {
      this.$refs.headerView.updateUserInfo();
    },
  },
  async mounted() {
    this.togglePreloader();
    await this.fetchAllPages();
    this.updateLanguageFromUrl();
    await this.injectContent();

  },
  watch: {
    $route(to, from) {
      this.togglePreloader();
      this.updateLanguageFromUrl();
    },
    getCurrentLanguage(newLang, oldLang) {
      this.fetchAllPages();
    }
  },
}
</script>

