export async function addToFavorites(slug, token) {
    const url = 'https://api.anydaycharter.com/api/add-favorite';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({"yacht_slug": slug})
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
}

export async function removeFromFavorites(slug, token) {
    const url = 'https://api.anydaycharter.com/api/remove-favorite';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({"yacht_slug": slug})
    };

    try {
        const response = await fetch(url, options);
        const data = await response.json();
    } catch (error) {
        console.error(error);
    }
}

export async function restorePassword(email) {
    const url = 'https://api.anydaycharter.com/api/forgot-password';
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer 8MU4e2Z0biho5tYhZ5hKNGtLfMArSOZRW0ObrqXSb395d6cd'
        },
        body: JSON.stringify({"email":email})
    };
    try {
        const response = await fetch(url, options);
        const data = await response.json();
        if(data.success){
            this.$notify({
                title: "Success!",
                text: "You successfully asked for password restoring. Please check your mail",
            });
        } else {
            this.$notify({
                type: 'warning',
                title: 'Warning',
                text: "This email wasn\'t registered before"
            });
        }

        this.closeModal();
    } catch (error) {
        console.error(error);
    }
}