<script>
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';

export default {
  name: "BaseViewMap",
  components: {
    GoogleMap,
    Marker,
    InfoWindow
  },
  props: {
    base: {
      type: Object,
      required: true
    },
    yachtName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      apiKey: 'AIzaSyD_AWzCnbsg9m9z7bxt4PdGCsFcPEoQAtA',
      center: { lat: 0, lng: 0 },
      infoWindowOptions: {},
      infoWindow: true,
    };
  },
  watch: {
    base: {
      immediate: true,
      handler(newBase) {
        if (newBase) {
          this.center = {
            lat: parseFloat(newBase.latitude),
            lng: parseFloat(newBase.longitude)
          };
          this.infoWindowOptions = {
            position: this.center,
            content: `<div class='product__name'>${this.yachtName}</div>
                      <div class='product__place' style="text-align: center; padding-top: 13px;">
                        ${newBase.name}<br>${newBase.city}
                      </div>`
          };
        }
      }
    }
  },
  methods: {
    closeBaseMapModal() {
      this.$emit('closeBaseMapModal');
    }
  },
  mounted() {
    this.infoWindow = true;
  }
};
</script>

<template>
  <div class="modal">
    <div class="modal__close" @click="closeBaseMapModal">
      <img src="/images/modal-close.svg" alt="Close icon"/>
    </div>
    <div class="modal__wrapper modal__wrapper--map">
      <div class="modal__map" id="modal__map--yacht">
        <div class="modal__map-title">Map</div>
        <div class="modal__map-wrapper modal__map-wrapper-yacht">
          <GoogleMap
              :api-key="apiKey"
              style="width: 100%; height: 100%"
              :center="center"
              :zoom="15"
              map-type-id="satellite"
          >
            <Marker :options="{ position: center }" >
              <InfoWindow :options="infoWindowOptions" v-model="infoWindow">
                <div class="product__name">{{ yachtName }}</div>
                <div class="product__place" style="text-align: center; padding-top: 13px;">
                  {{ base.name }}<br>{{ base.city }}
                </div>
              </InfoWindow>
            </Marker>
          </GoogleMap>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#tab-content--yacht {
  width: 100%;
  margin-top: 20px;
  padding: 0;
}

#modal__map--yacht {
  width: 100%;
}



</style>
