<script>
import { GoogleMap, Marker, MarkerCluster } from 'vue3-google-map';
import { mapGetters } from 'vuex';
import NoUiSliderCustom from '@/components/partials/NoUiSliderCustom.vue';
import BoatcardMapPreview from '@/components/partials/modals/partials/BoatcardMapPreview.vue';

export default {
  name: "BoatsLocation",
  components: {
    GoogleMap,
    Marker,
    MarkerCluster,
    BoatcardMapPreview,
    NoUiSliderCustom
  },
  props: {
    points: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      apiKey: 'AIzaSyD_AWzCnbsg9m9z7bxt4PdGCsFcPEoQAtA',
      center: { lat: 40.689247, lng: -74.044502 },
    };
  },
  computed: {
    ...mapGetters(['getYachts', 'getLimits']),
  },
  watch: {
    points: {
      immediate: true,
      handler(newPoints) {
        if (newPoints.length > 0) {
          this.center = { lat: newPoints[0].latitude, lng: newPoints[0].longitude };
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  }
};
</script>

<template>
  <div class="modal">
    <div class="modal__close" @click="closeModal">
      <img src="/images/modal-close.svg" alt="Close icon"/>
    </div>
    <div class="modal__wrapper modal__wrapper--map">
      <div class="modal__map">
        <div class="modal__map-title">Map</div>
        <div class="modal__map-wrapper">
          <GoogleMap
              :api-key="apiKey"
              style="width: 100%; height: 500px"
              :center="center"
              :zoom="15"
          >
            <MarkerCluster>
              <Marker
                  v-for="(point, index) in points"
                  :key="index"
                  :options="{ position: { lat: point.latitude, lng: point.longitude } }"
              />
            </MarkerCluster>
          </GoogleMap>
        </div>
      </div>
      <div class="modal__filter">
        <div class="tabs">

          <div class="tab-content">
            <ul class="tab-links">
              <li><a href="javascript:void(0)">{{ $t('interface.boats')}} {{ $t('yacht.available')}} ({{ getYachts.length }})</a></li>
            </ul>
            <BoatcardMapPreview v-for="yacht in getYachts" :key="yacht.id" :yacht="yacht"/>
          </div>
          <div class="tab-content">
            <ul class="tab-links">
              <li><a href="javascript:void(0)" @click.prevent="activeTab=1">{{ $t('actions.filter')}}</a></li>
            </ul>
            <div class="filter" v-if="getYachts.length">
              <NoUiSliderCustom :min="getLimits.cabins_min" :max="getLimits.cabins_max" title="Cabins" param="cabins" v-if="getLimits.cabins_max"/>
              <NoUiSliderCustom :min="getLimits.price_min" :max="getLimits.price_max" title="Price" param="price" v-if="getLimits.price_max"/>
              <NoUiSliderCustom :min="getLimits.people_min" :max="getLimits.people_max" title="People" param="people" v-if="getLimits.people_max"/>
              <NoUiSliderCustom :min="getLimits.berths_min" :max="getLimits.berths_max" title="Berths" param="berths" v-if="getLimits.berths_max"/>
              <NoUiSliderCustom :min="getLimits.length_min" :max="getLimits.length_max" title="Length" param="length" v-if="getLimits.length_max"/>
              <NoUiSliderCustom :min="getLimits.year_min" :max="getLimits.year_max" title="Year" param="year" v-if="getLimits.year_max"/>
              <NoUiSliderCustom :min="getLimits.wc_min" :max="getLimits.wc_max" title="Toilets" param="wc" v-if="getLimits.wc_max"/>
            </div>
            <div class="settings">
              <div class="settings__item">
                <div class="settings__title">{{ $t('yacht.rental_type') }}</div>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Bareboat </span><span>(202)</span></span>
                </label>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Crewed </span><span>(223)</span></span>
                </label>
              </div>
              <div class="settings__item">
                <div class="form-group">
                  <label>{{ $t('yacht.manufacturer') }}</label>
                  <input type="text" placeholder="Search"/>
                </div>
                <div class="form-group">
                  <label>{{ $t('yacht.model') }}</label>
                  <input type="text" placeholder="Select manufacturer first"/>
                </div>
                <div class="form-group">
                  <label>{{ $t('yacht.amenities') }}</label>
                  <input type="text" placeholder="Search"/>
                </div>
              </div>
<!--              <div class="settings__item">-->
<!--                <div class="settings__title">Review score</div>-->
<!--                <label class="checkbox">-->
<!--                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span-->
<!--                    class="checkbox__text"><span>6+ </span><span>(73)</span></span>-->
<!--                </label>-->
<!--                <label class="checkbox">-->
<!--                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span-->
<!--                    class="checkbox__text"><span>7+ </span><span>(64)</span></span>-->
<!--                </label>-->
<!--                <label class="checkbox">-->
<!--                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span-->
<!--                    class="checkbox__text"><span>8+ </span><span>(60)</span></span>-->
<!--                </label>-->
<!--                <label class="checkbox">-->
<!--                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span-->
<!--                    class="checkbox__text"><span>9+ </span><span>(30)</span></span>-->
<!--                </label>-->
<!--                <label class="checkbox">-->
<!--                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span-->
<!--                    class="checkbox__text"><span>10 </span><span>(25)</span></span>-->
<!--                </label>-->
<!--              </div>-->
              <div class="settings__item">
                <div class="settings__title">Type of mainsail</div>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Classic mainsail </span><span>(126)</span></span>
                </label>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Rolling mainsail </span><span>(70)</span></span>
                </label>
              </div>
              <div class="settings__item">
                <div class="settings__title">Engine</div>
                <div class="range-item__slider" id="rangeSlider8"></div>
                <div class="range-item__values">
                  <div id="rangeStart8"></div>
                  <div id="rangeEnd8"></div>
                </div>
              </div>
              <div class="settings__item">
                <div class="form-group">
                  <label>Charter company</label>
                  <input type="text" placeholder="Search"/>
                </div>
              </div>
              <div class="settings__item">
                <div class="settings__title">Services</div>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Deposit insurance </span><span>(176)</span></span>
                </label>
                <label class="checkbox">
                  <input type="checkbox"/><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Pet friendly </span><span>(25)</span></span>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>