<template>
    <p style="color: #6c6c6c">{{ content }}</p>
</template>

<script>
export default {
  name: 'TextareaContent',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
