<template>
  <div>
    <div id="rangeSlider11"></div>
    <div id="slider11value">{{ sliderValue }}</div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default {
  name: "NoUiSliderHours",
  props:{
    sliderValue:{
      type: String,
      required: true
    }
  },
  methods: {
    pad(value) {
      return value.toString().padStart(2, '0');
    },
    initializeSlider() {
      const rangeSlider11 = document.getElementById('rangeSlider11');
      const rangeSlider11Value = document.getElementById('slider11value');

      if (rangeSlider11) {
        noUiSlider.create(rangeSlider11, {
          start: 720,
          animate: false,
          step: 10,
          range: {
            min: 0,
            max: 1439,
          },
          format: {
            to: (value) => {
              const hours = Math.floor(value / 60);
              const minutes = value % 60;
              return this.pad(hours) + ':' + this.pad(minutes);
            },
            from: (value) => {
              return parseFloat(value);
            },
          },
        });

        rangeSlider11.noUiSlider.on('update', (values, handle) => {
          return ;
        });
        rangeSlider11.setAttribute('disabled', true);
      }
    },
  },
  mounted() {
    this.initializeSlider();
  },
};
</script>
<style scoped>
.noUi-horizontal {
  height: 2px;
}
.noUi-handle {
  background-color: #6c6c6c !important;
}
#slider11value {
  margin-top: 8px;
  text-align: right;
  color:#a8a8a8;
}
.details-item__name {
  margin-bottom: 10px;
}

</style>
