<script>
import $ from "jquery";
import Swiper from "swiper";
import {Pagination} from "swiper/modules";
import {BASE} from "@/mixins";

export default {
  name: "OfferView",
  mixins:[BASE],
  data(){
    return {
      offer: {
        id: 0,
        uuid: "",
        yachts: [],
        country : {}
      },
    }
  },
  methods:{
    async fetchOfferContent(){
      const urlParams = new URL(window.location.href);
      const pathParts = urlParams.pathname.split('/');

      const uuid = decodeURIComponent(pathParts[pathParts.length - 1]);
      const url = `https://api.anydaycharter.com/api/offer/${uuid}`;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer 8MU4e2Z0biho5tYhZ5hKNGtLfMArSOZRW0ObrqXSb395d6cd'
        }
      };

      try {
        const response = await fetch(url, options);
        this.offer = await response.json();
        console.log(this.offer);
      } catch (error) {
        console.error(error);
      }
    },
    initialisingProposalYachts(){
      $(".tabs__content.active .specials__item").css("opacity", "1");

      $(".tabs__caption").on("click", "li:not(.active)", function () {
        var $tabs = $(this).closest(".tabs");
        var $activeTab = $tabs.find(".tabs__content.active");
        var $targetTab = $tabs.find(".tabs__content").eq($(this).index());

        $(this).addClass("active").siblings().removeClass("active");

        $tabs.find(".tabs__content").removeClass("active");

        $targetTab.addClass("active");

        $targetTab.find(".specials__item").each(function (index) {
          var $gridItem = $(this);
          if ($gridItem.css("opacity") != 1) {
            $gridItem.delay(200 * index).animate({ opacity: 1 }, 500);
          }
        });

        $activeTab.find(".specials__item").css("opacity", 0);
      });

      var swiper = new Swiper(".specials__slider", {
        modules: [Pagination],
        slidesPerView: 3,
        spaceBetween: 17,
        loop: true,
        pagination: {
          el: ".specials__slider .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1025: {
            slidesPerView: 3,
          },
        },
      });
    },
    getYachtProposalImage(yacht) {
      if (!yacht.mainImageUrls) {
        return '/images/specials-1.png';
      }
      return yacht.mainImageUrls['800'] || yacht.mainImageUrls['original'];

    },
  },
  mounted() {
    this.fetchOfferContent().then(()=>{
      this.initialisingProposalYachts();
    })
  }
}
</script>

<template>
  <div>
    <div class="country-image">
      <div class="container">
        <div class="country-image__title">Yacht Charter in {{ offer.country.name }}</div>
        <div class="country-image__text">Choose from thousands of yachts in {{ offer.country.name }} to make your dream holiday a
          reality.
        </div>
      </div>
    </div>
    <div class="country">
      <div class="container">
        <div class="country__header">
          <div class="reviews__total-rate" style="visibility: hidden">
            <div class="reviews__info" >
              <div class="reviews__based">Based on <span>2312 </span>reviews</div>
              <div class="reviews__stars" data-rate="1.5">
                <div class="reviews__stars-item">
                  <div class="reviews__stars-fill"><i class="fa fa-star"></i></div>
                  <div style="overflow: hidden;" veiwbox="0 0 15 15"><i class="fa fa-star-o" style="width: 100%;"></i>
                  </div>
                </div>
                <div class="reviews__stars-item">
                  <div class="reviews__stars-fill"><i class="fa fa-star"></i></div>
                  <div style="overflow: hidden;" veiwbox="0 0 15 15"><i class="fa fa-star-o" style="width: 100%;"></i>
                  </div>
                </div>
                <div class="reviews__stars-item">
                  <div class="reviews__stars-fill"><i class="fa fa-star"></i></div>
                  <div style="overflow: hidden;" veiwbox="0 0 15 15"><i class="fa fa-star-o" style="width: 100%;"></i>
                  </div>
                </div>
                <div class="reviews__stars-item">
                  <div class="reviews__stars-fill"><i class="fa fa-star"></i></div>
                  <div style="overflow: hidden;" veiwbox="0 0 15 15"><i class="fa fa-star-o" style="width: 100%;"></i>
                  </div>
                </div>
                <div class="reviews__stars-item">
                  <div class="reviews__stars-fill"><i class="fa fa-star"></i></div>
                  <div style="overflow: hidden;" veiwbox="0 0 15 15"><i class="fa fa-star-o" style="width: 100%;"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="reviews__total-value">4.7</div>
          </div>
          <div class="country__header-info">
            <div class="country__header-available"><img src="/images/spain.png">2430 boats <span>available</span></div>
            <div class="product__recently">Recently booked by a customer from <img src="/images/spain.png"></div>
            <div class="country__header-name">{{ offer.country.name }}</div>
          </div>
        </div>
        <div class="country__body">
          <div class="page-head">
            <div class="page-head__subtitle">Things To Do on a</div>
            <h1 class="page-head__title">Boat Rental in {{ offer.country.name }}</h1>
          </div>
          <div class="country__info">
            <div class="country__info-item">
              <div class="country__info-title">Activities</div>
              <div class="country__info-text">Experience the vibrant Croatian party scene: Europe's top festivals and
                concerts happen on the mainland and islands, with celebs hitting Croatia's vibrant bars and clubs. Head
                to Hvar's marina where many arrive on superyachts. Get in on the glamor – cha
              </div>
            </div>
            <div class="country__info-item">
              <div class="country__info-title">Sailing conditions</div>
              <div class="country__info-text">Croatia enjoys favorable wind conditions, particularly during the summer
                months. The prevailing winds, such as the Mistral and the Bora, provide excellent sailing experiences,
                making it a paradise for sailors seeking smooth and enjoyable voyages.
              </div>
            </div>
            <div class="country__info-item">
              <div class="country__info-title">Enjoyable sailing adventure</div>
              <div class="country__info-text">While the Croatian coastline is breathtaking, sailors should exercise
                caution near rocky areas and cliffs, especially in more remote locations. Nautical charts and local
                knowledge are crucial to navigate safely and avoid potential hazards.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="specials__tabs tabs">
        <div class="container">
          <ul class="specials__tabs-caption tabs__caption">
            <li class="active"><span>Yachts available for You</span><span>{{ offer.yachts.length }}</span></li>

          </ul>
          <div class="tabs__content active">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="yacht in offer.yachts" :key="yacht.slug">
                  <a class="specials__item" :href="`/${$i18n.locale}/${yacht.type.slug}/${yacht.slug}`">
                    <div class="specials__img"><img :src="getYachtProposalImage(yacht)" :alt="yacht.country.name">
                      <div class="specials__info">
                        <div class="specials__name"> {{ yacht.name }}</div>
                        <div class="specials__place">
                          <span :class="'fi icon-flag fi-'+yacht.country.short.toLowerCase()"></span>
                          {{ yacht.country.name }}, {{ yacht.base }}</div>
                      </div>
                    </div>
                    <div class="specials__stat"><span>{{ yacht.length }} m ⁄ 33.91 ft</span><span>{{ yacht.max_people }} {{ $t('yacht.persons') }}</span><span>{{ yacht.year }}</span>
                    </div>
                                      <div class="specials__price">{{ $t('interface.from') }} {{ yacht.min_price }} EUR / {{ $t('interface.week') }}</div>
<!--                    <div class="specials__price">{{ $t('user.request_a_quote') }}</div>-->
                  </a>
                </div>
                <div class="swiper-slide" v-for="yacht in offer.yachts" :key="yacht.slug">
                  <a class="specials__item" :href="`/${$i18n.locale}/${yacht.type.slug}/${yacht.slug}`">
                    <div class="specials__img"><img :src="getYachtProposalImage(yacht)" :alt="yacht.country.name">
                      <div class="specials__info">
                        <div class="specials__name"> {{ yacht.name }}</div>
                        <div class="specials__place">
                          <span :class="'fi icon-flag fi-'+yacht.country.short.toLowerCase()"></span>
                          {{ yacht.country.name }}, {{ yacht.base }}</div>
                      </div>
                    </div>
                    <div class="specials__stat"><span>{{ yacht.length }} m ⁄ 33.91 ft</span><span>{{ yacht.max_people }} {{ $t('yacht.persons') }}</span><span>{{ yacht.year }}</span>
                    </div>
                    <div class="specials__price">{{ $t('interface.from') }} {{ yacht.min_price }} EUR / {{ $t('interface.week') }}</div>
                    <!--                    <div class="specials__price">{{ $t('user.request_a_quote') }}</div>-->
                  </a>
                </div>


              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="tabs__content">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-1.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 34</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Lefkada</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>10.34 m ⁄ 33.91 ft</span><span>6 persons</span><span>2016</span>
                  </div>
                  <div class="specials__price">from 865 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item specials__item--sale" href="#">
                  <div class="specials__img">
                    <div class="specials__sale">-25%</div>
                    <img src="/images/specials-2.png">
                    <div class="specials__info">
                      <div class="specials__name">Athena 38</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>10 persons</span><span>2002</span>
                  </div>
                  <div class="specials__price">from 3 096 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="tabs__content">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-1.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 34</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Lefkada</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>10.34 m ⁄ 33.91 ft</span><span>6 persons</span><span>2016</span>
                  </div>
                  <div class="specials__price">from 865 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item specials__item--sale" href="#">
                  <div class="specials__img">
                    <div class="specials__sale">-25%</div>
                    <img src="/images/specials-2.png">
                    <div class="specials__info">
                      <div class="specials__name">Athena 38</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>10 persons</span><span>2002</span>
                  </div>
                  <div class="specials__price">from 3 096 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-3.png">
                    <div class="specials__info">
                      <div class="specials__name">Sun Oddysey 410</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Gouvia</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>14.0 m ⁄ 40.01 ft</span><span>9 persons</span><span>2014</span>
                  </div>
                  <div class="specials__price">from 940 EUR / week</div>
                </a></div>
                <div class="swiper-slide"><a class="specials__item" href="#">
                  <div class="specials__img"><img src="/images/specials-4.png">
                    <div class="specials__info">
                      <div class="specials__name">Bavaria 37 Cruiser</div>
                      <div class="specials__place"><img src="/images/gr.svg">Greece, Volos</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>12.2 m ⁄ 40.01 ft</span><span>8 persons</span><span>2021</span>
                  </div>
                  <div class="specials__price">from 2570 EUR / week</div>
                </a></div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <a class="btn" @click="performSearchBoats({destinations: offer.country.slug, type: `any_type`, boatType: `any_type` }, true)"><span>All Boats with Family Pack in {{ offer.country.name }}</span></a>

    </div>
  </div>
</template>

<style scoped>
.country__header {
  flex-direction: column;
}
.country__header-info {
  height: 80px;
}
</style>