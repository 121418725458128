<script>
import {BASE} from "@/mixins";

export default {
  name: "CountryCard",
  mixins:[BASE],
  props:{
    country:{
      type:Object,
      required: true
    },
    index:{
      type:Number,
      required: true
    }
  },
  methods: {
    openCountrySingleView() {
      this.performSearchBoats({ destinations: this.country.slug, type: "any_type", boatType: 'any_type' }, true);
    },
    getCountryImage(image) {
      if (!image) {
        return '/images/country-1.png';
      }
        return image['800'] || image['original'];
    },
  }
}
</script>

<template>
  <a v-if="index!==4" class="grid__item" href="javascript:void(0)" @click.stop="openCountrySingleView" :title="'Visit ' + country.name" >
    <div class="grid__image">
      <img :src="getCountryImage(country.image)" :alt="country.image.alt || country.image.original" :title=country.image.title>
    </div>
    <div class="grid__item-name">{{  country.name }}</div>
    <div class="grid__item-price">from {{ country.min_price }} EUR / {{ $t('interface.week')}}</div>
  </a>
  <a v-else class="grid__item" href="#">
    <div class="grid__image">
      <img src="/images/grid-5.webp"></div>
    <div class="grid__item-logo">
      <img src="/images/logo.svg"></div>
    <div class="grid__item-name">{{ $t('interface.offer_of_the_week_title')}}</div>
    <div class="grid__item-price">{{ $t('interface.offer_of_the_week_text')}} </div>
  </a>
</template>

<style scoped>

</style>