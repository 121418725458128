<script>
import LoginForm from "@/components/partials/modals/LoginForm.vue";
import RegistrationForm from "@/components/partials/modals/RegistrationForm.vue";
import BoatViewMap from "@/components/partials/modals/BoatViewMap.vue";

export default {
  name: "OverlayBackground",
  components: {BoatViewMap, RegistrationForm, LoginForm}
}
</script>

<template>
  <div class="overlay">
    <LoginForm />
    <RegistrationForm />
  </div>
</template>

<style scoped>

</style>