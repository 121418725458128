import { createApp } from 'vue/dist/vue.esm-bundler';
import App from './App.vue';
import router from './router';
import '../src/assets/index';
import i18n from '@/assets/plugins/i18n'; // Import the i18n configuration
import globalComponents from '../src/components/globals/index';
import store from '@/store';
import $ from 'jquery';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import GoogleSignInPlugin from 'vue3-google-login';
import Notifications from '@kyvg/vue3-notification'

/**
 * Creates a new Vue application instance.
 * @type {import('vue').App}
 */
const app = createApp(App);

app.config.globalProperties.$ = $;

/**
 * Installs the i18n plugin.
 * @type {import('@/assets/plugins/i18n')}
 */
app.use(i18n);

/**
 * Installs the router.
 * @type {import('vue-router').Router}
 */
app.use(router);

app.use(store);

app.use(Notifications);

app.use(GoogleSignInPlugin, {
    clientId: '877339133236-g70fr4hv648mmu80mam2scnfa11na0gf.apps.googleusercontent.com',
});
/**
 * Installs global components.
 * @type {import('../src/components/globals/index')}
 */
app.use(globalComponents);

/**
 * Router navigation guard executed before each navigation.
 * Sets the global locale based on the route parameters.
 * @param {import('vue-router').RouteLocation} to - The target Route Object being navigated to.
 * @param {import('vue-router').RouteLocation} from - The current route being navigated away from.
 * @param {(to?: import('vue-router').RouteLocationNormalized, allowNext?: () => void) => void} next - Function to resolve the hook.
 */
router.beforeEach((to, from, next) => {
    let lang = to.params.lang;
    if (!lang) {
        lang = 'en';
    }
    i18n.global.locale = lang;
    next();
});

/**
 * Configure the error handler using
 *
 * @param err
 * @param vm
 * @param info
 */
app.config.errorHandler = function (err, vm, info) {
    console.error('Vue Error:', err, info);
};

app.config.warnHandler = function (msg, vm, trace) {
    // Ignore the specific warning about the "select" event
    if (msg.includes('Component emitted event "select"')) {
        return;
    }

    // Default behavior: log the warning to console
    console.warn(msg);
};

/**
 * Mounts the application to the DOM.
 * @method
 * @param {string | Element} el - The DOM element selector or element to mount the app to.
 */
app.mount('#app');
