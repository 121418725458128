<script>
export default {
  name: "CountriesView"
}
</script>

<template>
  <div class="countries">
    <div class="container">
      <div class="page-head">
        <div class="page-head__subtitle">Discover</div>
        <h1 class="page-head__title">All Countries</h1>
      </div>
      <form class="countries__filter">
        <div class="search__place">
          <input type="text" placeholder="Where are you going?">
        </div>
        <button class="btn"><span>Search</span></button>
      </form>
      <div class="countries__wrapper"><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-1.png"><img src="/images/country-2.png"></div>
        <div class="countries__name">Croatia</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-2.png"></div>
        <div class="countries__name">Greece</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-3.png"></div>
        <div class="countries__name">France</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-4.png"></div>
        <div class="countries__name">Spain</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-5.png"></div>
        <div class="countries__name">Italy</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-6.png"></div>
        <div class="countries__name">Turkey</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-7.png"></div>
        <div class="countries__name">Caribbean</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-8.png"></div>
        <div class="countries__name">Germany</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-9.png"></div>
        <div class="countries__name">Poland</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-10.png"></div>
        <div class="countries__name">Netherland</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-11.png"></div>
        <div class="countries__name">United Kingdom</div></a><a class="countries__item" href="#">
        <div class="countries__image"><img src="/images/country-12.png"></div>
        <div class="countries__name">Thailand</div></a></div>
    </div>
  </div>
</template>

<style scoped>
.search__place input {
  padding-left: 50px;
}
</style>