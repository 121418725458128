<script>
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";

export default {
  name: "ThankYou",
  components: {BreadCrumbs},
  data() {
    return {
      orderCode: 0
    }
  },
  mounted(){
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    this.orderCode = params.get('order');
  }
}
</script>

<template>
  <div class="container">

    <div class="details__modal details__success">
      <img src="/images/ThankYouImage.png" alt="ThankYouImage">
     <div class="details__success-wrapper">
       <div class="details__success-top-wrapper">
         <div class="details__success-title">Congratulations!</div>
         <div class="details-item details-item--success">
           <div class="details-item__name details-item__name--success">Your booking is successfully created</div>
           <div class="details-item__name details-item__code--success">Booking number is <div>{{ orderCode }}</div>
           </div>

         </div>
       </div>
       <div class="details__success-low-wrapper">
         <div class="details-item__descr details-item__descr--success">The manager will contact you soon!</div>
         <a class="btn btn--bordered" :href="`/${$i18n.locale}`">Thank You</a>
       </div>
     </div>
    </div>
  </div>
</template>

<style scoped>
.details__success-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: #21084d;
}
.details__success-top-wrapper{
  padding: 50px 50px 30px;
}
.details__success-low-wrapper {
  padding: 30px 50px;
}
.details__success-low-wrapper {
  border-top: solid 1px black;
}

.details__success-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
}

.details__modal {
  width: 953px;
  height: 430px;
  top: 15%;
  background-color: #ffffff;
  left: 30%;
  margin: auto;
  min-height: 200px;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, .3);
  border-radius: 10px;
  z-index: 999;
}

.details__success {
  margin: 285px auto;
  display: flex;
  align-items: center;
}

.details-item__name--success {

  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: black;
}

.details-item__code--success {
  font-size: 30px;
  font-weight: 600;
  line-height: 28px;
  color: #BF8D37;
}

.details-item__code--success div {
  margin-top: 15px;
  color: #21084d;
}

.details-item__descr--success {
  margin-bottom: 15px;
}
.details-item__code--success {
  margin-top: 5px;
}
.details-item__name--success {
  margin-top: 30px;
}
.btn--bordered {
  width: 240px;
}
@media (max-width: 767px) {
  .details__success {
    flex-direction: column;
    width: 90%;
    height: auto;
    margin: 110px auto;
  }
  .details__success img {
    border-radius: 10px;
  }
  .details__success-title {
    font-size: 30px;
  }
  .details__success-top-wrapper {
    padding: 40px 20px;
  }
  .details-item__name--success {
    margin-top: 10px;
    font-size: 16px;
  }
  .details-item__code--success {
    font-size: 22px;
  }
}
</style>