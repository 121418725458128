<script>
import {
  calculateDaysDifference,
  calculateProductPrice,
  checkYachtDetails,
  formatDateInDetails,
  newDate, scrollToElement, toggleFavorite
} from "@/utilities/service";
import {mapActions, mapGetters} from "vuex";
import intlTelInput from "intl-tel-input";
import BreadCrumbs from "@/components/partials/BreadCrumbs.vue";
import LoadingIndicator from "@/components/globals/LoadingIndicator.vue";

export default {
  name: "DetailsViewStepTwo",
  components: {LoadingIndicator, BreadCrumbs},
  data() {
    return {
      showLoadingIndicator: true,
      isFavorite: false,
      selectedPeriod: null,
      includedExtrasPrice: 0,
      excludedExtrasPrice: 0,
      flexibleCancellation: false,
      phonemaskInputExists: false,
      productId: null,
      orderCode: null,
      customer: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        specialRequests: "",
        selectedExtras: [],
      }
    };
  },
  methods: {
    checkYachtDetails,
    formatDateInDetails,
    newDate,
    toggleFavorite,
    calculateDaysDifference,
    calculateProductPrice,
    scrollToElement,
    ...mapActions(["fetchSelectedYacht"]),
    updateExtrasWithChecked(extras) {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('fc')) {
        this.flexibleCancellation = true;
      }

      extras.forEach(extra => {
        extra.checked = urlParams.has(extra.id.toString());
        if (extra.checked) {
          if (extra.payable_in_base) {
            this.excludedExtrasPrice += Number(this.calculateProductPrice(extra));
          } else {
            this.includedExtrasPrice += Number(this.calculateProductPrice(extra));
          }
        }
      });
    },
    async saveBooking() {
      const params = new URLSearchParams(window.location.search);
      const checkIn = params.get('date_start');
      const checkOut = params.get('date_end');

      // Ensure that the necessary fields are present
      if (!this.getSelectedYacht || !checkIn || !checkOut) {
        console.error("Required fields are missing");
        return;
      }

      const body = {
        yacht_id: this.getSelectedYacht.id,
        date_from: checkIn,
        product_id: 4519,
        date_to: checkOut,
        first_name: this.customer.firstName,
        last_name: this.customer.lastName,
        email: this.customer.email,
        phone: this.customer.phoneNumber,
        comment: this.customer.specialRequests || 'empty',
        price: this.summaryPrice,
        currency: this.getSelectedYacht.currency,
        extra_id: this.customer.selectedExtras,
      };

      const url = 'https://api.anydaycharter.com/api/order/create';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const locale = this.$i18n.locale;
        const orderCode = data.data.code;
        let newUrl = `/${locale}/thank-you?order=${orderCode}`;
        window.location.href = window.location.href = newUrl;

      } catch (error) {
        console.error('Error:', error);
      }
    },
    loadFromLocalStorage() {
      const customerData = localStorage.getItem('customer');
      if (customerData) {
        this.customer = JSON.parse(customerData);
      }
    },
    checkIfFavorite() {
      const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
      this.isFavorite = favorites.includes(this.getSelectedYacht.slug);
    }
  },
  computed: {
    ...mapGetters(["getSelectedYacht"]),
    emailLink() {
      return `mailto:${this.customer.email}`;
    },
    dateRange() {
      const params = new URLSearchParams(window.location.search);
      const checkIn = params.get('date_start');
      const checkOut = params.get('date_end');

      if (checkIn && checkOut) {
        const start = this.formatDateInDetails(checkIn);
        const end = this.formatDateInDetails(checkOut);

        return {
          start: start,
          end: end,
          daysDifference: this.calculateDaysDifference(start, end)
        };
      }
      return {
        start: "",
        end: "",
        daysDifference: ""
      }
    },
    initialPrice() {
      let result = 0;
      if (this.selectedPeriod) {
        result = this.selectedPeriod.price;
      }
      return result;
    },
    summaryPrice() {
      const initialPrice = Number(this.initialPrice);
      const includedExtrasPrice = Number(this.includedExtrasPrice);
      if (this.flexibleCancellation) {
        return Math.round(1.08 * (initialPrice + includedExtrasPrice));
      }
      return Math.round(initialPrice + includedExtrasPrice);
    },
    obligatoryPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }



      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if(!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && prod.payable_in_base);
    },
    obligatoryPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if(!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => prod.obligatory && !prod.payable_in_base);
    },
    optionalPayableInBase() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if(!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && prod.payable_in_base && prod.checked);
    },
    optionalPayableImmediately() {
      if (!this.getSelectedYacht || !this.getSelectedYacht.products) {
        return [];
      }

      let selectedProduct;
      const products = this.getSelectedYacht.products;
      if(!products.length) return [];
      if (products.length === 1) {
        selectedProduct = products[0];
      } else {
        selectedProduct = products.find(product => product.default_product) || products[0];
      }

      return selectedProduct.extras.filter(prod => !prod.obligatory && !prod.payable_in_base && prod.checked);
    },
    getFlagClass() {
      if (this.getSelectedYacht.base && this.getSelectedYacht.base.country.short) {
        return `fi icon-flag fi-${this.getSelectedYacht.base.country.short.toLowerCase()}`;
      }
      return '';
    },


  },
  async mounted() {
    await this.fetchSelectedYacht().then((res) => {
      let selectedProduct;
      const products = this.getSelectedYacht.products;

     if(products.length){
       if (products.length === 1) {
         selectedProduct = products[0];
       } else {
         selectedProduct = products.find(product => product.default_product) || products[0];
       }
       this.productId = selectedProduct.id;
       this.updateExtrasWithChecked(selectedProduct.extras);
     }
      setTimeout(()=>{
        this.showLoadingIndicator= false;
      }, 700)
    });
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);


    params.forEach((value, key) => {
      if (value === 'true' && !isNaN(key)) {
        this.customer.selectedExtras.push(key);
      }
    });
    const intervalId = setInterval(() => {
      const input = document.querySelector(".phonemask");
      if (input) {
        this.phonemaskInputExists = true;
        clearInterval(intervalId);
      }
    }, 100);
    const customerData = localStorage.getItem('customer');
    if (customerData) {
      this.customer = JSON.parse(customerData);
    }
    ;
    this.loadFromLocalStorage();
    this.checkIfFavorite();
  },

  watch: {
    getSelectedYacht(newVal) {
      if (newVal) {
        const params = new URLSearchParams(window.location.search);
        const checkIn = params.get('date_start');
        this.selectedPeriod = newVal.period_price.find(item => item.date_from.startsWith(checkIn));
      }
    },
    phonemaskInputExists(newVal) {
      if (newVal) {
        const input = document.querySelector(".phonemask");
        intlTelInput(input, {
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.0.4/js/utils.js",
          initialCountry: "it",
          nationalMode: true,
          showSelectedDialCode: true,
        });
      }
    }
  },

}
</script>

<template>
  <LoadingIndicator v-if="showLoadingIndicator"/>
  <template v-else>
    <div class="container">
      <div class="page-nav">
        <BreadCrumbs :base="getSelectedYacht.base?.city"/>
      </div>
      <div class="catalog__mobile-nav"><a class="page-nav__link catalog__filter" href="#"><span>{{
          $t('yacht.info')
        }}</span><img src="/images/filter.svg"></a></div>
      <div class="details-page">
        <div class="sidebar">
          <div class="sidebar__close"><img src="/images/close.svg"></div>
          <div class="booking">
            <div class="sidebar__title">{{ $t('yacht.your_booking_details') }}</div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_pick_up') }}</div>
              <div class="details-item__descr">{{ dateRange.start.dayWeek }} {{ dateRange.start.month }}
                {{ dateRange.start.year }}
              </div>

            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_drop_off') }}</div>
              <div class="details-item__descr">{{ dateRange.end.dayWeek }} {{ dateRange.end.month }}
                {{ dateRange.end.year }}
              </div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.total_length_of_rental') }}</div>
              <div class="details-item__descr">{{ dateRange.daysDifference }} {{ $t('interface.days') }}</div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.yacht_pick-up_and_drop-off_address') }}</div>
              <div class="details-item__descr">{{ getSelectedYacht.base?.name }}</div>
            </div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.charter_company') }}</div>
              <div class="details-item__descr">{{ getSelectedYacht.company?.name }}</div>
            </div>
          </div>
          <div class="summary">
            <div class="sidebar__title">{{ $t('yacht.price_summary') }}</div>
            <div class="details-item " v-if="flexibleCancellation">
              <div class="details-item__name details-item__small details-item__name details-item__small-flex">
                <span>{{ $t('yacht.flexible_cancellation') }}</span> <span>{{
                  Math.round(initialPrice * 0.08)
                }} {{ getSelectedYacht.currency }}</span></div>
            </div>
            <div class="summary__rental"><span>{{ $t('yacht.yacht_rental') }}</span><span>{{
                initialPrice
              }} {{ getSelectedYacht.currency }}</span></div>
            <div class="summary__total"><span>{{ $t('yacht.total_rental_charge') }}</span><span>{{
                summaryPrice
              }} {{ getSelectedYacht.currency }}</span><span>{{ $t('yacht.available') }}</span></div>
            <div class="details-item">
              <div class="details-item__name">{{ $t('yacht.price') }} {{ $t('interface.for') }}
                {{ dateRange.daysDifference }} {{ $t('interface.days') }}
              </div>
              <div class="details-item__descr">{{ $t('yacht.includes_taxes_and_charges') }}</div>
              <div class="note">{{ $t('interface.bingo') }}
                {{ $t('interface.you_just_got_the_best_price_available_online') }}
              </div>
            </div>
            <a class="promocode" href="#"><img src="/images/promocode.svg"
                                               alt="Promo code icon">{{ $t('yacht.do_you_have_a_promo_code') }}</a>
          </div>
          <div class="due-now"><span>{{ $t('yacht.due_now') }}</span><span>{{
              summaryPrice
            }} {{ getSelectedYacht.currency }}</span></div>
          <div class="limited">
            <img src="/images/alarm.svg" alt="Alarm icon">
            <span>{{ $t('yacht.limited_offer_in') }} {{
                getSelectedYacht.country?.name
              }}. {{ $t('yacht.dont_miss_out_to_secure_the_best_price') }} </span>
          </div>
          <div class="schedule">
            <div class="sidebar__title">{{ $t('yacht.payment_schedule') }}</div>
            <div class="schedule__wrapper"><img src="/images/check.svg" alt="Check">
              <div class="schedule__info">
                <div class="schedule__price">{{ summaryPrice }} €</div>
                <div class="schedule__date">{{ $t('yacht.full_payment_by') }} 2 Apr 2024</div>
              </div>
            </div>
          </div>

<!--          <div class="cancel">-->
<!--            <div class="sidebar__title">{{ $t('yacht.how_much_will_it_cost_to_cancel') }}</div>-->
<!--            <div class="cancel__wrapper">-->
<!--              <div class="cancel__item">2 Apr 2024</div>-->
<!--              <span class="cancel__descr">{{ $t('yacht.the_cancellation_fee_is') }} 100% {{ $t('interface.after') }} 2 Apr 2024</span>-->
<!--              <div class="cancel__item">{{ dateRange.end.dayWeek }} {{ dateRange.end.month }} {{ dateRange.end.year }}-->
<!--                ({{ $t('yacht.yacht_pick_up') }})-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="details">
          <div class="details__wrapper"><a class="product" @click="checkYachtDetails(true)">
            <div class="product__left">
              <div class="product__photo"><img v-if="getSelectedYacht.mainImageUrls"
                                               :src="getSelectedYacht.mainImageUrls[400] || getSelectedYacht.mainImageUrls[800]"
                                               :alt="getSelectedYacht.model + ' | ' + getSelectedYacht.name"></div>
            </div>
            <div class="product__right">
              <div class="product__title">
                <span :class="getFlagClass"></span>
                <div class="prodcut__name"> {{ getSelectedYacht.model && getSelectedYacht.model?.name }} |
                  {{ getSelectedYacht.name }}
                </div>
                <div class="add-to-favorite" @click.stop="toggleFavorite(getSelectedYacht)">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"
                        :fill="isFavorite ? '#BF8D37' : 'none'"
                        stroke="#BF8D37"
                        stroke-width="3"
                        stroke-miterlimit="22.9256"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="product__place">{{ getSelectedYacht.base?.city }}, {{ getSelectedYacht.base?.name }}</div>
              <!--            <div class="product__stats">-->
              <!--              <div class="product__rating">4.7</div>-->
              <!--              <div class="product__reviews">3 Reviews</div>-->
              <!--              <div class="product__recently">Recently booked by a customer from <img src="/images/spain.png"></div>-->
              <!--            </div>-->
              <div class="product__info">
                <ul>
                  <li><span>{{ $t('yacht.year') }}</span><span>{{ getSelectedYacht.year }}</span></li>
                  <li><span>{{ $t('yacht.people') }}</span><span>{{ getSelectedYacht.max_people }}</span></li>
                  <li><span>{{ $t('yacht.cabins') }}</span><span>{{ getSelectedYacht.cabins }}</span></li>
                </ul>
                <ul>
                  <li><span>{{ $t('yacht.yacht_type') }}</span><span>{{ getSelectedYacht.type?.name }}</span></li>
                  <li><span>{{ $t('yacht.length') }}</span><span>{{ getSelectedYacht.length }} m</span></li>
                  <li><span>{{ $t('yacht.mainsail') }}</span><span>Classic mainsail</span></li>
                </ul>
              </div>
            </div>
          </a>
            <div class="details__final">
              <h2 class="details__title">{{ $t('yacht.final_details') }}</h2>
              <div class="details__final-info">
                <div class="details__final-item"><span>{{ $t('user.first_name') }}</span><span>{{
                    customer.firstName
                  }}</span></div>
                <div class="details__final-item"><span>{{ $t('user.last_name') }}</span><span>{{
                    customer.lastName
                  }}</span></div>
                <div class="details__final-item"><span>{{ $t('user.email') }}</span><span>{{ customer.email }}</span>
                </div>
                <div class="details__final-item"><span>{{ $t('user.phone') }}</span><span>{{
                    customer.phoneNumber
                  }}</span></div>
              </div>
            </div>
            <div class="details__payment">
              <h2 class="details__title">Payment schedule</h2>
              <div class="details__payment-total"><img src="/images/check.svg">
                <div class="details__payment-info">
                  <div class="details__payment-price">{{ summaryPrice }} €</div>
                  <div class="details__payment-date">Full payment by 2 Apr 2024</div>
                </div>
              </div>
            </div>
            <!--          <div class="details__method">-->
            <!--            <h2 class="details__title">Choose the payment method</h2>-->
            <!--            <label class="payment__row">-->
            <!--              <div class="payment__checkbox">-->
            <!--                <div class="checkbox">-->
            <!--                  <input type="radio" name="radio1"><span class="checkbox__checkmark"></span><span class="checkbox__text"> <img src="/images/cart.svg">Debit  / Credit Card<img src="/images/payments.svg"></span>-->
            <!--                </div>-->
            <!--                <div class="payment__checkbox-descr">Today you pay 2,449  € (100% of total price)</div>-->
            <!--                <div class="payment__checkbox-inputs">-->
            <!--                  <div class="payment__input cart-number"><img src="/images/cart-2.svg">-->
            <!--                    <input type="text" placeholder="1234 1234 1234 1234">-->
            <!--                  </div>-->
            <!--                  <div class="payment__input cart-date">-->
            <!--                    <input type="text" placeholder="MM / YY">-->
            <!--                  </div>-->
            <!--                  <div class="payment__input cart-cvc">-->
            <!--                    <input type="text" placeholder="CVC">-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </label>-->
            <!--            <label class="payment__row">-->
            <!--              <div class="payment__checkbox">-->
            <!--                <div class="checkbox">-->
            <!--                  <input type="radio" name="radio1"><span class="checkbox__checkmark"></span><span class="checkbox__text"> <img src="/images/bank.svg">Bank Transfer</span>-->
            <!--                </div>-->
            <!--                <div class="payment__checkbox-descr">-->
            <!--                  <p>You’ll pay 2,449  € (100% of total price) by 5 April 2024.</p>-->
            <!--                  <p>We will send you the instructions for the bank transfer.</p>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </label>-->
            <!--            <label class="payment__row">-->
            <!--              <div class="payment__checkbox">-->
            <!--                <div class="checkbox">-->
            <!--                  <input type="radio" name="radio1"><span class="checkbox__checkmark"></span><span class="checkbox__text"> <img src="/images/clock.svg">Not sure yet. I will decide later</span>-->
            <!--                </div>-->
            <!--                <div class="payment__checkbox-descr">No problem! While you make your decisionm we will retain this booking until 5 April 2024</div>-->
            <!--              </div>-->
            <!--            </label>-->
            <!--            <div class="payment__links">By reserving this boat, you agree to our <a href="#">Terms and contitions </a>and <a href="#">Privacy policy and cookies</a></div>-->
            <!--          </div>-->
            <div class="details__modal-reservation-details" v-if="obligatoryPayableInBase.length || optionalPayableInBase.length">
              <div class="sidebar__title">{{ $t('yacht.excluded_charges') }}:
                <span>{{ $t('yacht.to_be_paid_at_the_yacht_pick-up') }}1</span></div>
              <div class="details-item " v-for="product in obligatoryPayableInBase" :key="product.id">
                <div class="details-item__name ">{{ product.name }}</div>
                <div class="details-item__descr">{{ product.price }} {{ product.currency }} {{
                    product.unit
                  }} {{ $t('yacht.mandatory').toLowerCase() }}
                </div>
              </div>
              <div class="details-item " v-for="product in optionalPayableInBase" :key="product.id">
                <div class="details-item__name">{{ product.name }}</div>
                <div class="details-item__descr">{{ product.price }} {{ product.currency }} {{
                    product.unit
                  }} {{ $t('yacht.optional').toLowerCase() }}
                </div>
              </div>

            </div>
            <div class="details__modal-reservation-details" v-if="flexibleCancellation || optionalPayableImmediately.length || obligatoryPayableImmediately.length">
              <div class="sidebar__title"  >{{ $t('yacht.included_charges') }}1</div>

              <div class="details-item " v-if="flexibleCancellation">
                <div class="details-item__name ">{{ $t('yacht.flexible_cancellation') }}</div>
                <div class="details-item__descr ">{{ Math.round(initialPrice * 0.08) }} {{ getSelectedYacht.currency }}
                  {{ $t('yacht.per_rental') }} {{ $t('yacht.optional').toLowerCase() }}
                </div>
              </div>
              <div class="details-item " v-for="product in optionalPayableImmediately" :key="product.id">
                <div class="details-item__name ">{{ product.name }}</div>
                <div class="details-item__descr ">{{ product.price }} {{ product.currency }} {{
                    product.unit
                  }} {{ $t('yacht.optional').toLowerCase() }}
                </div>
              </div>
              <div class="details-item " v-for="product in obligatoryPayableImmediately" :key="product.id">
                <div class="details-item__name ">{{ product.name }}</div>
                <div class="details-item__descr">{{ product.price }} {{ product.currency }} {{
                    product.unit
                  }} {{ $t('yacht.optional').toLowerCase() }}
                </div>
              </div>

            </div>
            <div class="summary">
              <div class="sidebar__title">{{ $t('yacht.price_summary') }}</div>
              <div class="summary__rental"><span>{{ $t('yacht.total_rental_charge') }}</span><span>{{
                  summaryPrice
                }} {{ getSelectedYacht.currency }}</span></div>
            </div>

            <div class="reserve"><a class="reserve__guarantees" href="#"><img src="/images/eur.svg">Any Day Charter
              guarantees the lowest price</a><a class="btn" @click="saveBooking"><span>Reserve</span></a>
              <div class="reserve__contacts">We'll send you an instant confirmation to <a
                  :href="emailLink">{{ customer.email }}</a></div>
            </div>
          </div>
          <div class="details__advantages">
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-1.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.always_the_best_price') }}</div>
              <div class="details__advantages-text">{{ $t('interface.no_excuses') }} {{
                  $t('interface.no_hidden_fees')
                }}
              </div>
            </div>
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-2.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.weve_got_your_back') }}</div>
              <div class="details__advantages-text">{{
                  $t('interface.we_take_take_care_of_any_problem_you_might_have')
                }} {{ $t('interface.immediately_and_without_questions') }}
              </div>
            </div>
            <div class="details__advantages-item">
              <div class="details__advantages-icon"><img src="/images/advantages-3.svg"></div>
              <div class="details__advantages-name">{{ $t('interface.sail_more_worry_less') }}</div>
              <div class="details__advantages-text">100% {{ $t('interface.verified_partners_and_boats') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<style scoped>
.details__modal {
  width: 468px;
  position: absolute;
  top: 15%;
  background-color: #ffffff;
  left: 30%;
  margin: auto;
  min-height: 200px;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, .3);
  border-radius: 10px;
  z-index: 999;
}


.details__actions-buttons-item a:last-child {
  text-decoration: none;
}

.details__actions-buttons-item a:last-child span {
  color: white;
}

.noUi-handle {
  background-color: #6c6c6c !important;
}

.noUi-handle.noUi-handle-upper {
  background-color: #6c6c6c !important;
}

.details__modal-title {
  padding: 32px 0;
  font-family: "Adelle Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  color: #BF8D37;
  border-bottom: 1px solid black;
}

.details__modal-customer, .details__modal-boat {
  padding: 40px 50px;
  border-bottom: 1px solid black;
}

.details__modal-reservation-details {
  padding: 15px 50px;

  @media (max-width: 767px) {
    padding: 20px ;
  }
}

.details__modal-subtitle {
  margin-bottom: 25px;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #21084D;
}

.overLay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.details__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
}

.icon-flag {
  margin-right: 5px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}

.details-item__small {
  font-size: 12px;
}

.details-item__small-flex {
  display: flex;
  justify-content: space-between;
}
</style>