<template>
  <div class="page-head">
    <h1 class="page-head__title">{{ content }}</h1>
  </div>
</template>

<script>
export default {
  name: 'H1Content',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
