<script>
export default {
  name: "Offer",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
},
  mounted() {
  }
}
</script>

<template>
  <div class="offer">
    <div class="container">
      <h1>{{ title }}</h1>
      <div class="offer__subtitle">
        {{ subtitle }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.offer {
  @media (max-width: 768px) {
    height: auto;
  }
}
</style>