import HeaderView from './Header.vue';
import FooterView from "@/components/globals/Footer.vue";
import LanguageSelector from "@/components/globals/LanguageSelector.vue";
import BlogView from "@/views/blog/BlogView.vue";
import CountriesView from "@/views/countries/CountriesView.vue";
import TermsView from "@/views/terms/TermsView.vue";
import UnderConstructionView from "@/components/under-construction/UnderConstruction.vue";
import Preloader from "@/components/globals/Preloader.vue";
import MainLayoutView from "@/layout/MainLayout.vue";
import Select2 from 'vue3-select2-component';

const components = [
    {name: 'MainLayoutView', component: MainLayoutView},
    {name: 'HeaderView', component: HeaderView},
    {name: 'FooterView', component: FooterView},
    {name: 'LanguageSelector', component: LanguageSelector},
    {name: 'BlogViewView', component: BlogView},
    {name: 'CountriesViewView', component: CountriesView},
    {name: 'UnderConstructionView', component: UnderConstructionView},
    {name: 'TermsViewView', component: TermsView},
    {name: 'Preloader', component: Preloader},
    {name: 'Select2', component: Select2},
];

export default {
    install(app){
        components.forEach(({ name, component }) => {
            app.component(name, component)
        })
    }
};
