<script>
export default {
  name: "LinksView"
}
</script>

<template>
  <div class="container">
    <div class="page-nav">
      <div class="breadcrumbs"><a class="breadcrumbs__link" href="/en`">Home</a><span class="breadcrumbs__separator">/</span><span class="breadcrumbs__current">Links</span></div>
    </div>
    <div class="page-links">
      <ul>
        <li class="row__link"><a href="https://www.figma.com/design/vcne5jxcgbFijVnIEylTdw/Any-Day-Charter?node-id=0-15&t=IiPufoLjCoOILJwq-0">Дизайн в Figma</a></li>
        <hr>
        <li class="row__link"><a :href="`/${$i18n.locale}/under-construction`">Страница "Находится в разработке"</a> 02 Under Construction</li>
        <li class="row__link"><a href="/en`">Главная страница</a>01 index fix</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/search?destination=san-marino&type=any_type&checkIn=06-28-2024&checkOut=06-29-2024`">Страница "Результаты поиска"</a>02 filter</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/sailing_boat/elan-impression-40-giorgia?checkIn=06-28-2024&checkOut=06-29-2024`">Страница "Карточка"</a>03 yacht</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/countries`">Страница "Страны"</a>04 countries</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/faq`">Страница "Часто задаваемые вопросы"</a>05 How we work</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/terms`">Страница "Условия"</a>06 Terms and Conditions</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/blog`">Страница "Блог"</a>07-1 Blog</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/blog-inner`">Страница "Блог (внутренняя)"</a>07-2 Blog</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/about`">Страница "О нас"</a>08 About</li>
        <li class="row__link"><a>Sign in</a>- модалки готовы, но пока нет регистрации и смены аккаунта - кнопка отображает кабинет сразу</li> 
        <li class="row__link"><a>Create new account</a> - модалки готовы, но пока нет регистрации и смены аккаунта - кнопка отображает кабинет сразу</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/dashboard`">Страница "Панель управления"</a>11 Dashboard</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/dashboard?title=Your+list&index=2`">Страница "Панель управления"</a>12 Your list</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/dashboard?title=Personal+information&index=3`">Страница "Панель управления"</a>13 Personal information}</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/dashboard?title=Settings&index=4`">Страница "Панель управления"</a>14 Settings</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/reviews`">Страница "Отзывы"</a>15 Customer reviews</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/country`">Страница "Страна"</a>16 Croatia</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/details/elan-impression-40-giorgia?checkIn=06-28-2024&checkOut=06-29-2024`">Страница "Детали"</a>18 Enter your deal</li>
        <li class="row__link"><a :href="`/${$i18n.locale}/detailsfinal`">Страница "Детали второй шаг"</a>19 Final details</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.page-links {
  font-size: 16px;
}

.page-links ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 50px;
}

.page-links li {
  margin-bottom: 5px;
}

.page-links a {
  text-decoration: none;
  color: blue;
}

.page-links a:hover {
  text-decoration: underline;
}

.row__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>