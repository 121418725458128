<script>
/**
 * Preloader component that displays a preloader for a specified duration.
 * @module Preloader
 * @vue-prop {boolean} showPreloader - Flag indicating whether the preloader is visible.
 * @vue-prop {number} preloaderTimeout - Timeout ID for controlling the preloader display duration.
 */
export default {
  /**
   * The name of the component.
   * @type {string}
   */
  name: "LoadSpinner",
  data() {
    return {
      showText: false,
      fullText: 'Please select other search parameters',
      typedText: '',
      labelTimeout: null,
      typingTimeout: null
    };
  },
  methods: {
    handleTextLabel() {
      this.labelTimeout = setTimeout(() => {
        this.showText = true;
        this.typeText();
      }, 2000);
    },
    typeText(index = 0) {
      if (index < this.fullText.length) {
        this.typedText += this.fullText.charAt(index);
        this.typingTimeout = setTimeout(() => {
          this.typeText(index + 1);
        }, 50);
      }
    }
  },
  mounted() {
    this.handleTextLabel();
  },
  beforeDestroy() {
    clearTimeout(this.labelTimeout);
    clearTimeout(this.typingTimeout);
  }
};
</script>

<template>
  <div class="load-spinner">
    <div class="preloader__icon preloader__icon--animation">
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2121_3)">
          <path
              d="M42.5854 12.7012C44.3353 15.4622 45.3998 18.5391 45.7789 21.6743L48.5447 24.2748L45.7789 26.8705C45.5213 28.9995 44.9477 31.0945 44.0631 33.0874H39.3967C40.9813 30.3702 41.7736 27.3225 41.7736 24.2748C41.7736 21.3243 41.0348 18.3787 39.5571 15.7344L42.5854 12.7012ZM4.48165 33.0874C3.59699 31.0945 3.02341 28.9995 2.77065 26.8705L0 24.2748L2.77065 21.6791C3.32478 17.0273 5.39062 12.5263 8.95844 8.95844C12.5263 5.39062 17.0273 3.32964 21.6791 2.77065L24.2748 0L26.8705 2.77065C30.0057 3.14493 33.0777 4.20944 35.8386 5.95447L32.8055 8.9876C30.1661 7.50992 27.2204 6.77108 24.2748 6.77108C19.7931 6.77108 15.3164 8.48208 11.8992 11.8992C8.47722 15.3164 6.77108 19.7931 6.77108 24.2748C6.77108 27.3225 7.56339 30.3702 9.14801 33.0874H4.48165Z"
              stroke="#BF8D37"></path>
          <path
              d="M27.5 18L38.0454 7.55371C38.5752 7.99118 39.0905 8.45782 39.5863 8.95362C40.0821 9.44942 40.5487 9.96466 40.9862 10.4944L30.5 21L27.5 18Z"
              stroke="white"></path>
          <path
              d="M26.3503 30.171V41.6521C29.1209 41.3216 31.8284 40.33 34.2199 38.6773V35.7803C37.0246 35.7803 39.8147 35.7803 42.6291 35.7803C41.7784 37.1316 40.7674 38.41 39.5862 39.5863C36.0184 43.1541 31.5173 45.22 26.8704 45.779L24.2747 48.5447L21.6791 45.779C17.0273 45.22 12.5262 43.1541 8.95838 39.5863C7.78207 38.41 6.76617 37.1316 5.91553 35.7803C8.73479 35.7803 11.52 35.7803 14.3247 35.7803V38.6773C16.7162 40.33 19.4237 41.3216 22.1943 41.6521V30.171C19.7639 29.3155 18.0189 26.9969 18.0189 24.2748C18.0189 20.8188 20.8187 18.019 24.2747 18.019C27.7259 18.019 30.5257 20.8188 30.5257 24.2748C30.5257 26.9969 28.7807 29.3155 26.3503 30.171ZM24.2747 22.175C23.113 22.175 22.1749 23.1131 22.1749 24.2748C22.1749 25.4317 23.113 26.3747 24.2747 26.3747C25.4316 26.3747 26.3746 25.4317 26.3746 24.2748C26.3746 23.1131 25.4316 22.175 24.2747 22.175Z"
              stroke="white"></path>
        </g>
        <defs>
          <clippath id="clip0_2121_3">
            <rect width="49" height="49" fill="white"></rect>
          </clippath>
        </defs>
      </svg>
    </div>
    <div class="search__label search__label--animated" v-if="showText">
      {{ typedText }}
    </div>

  </div>
</template>
