<script>
import {BASE} from "@/mixins";
import NoUiSliderCustom from "@/components/partials/NoUiSliderCustom.vue";
import Select2 from "vue3-select2-component";
import SideSearchForm from "@/components/partials/SideSearchForm.vue";
import {mapActions, mapGetters} from "vuex";
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  name: "FullSearch",
  components: {SideSearchForm, NoUiSliderCustom, Select2},
  mixins: [BASE],
  directives: {
    clickOutSide,
  },
  props: {
    limits: {
      type: Object,
      required: true
    },
    showFilter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      manufacturerSearch: '',
      modelSearch: '',
      bareboat: false,
      crewed: false,
      showManufacturers: false,
      showModels: false,
      selectedShipYards: [],
      selectedModels: [],
      selectedProducts: [],
      selectedMainSailTypes: []
    }
  },
  computed: {
    ...mapGetters(["getShipYards", "getMainSailTypes", "getLimits"]),
    filteredManufacturers() {
      const shipYardsArray = Object.values(this.getShipYards);  // Convert object to array
      if (!this.manufacturerSearch) {
        return this.getShipYards;  // Return full list if no search input
      }
      return shipYardsArray.filter(manufacturer =>
          manufacturer.name && manufacturer.name.toLowerCase().includes(this.manufacturerSearch.toLowerCase())
      );
    }
  },
  watch: {
    bareboat(newVal) {
      this.updateUrlParams();
    },
    crewed(newVal) {
      this.updateUrlParams();
    }
  },
  methods: {
    ...mapActions(['fetchYachts']),
    filteredModels(shipyard) {
      const modelsArray = Object.values(shipyard.models);

      if (!this.modelSearch) {
        return modelsArray;
      }

      return modelsArray.filter(model =>
          model.name.toLowerCase().includes(this.modelSearch.toLowerCase())
      );
    },
    updateUrlParams() {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);

      // If both checkboxes are selected or neither is selected, remove the 'product' parameter
      if ((this.bareboat && this.crewed) || (!this.bareboat && !this.crewed)) {
        params.delete('product');
      } else {
        // Otherwise, set the 'product' parameter to the selected option
        if (this.bareboat) {
          params.set('product', 'bareboat');
        } else if (this.crewed) {
          params.set('product', 'crewed');
        }
      }

      history.replaceState(null, '', url.pathname + '?' + params.toString());
      this.fetchYachts();
    },
    filterByManufacturer(manufacturer) {
      const shipyard_id = manufacturer.shipyard_id;

      const indexId = this.selectedShipYards.indexOf(shipyard_id);

      if (indexId !== -1) {
        this.selectedShipYards.splice(indexId, 1);
      } else {
        this.selectedShipYards.push(shipyard_id);
      }
      console.log(this.selectedShipYards)
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);

      // Get current shipyard_ids from the URL and split into an array
      let shipyardIds = params.get('shipyard_ids');
      let shipyardIdsArray = shipyardIds ? shipyardIds.split(',') : [];
      const shipyardIdStr = String(manufacturer.shipyard_id);
      const index = shipyardIdsArray.indexOf(shipyardIdStr);

      if (index !== -1) {
        // If the manufacturer is already in the array, remove it
        shipyardIdsArray.splice(index, 1);
      } else {
        // Otherwise, add it to the array
        shipyardIdsArray.push(shipyardIdStr);
      }

      if (shipyardIdsArray.length > 0) {
        // If there are selected manufacturers, update the URL with the new array
        params.set('shipyard_ids', shipyardIdsArray.join(','));
      } else {
        // If no manufacturers are selected, remove the 'shipyard_ids' parameter
        params.delete('shipyard_ids');
      }
      history.replaceState(null, '', url.pathname + '?' + params.toString());
      this.fetchYachts();
      this.closeModals()
    },
    filterByModel(model) {
      const urlParams = new URLSearchParams(window.location.search);

      const modelIndex = this.selectedModels.indexOf(model.model_id);
      if (modelIndex !== -1) {
        this.selectedModels.splice(modelIndex, 1);
      } else {
        this.selectedModels.push(model.model_id);
      }

      if (this.selectedModels.length > 0) {
        urlParams.set('model_ids', this.selectedModels.join(','));
      } else {
        urlParams.delete('model_ids');
      }

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
      this.fetchYachts();
      this.closeModals()
    },
    filterByMainSailType(type) {
      const urlParams = new URLSearchParams(window.location.search);
      let mainSailTypes = urlParams.get('main_sail_types');
      let mainSailTypesArray = mainSailTypes ? mainSailTypes.split(',') : [];
      const typeIndex = mainSailTypesArray.indexOf(type);

      // Add or remove the type from the URL parameter and selectedMainSailTypes array
      if (typeIndex !== -1) {
        mainSailTypesArray.splice(typeIndex, 1);
        this.selectedMainSailTypes.splice(this.selectedMainSailTypes.indexOf(type), 1);
      } else {
        mainSailTypesArray.push(type);
        this.selectedMainSailTypes.push(type);
      }

      // Update the URL parameter
      if (mainSailTypesArray.length > 0) {
        urlParams.set('main_sail_types', mainSailTypesArray.join(','));
      } else {
        urlParams.delete('main_sail_types');
      }

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
      this.fetchYachts();
      this.closeModals()
    },
    filterByProduct(product){
      console.log(product);
      const urlParams = new URLSearchParams(window.location.search);
      let products = urlParams.get('product');
      let productsArray = products ? products.split(',') : [];

      const productIndex = productsArray.indexOf(product);
      const selectedProductIndex = this.selectedProducts.indexOf(product);

      // Handle product selection/deselection
      if (selectedProductIndex !== -1) {
        // If the product is already selected, remove it from both arrays
        this.selectedProducts.splice(selectedProductIndex, 1);
        productsArray.splice(productIndex, 1);
      } else {
        // If another product is already selected and you add a new one, remove URL param but keep in selectedProducts
        if (productsArray.length === 1) {
          productsArray = [];
          urlParams.delete('product');
        }
        // Add the new product to the selected products list if it's not already there
        this.selectedProducts.push(product);
      }

      // Update the URL parameter only if there's a single product selected
      if (this.selectedProducts.length === 1) {
        urlParams.set('product', this.selectedProducts[0]);
      } else {
        urlParams.delete('product');
      }

      // Update the URL without reloading the page
      const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
      window.history.replaceState(null, '', newUrl);

      // Fetch updated yacht data
      this.fetchYachts();
    },
    openManufacturersList() {
      this.showManufacturers = true;
      this.showModels = false;
    },
    openModelsList() {
      this.showModels = true;
      this.showManufacturers = false;
    },
    closeModals() {
      this.showModels = false;
      this.showManufacturers = false;
    },
    updateSelectedParametersFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const shipyardIds = urlParams.get('shipyard_ids');
      const modelIds = urlParams.get('model_ids');
      const sailTypes = urlParams.get('main_sail_types');
      const product = urlParams.get('product');

      if (shipyardIds) {
        this.selectedShipYards = shipyardIds.split(',').map(Number);
      }
      if (modelIds) {
        this.selectedModels = modelIds.split(',').map(Number);
      }
      if (sailTypes) {
        this.selectedMainSailTypes = sailTypes.split(',').map(type => decodeURIComponent(type));
      }
      if (product) {
        this.selectedProducts = product.split(',').map(type => decodeURIComponent(type));
      }
    }
  },
  mounted() {
    this.updateSelectedParametersFromUrl();
  }
}
</script>

<template>
  <SideSearchForm/>
  <div class="filter" v-if="showFilter">
    <NoUiSliderCustom :min="+limits.cabins_min || 0.1" :max="+limits.cabins_max" :title="$t('yacht.cabins')"
                      param="cabins"
                      v-if="limits.cabins_max"/>
    <NoUiSliderCustom :min="+limits.price_min || 0.1" :max="+limits.price_max" :title="$t('yacht.price')" param="price"
                      v-if="limits.price_max"/>
    <NoUiSliderCustom :min="+limits.people_min || 0.1" :max="+limits.people_max" :title="$t('yacht.people')"
                      param="people"
                      v-if="limits.people_max"/>
    <NoUiSliderCustom :min="+limits.berths_min || 0.1" :max="+limits.berths_max" :title="$t('yacht.berths')"
                      param="berths"
                      v-if="limits.berths_max"/>
    <NoUiSliderCustom :min="+limits.length_min || 0.1" :max="+limits.length_max" :title="$t('yacht.length')"
                      param="length"
                      v-if="limits.length_max"/>
    <NoUiSliderCustom :min="+limits.year_min || 0.1" :max="+limits.year_max" :title="$t('yacht.year')" param="year"
                      v-if="limits.year_max"/>
    <NoUiSliderCustom :min="+limits.wc_min" :max="+limits.wc_max" :title="$t('yacht.toilets')" param="wc"
                      v-if="limits.wc_max"/>
  </div>
  <div class="settings" v-if="showFilter">
    <div class="settings__item">
      <div class="settings__title">{{ $t('yacht.rental_type')}}</div>
      <label class="checkbox" @click.prevent="filterByProduct('bareboat')">
        <input type="checkbox"  :checked="selectedProducts.includes('bareboat')">
        <span class="checkbox__checkmark"></span>
        <span class="checkbox__text"><span>{{ $t('yacht.bareboat')}}</span><span></span></span>
      </label>
      <label class="checkbox" @click.prevent="filterByProduct('crewed')">
        <input type="checkbox" :checked="selectedProducts.includes('crewed')">
        <span class="checkbox__checkmark"></span>
        <span class="checkbox__text"><span>{{ $t('yacht.crewed')}}</span><span></span></span>
      </label>
    </div>
    <div class="settings__item">
      <div class="form-group" @click.stop="openManufacturersList">
        <label>{{ $t('yacht.manufacturer')}}</label>
        <input
            type="text"
            :placeholder="$t('actions.search')"
            v-model="manufacturerSearch"
        >
        <div class="settings__item-manufacturers"
             v-if="showManufacturers"
             v-click-out-side.prevent="closeModals">

          <div class="settings__item-manufacturers-row"
               v-for="(manufacturer, id) in filteredManufacturers"
               :key="id"
               @click.prevent="filterByManufacturer(manufacturer)">
            <label class="checkbox">
              <input type="checkbox"
                     :checked="selectedShipYards.includes(manufacturer.shipyard_id)">
              <span class="checkbox__checkmark"></span>
              <span class="checkbox__text">
          <span>{{ manufacturer.name }}</span>
          <span>({{ manufacturer.count }})</span>
        </span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group" @click.stop="openModelsList">
        <label>Model</label>
        <input
            type="text"
            :placeholder="selectedShipYards.length === 0 ? 'Select manufacturer first' : 'Select model'"
            v-model="modelSearch"
        >
        <div class="settings__item-models" v-if="showModels" v-click-out-side.stop="closeModals">
          <div class="settings__item-models-row" v-for="shipyard in getShipYards" :key="shipyard.shipyard_id">
            <template v-for="model in filteredModels(shipyard)" :key="model.model_id">
              <label class="checkbox"
                     v-if="selectedShipYards.length === 0 || selectedShipYards.includes(shipyard.shipyard_id)"
                     @click.prevent="filterByModel(model)">
                <input type="checkbox" :checked="selectedModels.includes(model.model_id)">
                <span class="checkbox__checkmark"></span>
                <span class="checkbox__text"><span>{{ model.name }}</span><span>({{ model.count }})</span></span>
              </label>
            </template>

          </div>
        </div>
      </div>
      <!--      <div class="form-group">-->
      <!--        <label>Amenities</label>-->
      <!--        <input type="text" :placeholder="$t('actions.search')">-->
      <!--      </div>-->
    </div>
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Review score</div>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>6+ </span><span>(73)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>7+ </span><span>(64)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>8+ </span><span>(60)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>9+ </span><span>(30)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>10 </span><span>(25)</span></span>-->
    <!--      </label>-->
    <!--    </div>-->
    <div class="settings__item">
      <div class="settings__title">{{ $t('yacht.type_of_mainsail')}}</div>
        <label class="checkbox" v-for="(count, type) in getMainSailTypes" :key="type">
          <input type="checkbox" @click="filterByMainSailType(type)" :checked="selectedMainSailTypes.includes(type)">
          <span class="checkbox__checkmark"></span>
          <span class="checkbox__text">
          <span>{{ type }}</span>
<!--          <span>({{ count }})</span>-->
        </span>
      </label>
    </div>
    <!--    TODO: show when filter is ready-->
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Engine</div>-->
    <!--      <div class="range-item__slider" id="rangeSlider8"></div>-->
    <!--      <div class="range-item__values">-->
    <!--        <div id="rangeStart8"></div>-->
    <!--        <div id="rangeEnd8"></div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="settings__item">-->
    <!--      <div class="form-group">-->
    <!--        <label>Charter company</label>-->
    <!--        <input type="text" placeholder="Search">-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="settings__item">-->
    <!--      <div class="settings__title">Services</div>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span class="checkbox__text"><span>Deposit insurance </span><span>(176)</span></span>-->
    <!--      </label>-->
    <!--      <label class="checkbox">-->
    <!--        <input type="checkbox"><span class="checkbox__checkmark"></span><span-->
    <!--          class="checkbox__text"><span>Pet friendly </span><span>(25)</span></span>-->
    <!--      </label>-->
    <!--    </div>-->
  </div>
</template>

<style scoped>
.form-group {
  position: relative;
}

.settings__item-models,
.settings__item-manufacturers {
  position: absolute;
  padding-top: 15px;
  top: 80px;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 450px;
  overflow: scroll;
}

.settings__item-manufacturers-row {
  padding: 5px 10px;
  line-height: 30px;
  cursor: pointer;
}
.settings__item-models-row {
  padding: 0px 10px;
  margin-bottom: 9px;
  line-height: 30px;
  cursor: pointer;
}

.settings__item-manufacturers-row span:last-child,
.settings__item-models-row span:last-child {
  margin-left: 5px;
}

.settings__item-manufacturers::-webkit-scrollbar,
.settings__item-models::-webkit-scrollbar {
  width: 6px;
}

.settings__item-manufacturers::-webkit-scrollbar-thumb,
.settings__item-models::-webkit-scrollbar-thumb {
  background-color: #BF8D37;
  border-radius: 10px;
}

..settings__item-manufacturers::-webkit-scrollbar-track,
..settings__item-models::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
