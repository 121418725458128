/**
 * Formats the state for display with an image.
 * @param {Object} state - The state object containing id and text.
 * @returns {string} The formatted HTML string.
 */
export function formatState(state) {
    if (!state.id) {
        return state.text;
    }
    const baseUrl = "/images/types";
    return `<span class="option-type"><img src="${baseUrl}/${state.text}.svg" alt="logo typo" /> ${state.text}</span>`;
}

/**
 * Checks yacht details and opens a new URL with appropriate parameters.
 */
export function checkYachtDetails(fromDetails = false) {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const locale = this.$i18n.locale;
    const yachtSlug = fromDetails ? this.yacht.slug : this.getSelectedYacht.slug
    const yachtType = fromDetails ? generateSlug(this.yacht.type) : generateSlug(this.yacht.type);
    let newUrl = `/${locale}/${yachtType}/${yachtSlug}`;

    const searchParamsToAdd = ['date_start', 'date_end'];
    searchParamsToAdd.forEach(param => {
        if (params.has(param)) {
            newUrl += (newUrl.includes('?') ? '&' : '?') + `${param}=${params.get(param)}`;
        }
    });

    window.open(newUrl);
}

/**
 * Checks yacht details and opens a new URL with appropriate parameters.
 */
export function checkFavoriteYachtDetail(yacht) {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const locale = this.$i18n.locale;
    const yachtSlug = yacht.slug
    const yachtType = yacht.type.slug;
    let newUrl = `/${locale}/${yachtType}/${yachtSlug}`;


    window.open(newUrl);
}

/**
 * Capitalizes the first letter of a string.
 * @param {string} str - The input string.
 * @returns {string} The string with the first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
    if (!str || str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Capitalizes the first letter of each word in a string.
 * @param {string} str - The input string.
 * @returns {string} The string with each word's first letter capitalized.
 */
export function capitalizeEachWord(str) {
    if (str.length === 0) {
        return str;
    }
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

/**
 * Toggles the favorite status of a yacht.
 * @param {Object} yacht - The yacht object containing a slug.
 */
export function toggleFavorite(yacht) {
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    const yachtIndex = favorites.findIndex(slug => slug === yacht.slug);
    if (yachtIndex >= 0) {
        favorites.splice(yachtIndex, 1);
    } else {
        favorites.push(yacht.slug);
    }
    localStorage.setItem('favorites', JSON.stringify(favorites));
}

/**
 * Replaces "http://" with "https://" in a URL.
 * @param {string} url - The input URL.
 * @returns {string} The URL with "http://" replaced by "https://".
 */
export function replaceHttpWithHttps(url) {
    return url.replace("http://", "https://");
}

/**
 * Converts the values of an object to numbers.
 * @param {Object} obj - The input object with string values.
 * @returns {Object} The object with values converted to numbers.
 */
export function convertValuesToNumbers(obj) {
    const result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            result[key] = Number(obj[key]) || 1;
        }
    }
    return result;
}

/**
 * Converts meters to feet.
 * @param {number} meters - The input value in meters.
 * @returns {string} The value converted to feet.
 */
export function convertToFeet(meters) {
    return (meters * 3.28084).toFixed(2);
}

/**
 * Converts a string from Russian keyboard layout to English keyboard layout.
 * @param {string} input - The input string in Russian layout.
 * @returns {string} The converted string in English layout.
 */
export function convertRussianToEnglish(input) {
    const rusToEngMap = {
        'а': 'f', 'б': ',', 'в': 'd', 'г': 'u', 'д': 'l', 'е': 't', 'ё': '`', 'ж': ';', 'з': 'p',
        'и': 'b', 'й': 'q', 'к': 'r', 'л': 'k', 'м': 'v', 'н': 'y', 'о': 'j', 'п': 'g', 'р': 'h',
        'с': 'c', 'т': 'n', 'у': 'e', 'ф': 'a', 'х': '[', 'ц': 'w', 'ч': 'x', 'ш': 'i', 'щ': 'o',
        'ъ': ']', 'ы': 's', 'ь': 'm', 'э': "'", 'ю': '.', 'я': 'z',
        'А': 'F', 'Б': '<', 'В': 'D', 'Г': 'U', 'Д': 'L', 'Е': 'T', 'Ё': '~', 'Ж': ':', 'З': 'P',
        'И': 'B', 'Й': 'Q', 'К': 'R', 'Л': 'K', 'М': 'V', 'Н': 'Y', 'О': 'J', 'П': 'G', 'Р': 'H',
        'С': 'C', 'Т': 'N', 'У': 'E', 'Ф': 'A', 'Х': '{', 'Ц': 'W', 'Ч': 'X', 'Ш': 'I', 'Щ': 'O',
        'Ъ': '}', 'Ы': 'S', 'Ь': 'M', 'Э': '"', 'Ю': '>', 'Я': 'Z'
    };

    return input.split('').map(char => rusToEngMap[char] || char).join('');
}

/**
 * Generates a URL-friendly slug from a title.
 * @param {string} title - The input title.
 * @returns {string} The generated slug.
 */
export function generateSlug(title) {
    return title
        .toLowerCase()                     // Convert the string to lowercase
        .replace(/[^\w\s-]/g, '')          // Remove all non-word characters (except spaces and hyphens)
        .trim()                            // Trim leading and trailing whitespace
        .replace(/\s+/g, '-')              // Replace spaces with hyphens
        .replace(/-+/g, '-');              // Replace multiple hyphens with a single hyphen
}

/**
 * Formats a timestamp into an object containing the day of the week, day of the month, month, and year.
 * @param {number} timestamp - The input date timestamp in milliseconds.
 * @returns {{dayWeek: string, dayMonth: string, month: string, year: string}} The formatted date details.
 */
export function formatDateInDetails(timestamp) {
    if (!timestamp) {
        return {
            dayWeek: "",
            dayMonth: "",
            month: "",
            year: ""
        };
    }

    const date = new Date(timestamp);
    const optionsDay = {weekday: 'long', day: 'numeric'};
    const optionsMonthYear = {month: 'long', year: 'numeric'};

    const dayFormatted = date.toLocaleDateString('en-US', optionsDay);
    const monthYearFormatted = date.toLocaleDateString('en-US', optionsMonthYear);

    const [dayOfWeek, dayNumber] = dayFormatted.split(', ');
    const [day, dayOfMonth] = dayOfWeek.split(' ');
    const [monthName, yearNumber] = monthYearFormatted.split(' ');

    return {
        dayWeek: day,
        dayMonth: dayNumber,
        month: monthName,
        year: yearNumber
    };
}


/**
 * Creates a new Date object from an object containing day, month, and year.
 * @param {{dayWeek: string, dayMonth: string, month: string, year: string}} obj - The input object containing date details.
 * @returns {Date} The created Date object.
 * @throws {Error} If the date is invalid.
 */
export function newDate(obj) {
    const monthMapping = {
        January: 0,
        February: 1,
        March: 2,
        April: 3,
        May: 4,
        June: 5,
        July: 6,
        August: 7,
        September: 8,
        October: 9,
        November: 10,
        December: 11
    };

    const day = parseInt(obj.dayMonth, 10);
    const month = monthMapping[obj.month];
    const year = parseInt(obj.year, 10);

    const date = new Date(year, month, day);

    if (isNaN(date)) {
        throw new Error("Invalid date");
    }

    return date;
}


/**
 * Calculates the difference in days between two timestamps.
 * @param {number} startTimestamp - The start date timestamp in milliseconds.
 * @param {number} endTimestamp - The end date timestamp in milliseconds.
 * @returns {number} The difference in days.
 */
export function calculateDaysDifference(startTimestamp, endTimestamp) {
    if (!startTimestamp || !endTimestamp) {
        return 0;
    }

    const timeDifference = endTimestamp - startTimestamp;
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return Math.round(daysDifference);
}


/**
 * Calculates the product price based on the unit and price.
 * @param {{unit: string, price: string}} product - The product object.
 * @returns {number} The calculated product price.
 */
export function calculateProductPrice(product) {
    const unitMultiplication = {
        'per booking': 1,
        'per week': 1,
        'per day': 7,
        'per night': 7
    };

    const unit = product.unit;
    const multiplicationFactor = unitMultiplication[unit] || 1;

    return parseFloat(product.price) * multiplicationFactor;
}

/**
 * Scrolls smoothly to an element specified by its class name.
 * @param {string} className - The class name of the element to scroll to.
 */
export function scrollToElement(className) {
    const element = document.querySelector(`.${className}`);
    if (element) {
        element.scrollIntoView({behavior: 'smooth'});
    }
}

/**
 * Returns the appropriate image source URL.
 * If the provided image object contains a valid URL for either '800' or 'original',
 * it will return that URL. Otherwise, it returns the path to a placeholder image.
 *
 * @param {Object} image - The image object containing potential URLs.
 * @param {string} [image.800] - The URL of the image with a resolution of 800px.
 * @param {string} [image.original] - The URL of the original image.
 * @returns {string} The URL of the selected image or the placeholder image.
 */

export function getImageSrc(image) {
    console.log(image)
    if (image && (image['800'] || image['original'])) {
        return image['800'] || image['original'];
    } else {
        return '/images/single-no-image.png';
    }
}