<script>
import BoatCard from "@/components/partials/BoatCard.vue";
import {toggleFavorite} from "@/utilities/service";
import {mapGetters} from "vuex";
import loginForm from "@/components/partials/modals/LoginForm.vue";

export default {
  name: "RequestPrice",
  components: {BoatCard},
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customer: {
        specialRequests: "",
        selectedExtras: [],
        checkIn: "",
        checkOut: ""
      },
      isFavorite: false,
    }
  },
  methods: {
    toggleFavorite,
    closeModal() {
      this.$emit('closeModal');
    },
    checkIfFavorite() {
      const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
      this.isFavorite = favorites.includes(this.yacht.slug);
    },
    async sendRequestForQuote() {
      const params = new URLSearchParams(window.location.search);
      const checkIn = params.get('date_start');
      const checkOut = params.get('date_end');



      const body = {
        yacht_id: this.yacht.id,
        date_from: checkIn,
        product_id: 4519,
        date_to: checkOut,
        first_name: this.customer.firstName,
        last_name: this.customer.lastName,
        email: this.customer.email,
        phone: this.customer.phoneNumber,
        comment: "Request for a qoute: "+this.customer.specialRequests || 'empty',
        price: 0,
        currency: 'none',
        extra_id: [],
      };
      const url = 'https://api.anydaycharter.com/api/order/create';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const locale = this.$i18n.locale;
        const orderCode = data.data.code;
        let newUrl = `/${locale}/thank-you?order=${orderCode}`;
        window.location.href = window.location.href = newUrl;

      } catch (error) {
        console.error('Error:', error);
      }
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
    getFlagClass() {
      if (this.yacht.base && this.yacht.base.country.short) {
        return `fi icon-flag fi-${this.yacht.base.country.short.toLowerCase()}`;
      }
      return '';
    }
  },
  mounted() {
    this.checkIfFavorite();
    console.log(this.getUser);

    this.customer = {
      ...this.getUser,
      ...this.customer
    };

    console.log('this.customer:', this.customer);
  },

  watch: {
    getUser(newVal) {
      this.customer = {
        ...newVal,
        ...this.customer
      };
    }
  }
}
</script>

<template>
  <div class="overlayer" @click="closeModal">
    <div class="modal modal--small" @click.stop>
      <div class="modal__close" @click.stop="closeModal"><img src="/images/modal-close.svg" alt="Close icon"/></div>
      <div class="modal__wrapper">
        <div class="details">
          <div class="details__wrapper">
            <a class="product">
            <div class="product__left">
              <div class="product__photo"><img v-if="yacht.mainImageUrls"
                                               :src="yacht.mainImageUrls[400] || yacht.mainImageUrls[800]"
                                               :alt="yacht.model + ' | ' + yacht.name"></div>
            </div>
            <div class="product__right">
              <div class="product__title">
                <span :class="getFlagClass"></span>
                <div class="prodcut__name"> {{ yacht.model && yacht.model?.name }} |
                  {{ yacht.name }}
                </div>
<!--                <div class="add-to-favorite" @click.stop="toggleFavorite(yacht)">-->
<!--                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path-->
<!--                        :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"-->
<!--                        :fill="isFavorite ? '#BF8D37' : 'none'"-->
<!--                        stroke="#BF8D37"-->
<!--                        stroke-width="3"-->
<!--                        stroke-miterlimit="22.9256"-->
<!--                    ></path>-->
<!--                  </svg>-->
<!--                </div>-->
              </div>
              <div class="product__place">{{ yacht.base?.city }}, {{ yacht.base?.name }}</div>

              <div class="product__info">
                <ul>
                  <li><span>{{ $t('yacht.year') }}</span><span>{{ yacht.year }}</span></li>
                  <li><span>{{ $t('yacht.people') }}</span><span>{{ yacht.max_people }}</span></li>
                  <li><span>{{ $t('yacht.cabins') }}</span><span>{{ yacht.cabins }}</span></li>
                </ul>
                <ul>
                  <li><span>{{ $t('yacht.yacht_type') }}</span><span>{{ yacht.type?.name }}</span></li>
                  <li><span>{{ $t('yacht.length') }}</span><span>{{ yacht.length }} m</span></li>
                  <li><span>{{ $t('yacht.mainsail') }}</span><span>Classic mainsail</span></li>
                </ul>
              </div>
            </div>
          </a>
            <form class="details__form">
              <h2 class="card__heading-name">{{ $t('yacht.enter_your_details') }}</h2>
              <div class="form-group">
                <label>
                  <img src="/images/required.svg">{{ $t('user.first_name') }}</label>
                <input type="text" v-model="customer.firstName" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.last_name') }}</label>
                <input type="text" v-model="customer.lastName" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.email') }}</label>
                <input type="email" v-model="customer.email" required>
              </div>
              <div class="form-group">
                <label><img src="/images/required.svg">{{ $t('user.phone') }}</label>
                <input class="phonemask" type="tel" v-model="customer.phoneNumber" required>
              </div>
              <div class="details__form-descr">
                <span>{{ $t('user.we_will_send_you_an_email_confirmation_to_this_address') }}</span>
                <div class="registration__descr"><img src="/images/required.svg">{{ $t('interface.required_field') }}
                </div>
              </div>
            </form>
            <div class="details__request">
              <div class="details__request-title">{{ $t('user.special_requests') }}</div>
              <div class="details__request-subtitle">
                {{ $t('user.you_can_always_make_a_special_request_after_your_booking_is_completed') }}
              </div>
              <div class="form-group">
                <textarea v-model="customer.specialRequests"></textarea>
              </div>
              <div class="details__actions">
                <div class="details__actions-title">{{ $t('user.dont_worry_you_wont_be_charged_now') }}</div>
                <div class="details__actions-buttons">
                  <div class="details__actions-buttons-item">
                    <a class="btn " @click="sendRequestForQuote">
                      <span class="btn-span">{{ $t('actions.get_a_qoute')}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: relative;
  width: 60%;
  z-index: 1003;
}

.prodcut__name {
  margin-left: 5px;
}
.details {
  width: 100%;
}

.overlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 1002;
  align-self: center;
}
.card__heading-name, .details__request-title {
  font-size: 18px;
}
.btn-span {
  color: white;
}
</style>