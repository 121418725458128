<script>
export default {
  name: "ResetPasswordView",
  data() {
    return {
      user: {
        email: '',
        password: '',
        passwordConfirm: '',
      },
      token: '',
      apiUrl: 'https://api.anydaycharter.com/api/reset-password'
    };
  },
  mounted() {
    // Extract token and email from URL
    const urlParams = new URL(window.location.href);
    const pathParts = urlParams.pathname.split('/');


    this.token = pathParts[pathParts.length - 2];
    this.user.email = decodeURIComponent(pathParts[pathParts.length - 1]);
  },
  methods: {
    async resetPassword() {

      if (this.user.password !== this.user.passwordConfirm) {
        alert(this.$t('errors.passwords_do_not_match'));
        return;
      }

      // Prepare request payload
      const payload = {
        token: this.token,
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.passwordConfirm
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${payload.token}`
        },
        body: JSON.stringify(payload)
      };

      try {
        const response = await fetch(this.apiUrl, options);
        const data = await response.json();

        // Handle response data
        if (response.ok) {
          this.$notify({
            title: "Success!",
            text: "You successfully changed your password",
          });
          localStorage.setItem('authToken', payload.token);
          window.location.href = '/';
        } else {
          // Show error message
          alert(this.$t('errors.password_reset_failed') + ': ' + (data.message || ''));
        }
      } catch (error) {
        console.error(error);
        alert(this.$t('errors.server_error'));
      }
    }
  }
};
</script>

<template>
  <div class="under-construction">
    <div class="container">
      <div class="under-construction__subtitle">{{ $t('actions.you_can_change_your_password_here') }}</div>
      <div class="reset-password">

        <!-- Email Input (Disabled) -->
        <div class="form-group">
          <label>{{ $t('user.email') }}</label>
          <input type="text" v-model="user.email" disabled />
        </div>

        <!-- Password Input -->
        <div class="form-group">
          <label>{{ $t('user.password') }}</label>
          <input type="password" v-model="user.password" />
        </div>

        <!-- Password Confirmation Input -->
        <div class="form-group">
          <label>{{ $t('user.password_confirm') }}</label>
          <input type="password" v-model="user.passwordConfirm" />
        </div>

        <!-- Save Button -->
        <a class="btn" href="#" @click.prevent="resetPassword">
          <span>{{ $t('actions.save') }}</span>
        </a>

      </div>
    </div>
  </div>
</template>

<style scoped>
.reset-password {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 340px;
}
</style>