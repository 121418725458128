<script>
import {checkFavoriteYachtDetail, toggleFavorite} from "@/utilities/service";
import {mapActions, mapGetters} from "vuex";
import {addToFavorites, removeFromFavorites} from "@/api";

export default {
  name: "FavouriteModalCard",
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      isFavoriteRequested:false,
    }
  },
  computed:{
    ...mapGetters(["getUser"])
  },
  methods: {
    checkFavoriteYachtDetail,
    addToFavorites,
    removeFromFavorites,
    toggleFavorite,
    ...mapActions(["getUserInfo", "fetchFavouriteYachts"]),
    async removeFromFavoriteYachts() {
      if (this.isFavoriteRequested) {
        return;
      }
      const authToken = localStorage.getItem('authToken');
      this.isFavoriteRequested = true;
      if (!this.getUser.authentificated) {
        this.toggleFavorite(this.yacht.data)
      } else {
        if (this.getUser.favorites.includes(this.yacht.data.slug)) {

          this.removeFromFavorites(this.yacht.data.slug, authToken).then(() => {
            this.getUser.favorites = this.getUser.favorites.filter(fav => fav.slug !== this.yacht.data.slug);
          });
        } else {
          await this.addToFavorites(this.yacht.slug.data, authToken);
        }
      }

      if (authToken) {
        await this.getUserInfo({accessToken: authToken, innerUser: true});
      }
      this.$emit('trigger-update-in-header');
      this.isFavoriteRequested = false;
      await this.fetchFavouriteYachts();

    },
    getYachtFavouriteImage(yacht) {
      if (!yacht.mainImageUrls) {
        return '/images/yaht-1.png';
      }
      return yacht.mainImageUrls['400'] || yacht.mainImageUrls['800'];

    },
  },
  mounted() {
  }
}
</script>

<template>
  <a class="yaht" href="javascript:void(0)" @click="checkFavoriteYachtDetail(yacht.data)">
    <div class="yaht__remove">
      <img src="/images/close-second.svg" @click.stop="removeFromFavoriteYachts"></div>
    <div class="yaht__image">
      <img :src="getYachtFavouriteImage(yacht.data)" :title="yacht.data.model.name + ' | ' + yacht.data.name" :alt="yacht.data.model.name + ' | ' + yacht.data.name"></div>
    <div class="yaht__info">
      <div class="yaht__name">
        <span :class="'fi icon-flag fi-'+yacht.data.country.short.toLowerCase()"></span>
        <span>{{ yacht.data.model?.name }} <template v-if="yacht.data.name">|</template> {{  yacht.data.name }}</span>
      </div>
      <div class="yaht__place">{{ yacht.data.base?.city }}, {{ yacht.data.base?.name }}</div>
      <div class="product__info">
        <ul>
          <li v-if="yacht.data.year"><span>{{ $t('yacht.year') }}</span><span>{{ yacht.data.year }}</span></li>
          <li v-if="yacht.data.max_people"><span>{{ $t('yacht.people') }}</span><span>{{ yacht.data.max_people }}</span></li>
<!--          <li v-if="yacht.data.cabins"><span>{{ $t('yacht.cabins') }}</span><span>{{ yacht.data.cabins }}</span></li>-->
        </ul>
      </div>
    </div>
  </a>
</template>

<style scoped>
.product__info {
  margin-bottom: 0;
}
.product__info ul {
  display: flex;
  flex-direction: row;
}
.product__info ul li {
  font-size: 12px;
}
.product__info ul li span:nth-child(2) {
  font-size: 13px;
  line-height: 123%;
  color: #050709;
}
.yaht__name {
  font-size: 13px;
}
.yaht__image {
width: 85px;
}
.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}
.yaht__info {
  overflow: hidden;
}
</style>