<script>
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';

export default {
  name: "BaseMapFrame",
  components: {
    GoogleMap,
    Marker,
    InfoWindow
  },
  props: {
    base: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      apiKey: 'AIzaSyD_AWzCnbsg9m9z7bxt4PdGCsFcPEoQAtA',
      center: { lat: 0, lng: 0 },
      infoWindowOptions: {},
      infoWindow: true,
    };
  },
  watch: {
    base: {
      immediate: true,
      handler(newBase) {
        if (newBase) {
          this.center = {
            lat: parseFloat(newBase.latitude),
            lng: parseFloat(newBase.longitude)
          };

        }
      }
    }
  },

  mounted() {
    this.infoWindow = true;
  }
};
</script>

<template>
          <GoogleMap
              :api-key="apiKey"
              style="width: 100%; height: 400px"
              :center="center"
              :zoom="15"
          >
            <Marker :options="{ position: center }" >
            </Marker>
          </GoogleMap>

</template>

<style scoped>
#tab-content--yacht {
  width: 100%;
  margin-top: 20px;
  padding: 0;
}

#modal__map--yacht {
  width: 100%;
}



</style>
