const state = {
    selectedDestination:{}
};

const mutations = {
    SET_SELECTED_DESTINATION(state, selectedDestination) {
        state.selectedDestination = selectedDestination;
    }
};

const actions = {
    setSelectedDestination({ commit }, selectedDestination) {
        commit('SET_SELECTED_DESTINATION', selectedDestination);
    }
};

const getters = {
    getSelectedDestination: state => state.selectedDestination,
};

export default {
    state,
    mutations,
    actions,
    getters
};
