<script>
export default {
  name: "RegistrationForm",
  data() {
    return {
      showForm: false
    }
  }
}
</script>

<template>
  <div class="modal modal--small" id="registration" v-if="showForm">
    <div class="modal__close"><img src="/images/modal-close.svg"/></div>
    <div class="modal__wrapper">
      <div class="modal__title">Create a new account</div>
      <div class="registration">
        <div class="form-group">
          <label>First name</label>
          <input type="text"/>
        </div>
        <div class="form-group">
          <label>Last name</label>
          <input type="text"/>
        </div>
        <div class="form-group">
          <label><img src="/images/required.svg"/>Email</label>
          <input type="text"/>
        </div>
        <div class="form-group">
          <label><img src="/images/required.svg"/>Password</label>
          <input type="text"/>
        </div>
        <div class="registration__descr"><img src="/images/required.svg"/>Required field</div>
        <div class="registration__info">By signing in or creating an account, you agree to our <a href="#">Terms and conditions </a>and <a href="#">Privacy policy </a>and cookies.</div><a class="btn" href="#"><span>Create a new account</span></a>
        <div class="sign-in__devider">or</div><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-1.svg"/></div>
        <div class="sign-in__text">Sign in with Facebook</div></a><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-2.svg"/></div>
        <div class="sign-in__text">Sign in with Google</div></a><a class="sign-in__item" href="#">
        <div class="sign-in__icon"><img src="/images/sign-in-3.svg"/></div>
        <div class="sign-in__text">Sign in with Apple</div></a>
        <div class="sign-in__prompt">Already have an account?</div><a class="sign-in__create" href="#">Sign in</a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>