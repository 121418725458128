<script>
/**
 * Preloader component that displays a preloader for a specified duration.
 * @module Preloader
 * @vue-prop {boolean} showPreloader - Flag indicating whether the preloader is visible.
 * @vue-prop {number} preloaderTimeout - Timeout ID for controlling the preloader display duration.
 */
export default {
  /**
   * The name of the component.
   * @type {string}
   */
  name: "Preloader",

  /**
   * Data properties of the component.
   * @returns {object} The data properties.
   */
  data() {
    return {
      showPreloader: true, // Flag indicating whether the preloader is visible
      preloaderTimeout: null // Timeout ID for controlling the preloader display duration
    };
  },

  /**
   * Lifecycle hook called after the component has been mounted.
   * Starts a timeout to hide the preloader after a specified duration.
   */
  mounted() {
    this.preloaderTimeout = setTimeout(() => {
      this.showPreloader = false;
    }, 2500);
  },

  /**
   * Lifecycle hook called before the component is unmounted.
   * Clears the timeout to prevent memory leaks and hides the preloader.
   */
  beforeUnmount() {
    clearTimeout(this.preloaderTimeout);
    this.showPreloader = false;
  }
};

</script>

<template>
  <div class="preloader" v-if="showPreloader">
    <div class="preloader__icon">
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2121_3)">
          <path
              d="M42.5854 12.7012C44.3353 15.4622 45.3998 18.5391 45.7789 21.6743L48.5447 24.2748L45.7789 26.8705C45.5213 28.9995 44.9477 31.0945 44.0631 33.0874H39.3967C40.9813 30.3702 41.7736 27.3225 41.7736 24.2748C41.7736 21.3243 41.0348 18.3787 39.5571 15.7344L42.5854 12.7012ZM4.48165 33.0874C3.59699 31.0945 3.02341 28.9995 2.77065 26.8705L0 24.2748L2.77065 21.6791C3.32478 17.0273 5.39062 12.5263 8.95844 8.95844C12.5263 5.39062 17.0273 3.32964 21.6791 2.77065L24.2748 0L26.8705 2.77065C30.0057 3.14493 33.0777 4.20944 35.8386 5.95447L32.8055 8.9876C30.1661 7.50992 27.2204 6.77108 24.2748 6.77108C19.7931 6.77108 15.3164 8.48208 11.8992 11.8992C8.47722 15.3164 6.77108 19.7931 6.77108 24.2748C6.77108 27.3225 7.56339 30.3702 9.14801 33.0874H4.48165Z"
              stroke="#BF8D37"></path>
          <path
              d="M27.5 18L38.0454 7.55371C38.5752 7.99118 39.0905 8.45782 39.5863 8.95362C40.0821 9.44942 40.5487 9.96466 40.9862 10.4944L30.5 21L27.5 18Z"
              stroke="white"></path>
          <path
              d="M26.3503 30.171V41.6521C29.1209 41.3216 31.8284 40.33 34.2199 38.6773V35.7803C37.0246 35.7803 39.8147 35.7803 42.6291 35.7803C41.7784 37.1316 40.7674 38.41 39.5862 39.5863C36.0184 43.1541 31.5173 45.22 26.8704 45.779L24.2747 48.5447L21.6791 45.779C17.0273 45.22 12.5262 43.1541 8.95838 39.5863C7.78207 38.41 6.76617 37.1316 5.91553 35.7803C8.73479 35.7803 11.52 35.7803 14.3247 35.7803V38.6773C16.7162 40.33 19.4237 41.3216 22.1943 41.6521V30.171C19.7639 29.3155 18.0189 26.9969 18.0189 24.2748C18.0189 20.8188 20.8187 18.019 24.2747 18.019C27.7259 18.019 30.5257 20.8188 30.5257 24.2748C30.5257 26.9969 28.7807 29.3155 26.3503 30.171ZM24.2747 22.175C23.113 22.175 22.1749 23.1131 22.1749 24.2748C22.1749 25.4317 23.113 26.3747 24.2747 26.3747C25.4316 26.3747 26.3746 25.4317 26.3746 24.2748C26.3746 23.1131 25.4316 22.175 24.2747 22.175Z"
              stroke="white"></path>
        </g>
        <defs>
          <clippath id="clip0_2121_3">
            <rect width="49" height="49" fill="white"></rect>
          </clippath>
        </defs>
      </svg>
    </div>
    <div class="preloader__text">
      <svg width="127" height="37" viewBox="0 0 127 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.87375 36.5447C4.43982 36.5447 3.2392 36.0829 2.27676 35.1496C1.30947 34.2115 0.828247 33.006 0.828247 31.5235C0.828247 30.0361 1.31919 28.816 2.30107 27.8584C3.29267 26.8911 4.49328 26.4099 5.90291 26.4099C6.76813 26.4099 7.57503 26.5898 8.31873 26.9495C9.06243 27.3092 9.66031 27.7904 10.1124 28.4028L8.67842 29.8659C7.93472 28.9715 7.05006 28.5244 6.02443 28.5244C5.20782 28.5244 4.51759 28.8063 3.94887 29.375C3.38502 29.9388 3.09824 30.6534 3.09824 31.5137C3.09824 32.3547 3.38016 33.0546 3.93429 33.6087C4.49328 34.1677 5.18352 34.4448 6.00985 34.4448C7.0695 34.4448 7.97847 33.9976 8.73189 33.0935L10.2047 34.474C9.7478 35.101 9.13048 35.6065 8.35761 35.9808C7.58961 36.36 6.76327 36.5447 5.87375 36.5447Z"
            fill="#BF8D37"></path>
        <path
            d="M22.8527 36.3989H20.6605V26.585H22.8527V30.4201H26.6781V26.585H28.8801V36.3989H26.6781V32.5346H22.8527V36.3989Z"
            fill="#BF8D37"></path>
        <path
            d="M48.3669 36.3989H46.0823L45.4309 34.4206H42.1402L41.4597 36.3989H39.1751L42.8547 26.585H44.7699L48.3669 36.3989ZM44.8477 32.4957L43.7977 29.3848L42.7381 32.4957H44.8477Z"
            fill="#BF8D37"></path>
        <path
            d="M66.4345 36.3989H63.8826L61.9577 32.972H60.8786V36.3989H58.6913V26.585H62.5945C63.7173 26.585 64.5777 26.8912 65.1707 27.4988C65.7637 28.0918 66.0602 28.8452 66.0602 29.7591C66.0602 30.4153 65.8998 30.9986 65.5742 31.5138C65.2534 32.0242 64.7819 32.4082 64.1597 32.6658L66.4345 36.3989ZM62.5799 28.6022H60.8786V31.0326H62.4341C62.8764 31.0326 63.2167 30.9159 63.45 30.6875C63.6882 30.459 63.8048 30.1625 63.8048 29.7979C63.8048 29.4528 63.693 29.166 63.4792 28.9424C63.2604 28.714 62.9639 28.6022 62.5799 28.6022Z"
            fill="#BF8D37"></path>
        <path d="M78.7614 36.3989V28.6945H75.9858V26.585H83.7291V28.6945H80.9633V36.3989H78.7614Z"
              fill="#BF8D37"></path>
        <path
            d="M100.98 36.3989H94.141V26.585H100.995V28.6314H96.3478V30.4493H100.65V32.4811H96.3478V34.3525H100.98V36.3989Z"
            fill="#BF8D37"></path>
        <path
            d="M119.67 36.3989H117.123L115.193 32.972H114.119V36.3989H111.927V26.585H115.835C116.958 26.585 117.818 26.8912 118.411 27.4988C119.004 28.0918 119.3 28.8452 119.3 29.7591C119.3 30.4153 119.14 30.9986 118.814 31.5138C118.494 32.0242 118.022 32.4082 117.4 32.6658L119.67 36.3989ZM115.82 28.6022H114.119V31.0326H115.674C116.117 31.0326 116.457 30.9159 116.69 30.6875C116.924 30.459 117.04 30.1625 117.04 29.7979C117.04 29.4528 116.933 29.166 116.715 28.9424C116.501 28.714 116.199 28.6022 115.82 28.6022Z"
            fill="#BF8D37"></path>
        <path
            d="M5.06686 0.793579H12.3435L20.2909 21.0874H15.8335L14.2781 16.6932H6.84591L5.25643 21.0874H0.828247L7.48267 4.33223H5.06686V0.793579ZM12.9948 13.0671L10.6033 6.31544L8.15832 13.0671H12.9948Z"
            fill="white"></path>
        <path
            d="M94.6029 0.793579H101.879L109.822 21.0874H105.365L103.809 16.6932H96.3819L94.7924 21.0874H90.3643L97.0187 4.33223H94.6029V0.793579ZM102.526 13.0671L100.134 6.31544L97.6943 13.0671H102.526Z"
            fill="white"></path>
        <path
            d="M69.1321 0.793579C72.6075 0.793579 76.083 0.793579 79.5585 0.793579C82.7423 0.793579 85.2213 1.71227 87.0198 3.53993C88.8231 5.3773 89.7321 7.85145 89.7321 10.9818C89.7321 14.1121 88.7988 16.5668 86.9615 18.3702C85.1241 20.1735 82.6062 21.0874 79.3883 21.0874H71.7715V4.33223H69.1321V0.793579ZM76.0004 4.69193V17.189H79.5876C81.4007 17.189 82.8103 16.5911 83.8165 15.4051C84.8276 14.2142 85.3331 12.7122 85.3331 10.8992C85.3331 9.14442 84.8276 7.66674 83.8165 6.4807C82.8103 5.28981 81.4007 4.69193 79.5876 4.69193H76.0004Z"
            fill="white"></path>
        <path
            d="M36.9295 13.3441V0.793579H41.1001V21.0874H38.2176L27.2711 8.64862V21.0874H23.0957V0.793579H25.7837L36.9295 13.3441Z"
            fill="white"></path>
        <path
            d="M57.442 0.793579H62.3271L55.0116 14.0587V21.0874H50.7827V14.0587L43.5499 0.793579H48.4398L52.8972 9.68883L57.442 0.793579Z"
            fill="white"></path>
        <path
            d="M122.115 0.793579H127L119.685 14.0587V21.0874H115.456V14.0587L108.223 0.793579H113.113L117.57 9.68883L122.115 0.793579Z"
            fill="white"></path>
      </svg>
    </div>
  </div>
</template>

<style scoped>

</style>