<template>
    <h3>{{ content }}</h3>
</template>

<script>
export default {
  name: 'H3Content',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>
