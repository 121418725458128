<script>
import Select2 from "vue3-select2-component";
import {mapActions, mapGetters} from "vuex";
import DateRangePicker from "vue3-daterange-picker";
import {BASE} from "@/mixins";
import {formatState, convertRussianToEnglish, capitalizeEachWord} from "@/utilities/service";
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import $ from 'jquery';
import "../../assets/js/daterangepicker.min";
import popularDestinations from "@/constants";

export default {
  name: "SideSearchFormButton",
  components: { DateRangePicker, Select2 },
  mixins: [BASE],
  data() {
    return {
      localRanges: {},
      selectedDestinations: [],
      searchQuery: '',
      isScrolling: false,
      startX: 0,
      scrollLeft: 0,
      queryOptions: {
        boatType: '0',
        destinations: '',
        type: 'any_type',
        dateRange: {
          startDate: new Date(),
          endDate: new Date(),
        },

      },
      recentDestinations: [],
      isSearchDestinationDropdownOpen: false,
      destinationsNotSelected: false,
      isDestinationSelected: false,
      dateIsSet: false,
      isDatePickerIncorrect: false,
      warningMessage: '',
      popularDestinations
    }
  },
  computed: {
    ...mapGetters(['getYachtTypes','getDestinations']),
    filteredDestinations() {
      const arrayOfDestinations = this.queryOptions.destinations.split(",");
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.getDestinations
          .filter(destination => {
            return (destination.name.toLowerCase().includes(searchQueryLower) ||
                    destination.country_name.toLowerCase().includes(searchQueryLower)) &&
                !arrayOfDestinations.some(dest => dest === destination.slug);
          })
          .sort((a, b) => {
            const aNameLower = a.name.toLowerCase();
            const bNameLower = b.name.toLowerCase();
            if (aNameLower.startsWith(searchQueryLower) && !bNameLower.startsWith(searchQueryLower)) {
              return -1;
            } else if (!aNameLower.startsWith(searchQueryLower) && bNameLower.startsWith(searchQueryLower)) {
              return 1;
            } else {
              return 0;
            }
          });
    },
    placeholder() {
      return (this.selectedDestinations && this.selectedDestinations.length) ? "" : capitalizeEachWord(this.$t('actions.where_are_you_going'));
    },
    inputStyle() {
      return (this.selectedDestinations && this.selectedDestinations.length) ? "padding: 0 15px;width:20%" : "padding:0px 5px 0 12px;width:100%";
    },
    showPopularList(){
      return this.popularDestinations.length && !this.searchQuery.length  && !this.selectedDestinations.length;
    },
    showRelatedResultsList(){
      return this.filteredDestinations.length && this.searchQuery.length;
    },
    showRecentSearchesList(){
      return this.recentDestinations.length && !this.searchQuery.length;
    }
  },
  methods: {
    formatState,
    convertRussianToEnglish,
    ...mapActions(['fetchDestinations','fetchYachtTypes', 'fetchYachts',"setSelectedDestination"]),
    setDestination(destination) {
      const indexInSelected = this.selectedDestinations.findIndex(d => d.slug === destination.slug);

      if (indexInSelected === -1) {
        this.selectedDestinations.push(destination);
      } else {
        this.selectedDestinations.splice(indexInSelected, 1);
      }

      const destinationSlugs = this.selectedDestinations.map(destination => destination.slug);


      this.queryOptions.destinations = destinationSlugs.join(",");

      this.destinationsNotSelected = false;
      this.isDestinationSelected = true;
      setTimeout(() => {
        this.isSearchDestinationDropdownOpen = true;
      },0);
      this.searchQuery = "";
      this.saveLastFiveDestinations(destination);
      this.$nextTick(() => {
        const container = this.$refs.tagsContainer;
        if(container){
          container.scrollLeft = container.scrollWidth;
        }
      });
    },
    parseUrlParameters() {
      const params = new URLSearchParams(window.location.search);
      this.queryOptions.destinations = params.get("destinations") || "";
      const selectedDestination = this.getDestinations.find(dest => dest.slug === this.queryOptions.destinations);
      const checkInParam = params.get("checkIn");
      if (checkInParam) {
        this.queryOptions.dateRange.startDate = new Date(checkInParam);
      } else {
        this.queryOptions.dateRange.startDate = new Date();
      }
    },
    startDestinationSelect(){
      this.isSearchDestinationDropdownOpen=true;
    },
    handleSelect(id){
      this.queryOptions.type = this.getYachtTypes.find(type => type.id === id).slug;
    },
    checkRecentDestinations() {
      this.recentDestinations = JSON.parse(localStorage.getItem('destinations')) || [];
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isSearchDestinationDropdownOpen = false;
      }
    },
    startScroll(event) {
      this.isScrolling = true;
      this.startX = event.pageX - this.$refs.tagsContainer.offsetLeft;
      this.scrollLeft = this.$refs.tagsContainer.scrollLeft;
    },
    onScroll(event) {
      if (!this.isScrolling) return;
      event.preventDefault();
      const x = event.pageX - this.$refs.tagsContainer.offsetLeft;
      const walk = (x - this.startX) * 2; // The scroll speed
      this.$refs.tagsContainer.scrollLeft = this.scrollLeft - walk;
    },
    stopScroll() {
      this.isScrolling = false;
    },
    initializeDateRangePicker() {
      const vm = this;
      const that = this;

      $(this.$refs.daterangePicker).daterangepicker({
        opens: "right",
        startDate: this.queryOptions.dateRange.startDate,
        endDate: this.queryOptions.dateRange.endDate,
        minDate: this.queryOptions.dateRange.startDate,
        autoApply: true,
        locale: {
          format: 'DD/MM/YYYY'
        },
        // Disable all dates that are not Saturdays
        isInvalidDate: function(date) {
          // If the day is not Saturday (6), disable it
          return date.day() !== 6;
        }
      }, function(start, end, label) {
        // This callback is called after date selection, updating the range if valid
        vm.queryOptions.dateRange.startDate = start.format('DD/MM/YYYY');
        vm.queryOptions.dateRange.endDate = end.format('DD/MM/YYYY');
      });

      $(this.$refs.daterangePicker).on(
          "show.daterangepicker",
          function (ev, picker) {
            if ($(window).width() < 1024) {
              $("body").addClass("hidden");
            }
          }
      );

      $(this.$refs.daterangePicker).on(
          "hide.daterangepicker",
          function (ev, picker) {
            if ($(window).width() < 1024) {
              $("body").removeClass("hidden");
            }
          }
      );

      $(this.$refs.daterangePicker).on(
          "apply.daterangepicker",
          function(ev, picker) {
            const startDate = picker.startDate;
            const endDate = picker.endDate;

            // Check if both the start and end dates are Saturdays
            if (startDate.day() !== 6 || endDate.day() !== 6) {
              // Mark the picker state as incorrect if dates are not Saturdays

              return;
            }

            // Calculate the number of weeks between start and end date
            const weeks = endDate.diff(startDate, 'weeks');
            if (weeks < 1 || (endDate.diff(startDate, 'days') % 7 !== 0)) {

              return;
            }

            // If the range is valid, update the date range
            vm.queryOptions.dateRange.startDate = startDate.format('DD/MM/YYYY');
            vm.queryOptions.dateRange.endDate = endDate.format('DD/MM/YYYY');
            vm.dateIsSet = true;

          }
      );
    },

    handleInput(event) {
      const input = event.target.value;
      this.searchQuery = this.convertRussianToEnglish(input);
      this.destinationsNotSelected = true;
    },
    saveLastFiveDestinations(destination) {
      let lastFiveDestinations = JSON.parse(localStorage.getItem('destinations')) || [];

      const existingIndex = lastFiveDestinations.findIndex(d => d.slug === destination.slug);
      if (existingIndex !== -1) {
        lastFiveDestinations.splice(existingIndex, 1);
      }

      lastFiveDestinations.unshift(destination);

      if (lastFiveDestinations.length > 5) {
        lastFiveDestinations.pop();
      }

      localStorage.setItem('destinations', JSON.stringify(lastFiveDestinations));

    }
  },
  async mounted() {
    this.initializeDateRangePicker();
    await this.fetchDestinations();
    await this.fetchYachtTypes();
    this.parseUrlParameters();
    this.checkRecentDestinations();
    setTimeout(()=>{
      const container = this.$refs.tagsContainer;
      let isDown = false;
      let startX;
      let scrollLeft;
      if (container) {
        container.addEventListener('mousedown', (e) => {
          isDown = true;
          container.classList.add('active');
          startX = e.pageX - container.offsetLeft;
          scrollLeft = container.scrollLeft;
        });

        container.addEventListener('mouseleave', () => {
          isDown = false;
          container.classList.remove('active');
        });

        container.addEventListener('mouseup', () => {
          isDown = false;
          container.classList.remove('active');
        });

        container.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - container.offsetLeft;
          const walk = (x - startX) * 3;
          container.scrollLeft = scrollLeft - walk;
        });

        container.scrollLeft = container.scrollWidth;
      }
    },100)

  },
  created() {
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  }
}
</script>

<template>
  <div>
  <form class="search">
    <div class="sidebar__title">{{ $t('actions.search') }}</div>
    <div class="search__place">
      <label for="sideSearchInputForm">
      <div class="tags--wrapper">
        <div class="tags--container"
             v-if="selectedDestinations && selectedDestinations.length > 0"
             ref="tagsContainer"
             @mousedown="startScroll"
             @mousemove="onScroll"
             @mouseup="stopScroll">
          <div class="tags" v-for="dest in selectedDestinations" :key="dest.slug">
            <div class="tags__text">
              {{ dest.name }}
            </div>
            <div class="tags__remove" @click="setDestination(dest)">

              <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10.3995" y="12.2426" width="10" height="2" transform="rotate(-135 10.3995 12.2426)" fill="#BF8D37"/>
                <rect x="3.32837" y="10.8284" width="10" height="2" transform="rotate(-45 3.32837 10.8284)" fill="#BF8D37"/>
              </svg>
            </div>
          </div>
        </div>
        <input type="text"
               :style="inputStyle"
               :title="$t('actions.select_your_destinations')"
               :placeholder=placeholder
               v-model="searchQuery"
               @click="startDestinationSelect"
               id="sideSearchInputForm"
               @input="handleInput">
      </div>
      </label>
      <div class="search__dropdown" v-if="isSearchDestinationDropdownOpen" style="max-height: 526px;overflow: hidden">
        <!--        <div class="search__dropdown-title">-->
        <!--          <img src="/images/icons/pin-fill.svg" alt="Pin icon">Around current location-->
        <!--        </div>-->
        <div class="search__dropdown-item" v-if="selectedDestinations.length>0">
          <div class="search__dropdown-item-heading">{{ $t('actions.selected_destinations') }}</div>
          <div class="search__dropdown-row" v-for="selectedDestination in selectedDestinations" @click.prevent="setDestination(selectedDestination)">
            <div class="search__dropdown-name">
              <span :class="'fi icon-flag fi-'+selectedDestination.icon.toLowerCase()"></span>
              {{ selectedDestination.name }}</div>
            <div class="search__dropdown-place">{{ selectedDestination.type }}</div>
            <label class="checkbox" >
              <input type="checkbox" checked disabled><span
                class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>
        <div class="search__dropdown-item" v-if="showRecentSearchesList">
          <div class="search__dropdown-item-heading">{{ $t('interface.recent_searches') }}</div>
          <div class="search__dropdown-row" v-for="selectedDestination in recentDestinations"
               @click.prevent="setDestination(selectedDestination)">
            <div class="search__dropdown-name">
              <span :class="'fi icon-flag fi-'+selectedDestination.icon.toLowerCase()"></span>
              {{ selectedDestination.name }}
            </div>
            <div class="search__dropdown-place">{{ selectedDestination.type }}</div>
            <label class="checkbox">
              <input type="checkbox" disabled><span
                class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>
        <div class="search__dropdown-item" v-if="showPopularList">
          <div class="search__dropdown-item-heading">{{ $t('interface.popular_destinations') }}</div>
          <div class="search__dropdown-row" v-for="selectedDestination in popularDestinations"
               @click.prevent="setDestination(selectedDestination)">
            <div class="search__dropdown-name">
              <span :class="'fi icon-flag fi-'+selectedDestination.icon.toLowerCase()"></span>
              {{ selectedDestination.name }}
            </div>
            <div class="search__dropdown-place">{{ selectedDestination.type }}</div>
            <label class="checkbox">
              <input type="checkbox" disabled><span
                class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>

        <div class="search__dropdown-item" v-else-if="showRelatedResultsList">
          <div class="search__dropdown-item-heading">{{ $t('actions.related_results') }}</div>
          <div class="search__dropdown-row" v-for="destinations in filteredDestinations.slice(0, 8)"
               :key="destinations.slug" @click.prevent="setDestination(destinations)">
            <div class="search__dropdown-name">
              <span :class="'fi icon-flag fi-'+destinations.icon.toLowerCase()"></span>
              {{ destinations.name }}
              <template v-if="destinations.country_name">
                {{ ", " + destinations.country_name }}
              </template>
            </div>
            <div class="search__dropdown-place">{{ destinations.type }}</div>
            <label class="checkbox" >
              <input type="checkbox" disabled><span class="checkbox__checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="search__date" @click="isSearchDestinationDropdownOpen=false">
      <div class="search__label">{{ $t('actions.select_date') }}</div>
      <input type="text" name="daterange" placeholder="Any date"   ref="daterangePicker">
    </div>

    <div class="search__type">
      <div class="search__label">{{ $t('actions.select_yacht_type') }}</div>
      <Select2 v-model="queryOptions.boatType"
               :options="getYachtTypes"
               :settings="{
                 minimumResultsForSearch: -1,
                 templateResult: formatState,
                 templateSelection: formatState, escapeMarkup: function(m) { return m; },
               }"
               @select="handleSelect(queryOptions.boatType)"
               class="select-type"/>
    </div>
    <a class="btn btn-wide" @click.prevent="performSearchBoats(queryOptions, false, dateIsSet)"><span>{{ $t('actions.search') }}</span></a>
  </form>
    <div class="overlay" v-if="isDatePickerIncorrect" v-cloak>
      <div class="modal-warning modal--small" @click.self="isDatePickerIncorrect=false">
        <div>
          <div class="modal__close" @click="isDatePickerIncorrect=false">
            <img src="/images/modal-close.svg"/>
          </div>
          <div class="modal__wrapper">
            <img src="/images/icons/warn.png" alt="Warn icon">
            <div>
              <div class="modal-warning--title">{{ $t('interface.warning')}}</div>
              <div class="modal-warning--text">{{ warningMessage }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.tags--wrapper {
  display: flex;
  align-items: center;
  padding-left: 40px;
  font-size: 13px;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  background-color: white;
}

.tags--container {
  display: flex;
  gap: 3px;
  max-width: 75%;
  overflow-x: auto;
  white-space: nowrap;
  scroll-behavior: smooth;
  transform: translateX(1px);
  cursor: grab;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tags--container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.tags--container:active {
  cursor: grabbing;
}

.tags {
  color: #8A8A8A;
  background-color: #E8E8E8;
  display: flex;
  height: 34px;
  font-family: Adelle Sans,sans-serif;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
  width: max-content;
  max-width: 140px;
  white-space: nowrap;
}

.tags__text {
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.tags__remove {
  height: 100%;
  width: 13px;
  display: flex;
  align-items: center;
  transition: all ease-in-out 300ms;
  cursor: pointer;
}

.tags__remove:hover {
  transform: scale(1.3);
}

.search__dropdown-row {
  background-color: white;
}
.search__date::before {
  z-index: 1;
}

.btn-wide {
  width: 100%;
}
.search__place input {
  text-transform: none;
}
.search__date {
  height: 80px;
}
.sidebar .search__place:before {
  top:0;
}
.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}
input::placeholder {
  color: black;
  font-size: 15px;
}
.search__date input {
  padding: 0 20px 0 50px;
}
.modal__wrapper {
  display: flex;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.modal-warning {
  position: fixed;
  top: 20rem;
  margin: 0 auto;
  width: 600px;
  height: auto;
  overflow: visible;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 1002;
  @media (max-width: 768px) {
    width: 90%;
    left: 5%;
  }
}
.modal__wrapper {
  padding: 0;
}
.modal__wrapper div{
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-warning--title {
  color: #BF8D37;
  font-weight: 600;
  font-size: 24px;
}
.modal-warning--text {
  color: #000;
  font-weight: 400;
  font-size: 15px;
}
input::placeholder {
  color:#000;
  font-size: 15px;
  font-weight: 500;
}
</style>