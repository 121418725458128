<script>
/**
 * LanguageSelector component for selecting and changing the current language.
 * @module LanguageSelector
 * @vue-prop {string} currentLanguage - The current language selected.
 * @vue-prop {Array} languages - The array of available languages.
 */
import { capitalize } from "@vue/shared";
import router from "@/router";
import { mapActions, mapGetters } from "vuex";

export default {
  /**
   * The name of the component.
   * @type {string}
   */
  name: 'LanguageSelector',

  /**
   * The props accepted by the component.
   * @type {object}
   */
  props: {
    /**
     * The current language selected.
     * @type {string}
     */
    currentLanguage: {
      type: String,
      required: true
    },
    /**
     * The array of available languages.
     * @type {Array}
     */
    availableLocales: {
      type: Array,
      required: true
    }
  },

  /**
   * The methods available in the component.
   * @type {object}
   */
  computed: {
    ...mapGetters(['getCurrentLanguage']),
  },
  methods: {
    ...mapActions(['setCurrentLanguage']),
    /**
     * Capitalizes the first letter of a string.
     * @param {string} str - The input string.
     * @returns {string} The capitalized string.
     */
    capitalize,

    /**
     * Changes the current language.
     * @param {string} language - The language to switch to.
     */
    changeLanguage(language) {
      // Set the new language in the i18n locale
      // Set the new language in the i18n locale
      this.$i18n.locale = language;

      // Update the HTML lang attribute
      document.querySelector("html").setAttribute("lang", language);

      // Get the current route's path and query parameters
      const currentRoute = router.currentRoute.value;
      const currentPath = currentRoute.path;
      const currentQuery = currentRoute.query;

      // Construct the new path with the new language
      // Replace the current language prefix with the new one
      const newPath = currentPath.replace(/^\/[a-zA-Z]{2}/, `/${language}`);

      // Push the new route with the updated path and current query parameters
      router.push({ path: newPath, query: currentQuery });

      // Store the new language in localStorage
      localStorage.setItem("user-locale", language);

      // Emit the language change event
      this.$emit('language-change', language);

      // Optionally, you can call a method to update the current language
      this.setCurrentLanguage(language);
    }
  }
};

</script>

<template>
  <div class="language">
    <div class="language__current">{{ capitalize(currentLanguage) }}</div>
    <div class="language__list">
      <a v-for="lang in availableLocales" :key="lang" class="language__link" :class="{ 'active': lang === currentLanguage }"
         @click="changeLanguage(lang.short)">
        <span :class="lang.icon" class="language__flag"></span>
        {{ lang.name }}

      </a>
    </div>
  </div>
</template>
<style>
.language {
  visibility: hidden;
}
.language__flag {
  margin-right: 5px;
}
.language__link {
  cursor: pointer;
}
</style>
