<script>
import {checkYachtDetails} from "@/utilities/service";

export default {
  name: "BoatcardMapPreview",
  methods: {checkYachtDetails},
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
}
</script>

<template>
  <a class="yaht" @click="checkYachtDetails">
    <div class="yaht__image">
      <img src="/images/yaht-1.png"/>
    </div>
    <div class="yaht__info">
      <div class="yaht__name">
        <img src="/images/spain.png"/>
        <span :title=yacht.name>{{ yacht.model }} | {{ yacht.name }}</span>
      </div>
      <div class="yaht__place">{{ yacht.city }}, {{ yacht.base }} </div>
      <object class="yaht__actions">
        <a href="#">{{ $t('actions.view_yacht')}}</a>
        <div class="yaht__rate">
          <span>4.4</span>
          <span>3 {{ $t('yacht.reviews')}}</span>
        </div>
      </object>
    </div>
  </a>
</template>

<style scoped>
.yaht {
  cursor:pointer;
}
</style>