import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import MainLayout from "@/layout/MainLayout.vue";
import UnderConstruction from "@/components/under-construction/UnderConstruction.vue";
import i18n from "@/assets/plugins/i18n";
import BlogView from "@/views/blog/BlogView.vue";
import CountriesView from "@/views/countries/CountriesView.vue";
import ReviewsView from "@/views/reviews/ReviewsView.vue";
import FaqView from "@/views/faq/FaqView.vue";
import BlogInnerView from "@/views/blog/BlogInnerView.vue";
import DashboardView from "@/views/dashboard/DashboardView.vue";

import DetailsView from "@/views/details/DetailsView.vue";
import DetailsViewStepTwo from "@/views/details/DetailsViewStepTwo.vue";
import SearchView from "@/views/search/SearchView.vue";
import BoatView from "@/views/search/BoatView.vue";
import LinksView from "@/views/links/LinksView.vue";
import RichTemplate from "@/views/RichTemplate.vue";
import ThankYou from "@/views/details/ThankYou.vue";
import OfferView from "@/views/offers/OfferView.vue";
import ResetPasswordView from "@/views/dashboard/ResetPasswordView.vue";

const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view />'
    },
    children: [
      {
        path: '',
        name: 'HomeView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'HomeView',
            component: HomeView
          }
        ]
      },
      {
        path: 'about',
        name: 'AboutView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'AboutView',
            component: AboutView
          }
        ]
      },
      {
        path: 'blog-page',
        name: 'BlogView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'BlogView',
            component: BlogView
          }
        ]
      },
      {
        path: 'countries',
        name: 'CountriesView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'CountriesView',
            component: CountriesView
          }
        ]
      },
      {
        path: ':slug',
        name: 'RichTemplate',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'RichTemplate',
            component: RichTemplate
          }
        ]
      },
      {
        path: 'reviews',
        name: 'ReviewsView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'ReviewsView',
            component: ReviewsView
          }
        ]
      },
      {
        path: 'faq',
        name: 'FaqView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'FaqView',
            component: FaqView
          }
        ]
      },
      {
        path: 'blog-inner',
        name: 'Blog-innerView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'Blog-innerView',
            component: BlogInnerView
          }
        ]
      },
      {
        path: 'offers/:id',
        name: 'offerView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'OfferView',
            component: OfferView
          }
        ]
      },
      {
        path: 'dashboard',
        name: 'DashboardView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'DashboardView',
            component: DashboardView
          }
        ]
      },
      {
        path: 'details/:slug',
        name: 'DetailsView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'DetailsView',
            component: DetailsView
          }
        ]
      },
      {
        path: 'detailsfinal/:slug',
        name: 'DetailsViewStepTwo',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'DetailsViewStepTwo',
            component: DetailsViewStepTwo
          }
        ]
      },
      {
        path: 'search',
        name: 'SearchView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'SearchView',
            component: SearchView
          }
        ]
      },
      {
        path: ':yachtType/:yachtSlug',
        name: 'BoatView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'BoatView',
            component: BoatView,
            props: true
          }
        ]
      },
      {
        path: 'thank-you',
        name: 'ThankYou',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'ThankYou',
            component: ThankYou
          }
        ]
      },
      {
        path: 'links',
        name: 'LinksView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'LinksView',
            component: LinksView
          }
        ]
      },
      {
        path: 'reset-password/:id/:email',
        name: 'ResetPasswordView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'ResetPasswordView',
            component: ResetPasswordView
          }
        ]
      },
      {
        path: 'under-construction',
        name: 'UnderConstructionView',
        component: MainLayout,
        children: [
          {
            path: '',
            name: 'UnderConstructionView',
            component: UnderConstruction
          }
        ]
      }
    ],
  },
  {
    path: '/',
    redirect: `/${i18n.global.locale.value}`
  },
  {
    path: '/robots.txt',
    beforeEnter() {
      window.location.href = '/robots.txt';
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: to => {
      const lang = to.params.lang || i18n.global.locale.value || 'en';
      return `/${lang}/under-construction`;
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
