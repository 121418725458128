<template>
  <div id="app">
    <MainLayout />
    <notifications />
  </div>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
import {Notifications} from "@kyvg/vue3-notification";

export default {
  name: 'App',
  components: {Notifications, MainLayout},
}
</script>
