<template>
  <div class="floating-menu" :class="{ expanded: isExpanded }">
    <div class="menu-icon" @click="toggleMenu">&#9776;</div>
    <ul v-if="isExpanded" class="menu-list" @click.self="collapseMenu">
      <li v-for="(link, index) in links" :key="index">
        <a :href="link.url" @click.prevent="navigate(link.url)">{{ link.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LinksAnchor",
  data() {
    return {
      isExpanded: false,
      links: [
        { label: "All links", url: `/${this.$i18n.locale}/links` }
      ]
    };
  },
  methods: {
    toggleMenu() {
      this.isExpanded = !this.isExpanded;
    },
    collapseMenu() {
      this.isExpanded = false;
    },
    navigate(url) {
      window.location.href = url;
    }
  }
};
</script>

<style scoped>
.floating-menu {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 999;
}

.floating-menu.expanded {
  right: 170px;
}

.menu-icon {
  cursor: pointer;
  padding: 10px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-list li {
  padding: 5px 10px;
}

.menu-list li a {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.menu-list li a:hover {
  color: #007bff;
}
</style>
