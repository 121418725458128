import axios from 'axios';
import store from '@/store';

const URI_API = "https://api.anydaycharter.com/api";

const state = {
    favouriteYachts: []
};

const mutations = {
    SET_FAVOURITE_YACHTS(state, favouriteYachts) {
        state.favouriteYachts = favouriteYachts;
    }
};

const actions = {
    async fetchFavouriteYachts({commit}) {
        try {
            const isUserAuthenticated = store.getters['getUser'].authentificated;
            let favorites = [];
            if (isUserAuthenticated) {
                favorites = store.getters['getUser'].favorites || [];
            } else {
                favorites = JSON.parse(localStorage.getItem('favorites')) || [];
            }

            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;

            const yachtRequests = favorites.map(slug => {
                return axios.get(`${URI_API}/yacht/${slug}`, {
                    headers: {
                        "Accept": "application/json",
                        'Authorization': `Bearer ${tokenBearer}`
                    }
                }).then(response => response.data)
                    .catch(error => {
                        console.error(`Error fetching yacht with slug ${slug}:`, error);
                        return null;
                    });
            });

            const yachts = await Promise.all(yachtRequests);
            const validYachts = yachts.filter(yacht => yacht !== null);
            commit('SET_FAVOURITE_YACHTS', validYachts);
        } catch (error) {
            console.error("Error fetching favourite yachts:", error);
        }
    }
};

const getters = {
    getFavouriteYachts: state => state.favouriteYachts
};

export default {
    state,
    mutations,
    actions,
    getters
};
