<script>
import MainSearch from "@/components/partials/MainSearch.vue";
import Offer from "@/components/partials/homepage/Offer.vue";
import CountryCard from "@/components/partials/homepage/CountryCard.vue";
import {mapActions, mapGetters} from "vuex";
import {BASE} from "@/mixins";
import $ from "jquery";
import Swiper from "swiper";
import {FreeMode, Pagination, Virtual} from "swiper/modules";

export default {
  name: 'HomeView',
  components: {CountryCard, Offer, MainSearch},
  mixins:[BASE],
  data() {
    return {
      pageData: {},
      blogList: [],
      cityBanners: [],
      yachtProposalFirstTab:[],
      yachtProposalSecondTab:[],
      yachtProposalThirdTab:[],
    }
  },
  computed: {
    ...mapGetters(["getCountries", "getCurrentLanguage", "getYachtTypes"])
  },
  methods: {
    ...mapActions(['fetchCountries','fetchYachtTypes']),
    async fetchHomePage() {
      const locale = this.$i18n.locale;
      const url = `https://api.anydaycharter.com/api/page/${locale}/home`;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        this.pageData = data.mainContent;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBlogListingPage() {
      const locale = this.$i18n.locale;

      const url = 'https://api.anydaycharter.com/api/blogs/' + locale;
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.blogList) {
          this.blogList = data.blogList.slice(0, 3);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCitiesBanners() {
      const url = 'https://api.anydaycharter.com/api/cities-on-main-page';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        this.cityBanners = await response.json();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProposalYachts(){
      const url = 'https://api.anydaycharter.com/api/yacht-main-page';
      const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        }
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        const allYachts = data.data;
        allYachts.forEach(item => {
          switch(item.category_on_main_page) {
            case 1:
              this.yachtProposalFirstTab.push(item);
              break;
            case 2:
              this.yachtProposalSecondTab.push(item);
              break;
            case 3:
              this.yachtProposalThirdTab.push(item);
              break;
            default:
              console.warn(`Unknown category: ${item.category_on_main_page}`);
          }
        });
        await this.$nextTick(() => {
          this.initialisingProposalYachts();
          this.initialisingCitiesBanners();
          const width = window.innerWidth;

          if (width <= 767) {
            this.initialisingCountriesBanners();
          }
        })
      } catch (error) {
        console.error(error);
      }
    },
    getBlogImage(blog) {
      if (!blog.mainContent.image) {
        return '/images/blog-1.png';
      }
        return blog.mainContent.image['800'] || blog.mainContent.image['original'];
    },
    getCityImage(city) {
      if (!city.image) {
        return '/images/town-1.png';
      }

      const width = window.innerWidth;

      if (width <= 400) {
        return   city.image['400'] || city.image['original'];
      } else  {
        return city.image['800'] || city.image['original'];
      }
    },
    getYachtProposalImage(yacht) {
      if (!yacht.mainImageUrls) {
        return '/images/specials-1.png';
      }
        return yacht.mainImageUrls['800'] || yacht.mainImageUrls['original'];

    },
    initialisingProposalYachts(){
      $(".tabs__content.active .specials__item").css("opacity", "1");

      $(".tabs__caption").on("click", "li:not(.active)", function () {
        var $tabs = $(this).closest(".tabs");
        var $activeTab = $tabs.find(".tabs__content.active");
        var $targetTab = $tabs.find(".tabs__content").eq($(this).index());

        $(this).addClass("active").siblings().removeClass("active");

        $tabs.find(".tabs__content").removeClass("active");

        $targetTab.addClass("active");

        $targetTab.find(".specials__item").each(function (index) {
          var $gridItem = $(this);
          if ($gridItem.css("opacity") != 1) {
            $gridItem.delay(200 * index).animate({ opacity: 1 }, 500);
          }
        });

        $activeTab.find(".specials__item").css("opacity", 0);
      });

      var swiper = new Swiper(".specials__slider", {
        modules: [Pagination],
        slidesPerView: 4,
        spaceBetween: 17,
        loop: true,
        pagination: {
          el: ".specials__slider .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          },
          1025: {
            slidesPerView: 4,
          },
        },
      });
    },
    initialisingCitiesBanners(){
      var swiper = new Swiper(".towns__gallery", {
        modules: [Pagination, FreeMode, Virtual],
        slidesPerView: 6,
        spaceBetween: 17,
        watchSlidesProgress: true,
        slideFullyVisibleClass: "swiper-slide--full",
        slidesOffsetBefore: -139,
        loop: true,
        pagination: {
          el: ".towns__gallery .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesOffsetBefore: 0,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 17,
            slidesOffsetBefore: -129,
          },
          1024: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: 5,
            slidesOffsetBefore: -119,
          },
          1640: {
            slidesPerView: "auto",
          },
        },
      });
    },
    initialisingCountriesBanners(){
      var swiper = new Swiper(".countries__gallery", {
        modules: [Pagination, FreeMode, Virtual],
        slidesPerView: 6,
        spaceBetween: 17,
        watchSlidesProgress: true,
        slideFullyVisibleClass: "swiper-slide--full",
        slidesOffsetBefore: -139,
        loop: true,
        pagination: {
          el: ".countries__gallery .swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesOffsetBefore: 0,
          },
          768: {
            slidesPerView: 3,
          }
        },
      });
    },
    savePageState() {
      sessionStorage.setItem('pageState', JSON.stringify({
        path: this.$route.path,
        scrollTop: window.scrollY-1100
      }));
    },
    restorePageState() {
      const savedState = JSON.parse(sessionStorage.getItem('pageState'));
      if (savedState) {
        this.$router.push(savedState.path);
        this.$nextTick(() => {
          setTimeout(() => {
            window.scrollTo(0, savedState.scrollTop);
          }, 800);
        });
      }
    }
  },
  async mounted() {
    this.restorePageState();
    await this.fetchCountries();
    await this.fetchHomePage();
    await this.fetchBlogListingPage();
    await this.fetchCitiesBanners();

    await this.fetchProposalYachts();
    await this.fetchYachtTypes();

  },
  watch: {
    getCurrentLanguage(newLang, oldLang) {
      this.fetchHomePage();
    },
    '$route'(to, from) {
      this.savePageState();
    }
  },
}
</script>

<template>
  <div>
    <Offer v-if="pageData && pageData.offer_title && pageData.offer_subtitle"  :title="pageData.offer_title" :subtitle="pageData.offer_subtitle"/>
    <div class="container">
      <MainSearch/>
      <div class="grid">
        <div class="heading">
          <div class="heading__name">{{ pageData.countries_name }}</div>
          <h2 class="heading__title">{{ pageData.countries_title }}</h2>
        </div>
        <div class="grid__wrapper grid__wrapper--desktop">
          <CountryCard v-for="(country, index) in getCountries" :country=country :index="index" :key="index"/>
        </div>
        <div class="swiper countries__gallery">

          <div class="swiper-wrapper" >
            <CountryCard class="swiper-slide" v-for="(country, index) in getCountries" :country=country :index="index" :key="index"/>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="towns">
      <div class="container">
        <div class="heading">
          <div class="heading__name">{{ pageData.marines_name }}</div>
          <h2 class="heading__title">{{ pageData.marines_title }}</h2>
        </div>
      </div>
      <div class="swiper towns__gallery">
        <div class="swiper-wrapper">
          <a class="swiper-slide" href="javascript:void(0)" v-for="city in cityBanners" :key="city.id" @click="performSearchBoats({destinations: city.destination, type: `any_type`, boatType: `any_type` }, true)">
            <div class="towns__image">
              <img :src="getCityImage(city)" :alt="city.image ? city.image.alt : city.name" :title="city.name"></div>
            <div class="towns__info">
              <div class="towns__name">{{ city.name }}</div>
              <div class="towns__descr">{{ $t('home.sailing_boats_in')}} {{ city.country }}</div>
            </div>
          </a>

          </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="insurance">
      <div class="container">
        <div class="insurance__icon"><img src="/images/insurance.svg"></div>
        <div class="insurance__info">
          <h2 class="insurance__title">{{ pageData.insurance_title }}</h2>
          <div class="insurance__descr" v-html="pageData.insurance_description"></div>
          <a class="btn btn--small" :href="`/${$i18n.locale}/about`"><span>{{ pageData.insurance_button }}</span></a>
        </div>
      </div>
    </div>
    <div class="specials">
      <div class="container">
        <div class="heading">
          <div class="heading__name">{{ pageData.specials_name }}</div>
          <h2 class="heading__title">{{ pageData.specials_title }}</h2>
        </div>
        <div class="specials__descr" v-html="pageData.specials_description">
        </div>
      </div>
      <div class="specials__tabs tabs">
        <div class="container">
          <ul class="specials__tabs-caption tabs__caption">
            <li class="active"><span>{{ $t('yacht.category1') }}</span><span>{{ yachtProposalFirstTab.length }}</span></li>
            <li><span>{{ $t('yacht.category2') }}</span><span>{{ yachtProposalSecondTab.length }}</span></li>
            <li><span>{{ $t('yacht.category3') }}</span><span>{{ yachtProposalThirdTab.length }}</span></li>
          </ul>
          <div class="tabs__content active">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="yacht in yachtProposalFirstTab" :key="yacht.id">
                  <a class="specials__item" :href="`/${$i18n.locale}/${yacht.type.slug}/${yacht.slug}`">
                  <div class="specials__img"><img :src="getYachtProposalImage(yacht)" :alt="yacht.country.name">
                    <div class="specials__info">
                      <div class="specials__name"> {{ yacht.name }}</div>
                      <div class="specials__place">
                        <span :class="'fi icon-flag fi-'+yacht.country.short.toLowerCase()"></span>
                        {{ yacht.country.name }}, {{ yacht.base }}</div>
                    </div>
                  </div>
                  <div class="specials__stat"><span>{{ yacht.length }} m ⁄ 33.91 ft</span><span>{{ yacht.max_people }} {{ $t('yacht.persons') }}</span><span>{{ yacht.year }}</span>
                  </div>
<!--                  <div class="specials__price">{{ $t('interface.from') }} {{ yacht.min_price }} EUR / {{ $t('interface.week') }}</div>-->
                  <div class="specials__price">{{ $t('user.request_a_quote') }}</div>
                </a>
                </div>


              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="tabs__content">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="yacht in yachtProposalSecondTab" :key="yacht.id">
                  <a class="specials__item" :href="`/${$i18n.locale}/${yacht.type.slug}/${yacht.slug}`">
                    <div class="specials__img"><img :src="getYachtProposalImage(yacht)" :alt="yacht.country.name">
                      <div class="specials__info">
                        <div class="specials__name"> {{ yacht.name }}</div>
                        <div class="specials__place"> <span :class="'fi icon-flag fi-'+yacht.country.short.toLowerCase()"></span>{{ yacht.country.name }}, {{ yacht.base }}</div>
                      </div>
                    </div>
                    <div class="specials__stat"><span>{{ yacht.length }} m ⁄ 33.91 ft</span><span>{{ yacht.max_people }} {{ $t('yacht.persons') }}</span><span>{{ yacht.year }}</span>
                    </div>
<!--                    <div class="specials__price">{{ $t('interface.from') }} {{ yacht.min_price }} EUR / {{ $t('interface.week') }}</div>-->
                    <div class="specials__price">{{ $t('user.request_a_quote') }}</div>
                  </a>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="tabs__content">
            <div class="specials__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="yacht in yachtProposalThirdTab" :key="yacht.id">
                  <a class="specials__item" :href="`/${$i18n.locale}/${yacht.type.slug}/${yacht.slug}`">
                    <div class="specials__img"><img :src="getYachtProposalImage(yacht)" :alt="yacht.country.name">
                      <div class="specials__info">
                        <div class="specials__name"> {{ yacht.name }}</div>
                        <div class="specials__place"> <span :class="'fi icon-flag fi-'+yacht.country.short.toLowerCase()"></span>{{ yacht.country.name }}, {{ yacht.base }}</div>
                      </div>
                    </div>
                    <div class="specials__stat"><span>{{ yacht.length }} m ⁄ 33.91 ft</span><span>{{ yacht.max_people }} {{ $t('yacht.persons') }}</span><span>{{ yacht.year }}</span>
                    </div>
<!--                    <div class="specials__price">{{ $t('interface.from') }} {{ yacht.min_price }} EUR / {{ $t('interface.week') }}</div>-->
                    <div class="specials__price">{{ $t('user.request_a_quote') }}</div>
                  </a>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <a class="btn" href="#"><span>{{ pageData.specials_button }}</span></a>
    </div>
    <div class="lowest-price">
      <div class="container">
        <div class="lowest-price__icon">
          <img src="/images/lowest-price.svg"></div>
        <div class="lowest-price__info">
          <div class="lowest-price__title">{{ pageData.lowest_price_title }}</div>
          <div class="lowest-price__text" v-html="pageData.lowest_price_description"></div>
          <a class="btn btn--middle" href="#"><span>{{ pageData.lowest_price_button }}</span></a>
        </div>
      </div>
    </div>

    <div class="type">
      <div class="container">
        <div class="heading">
          <div class="heading__name">{{ pageData.boat_types_name }}</div>
          <h2 class="heading__title">{{ pageData.boat_types_title }}</h2>
        </div>
        <div class="type__wrapper">
          <div class="type__item">
            <div class="type__info">
              <div class="type__number">01</div>
              <div class="type__descr">
                <div class="type__icon"><img src="/images/type-icon-1.svg"></div>
                <div class="type__text">
                  <div class="type__name">{{ $t('interface.typeboat1') }}</div>
                  <div class="type__stat" v-if="getYachtTypes[7]">{{ getYachtTypes[7].yachts_count }} {{ $t('yacht.yachts') }}</div>
<!--                  <div class="type__stat">{{ $t('interface.typeboat_text1') }}</div>-->
                </div>
              </div>
            </div>
            <div class="type__img"><img src="/images/type-1.webp"></div>
          </div>
          <div class="type__item">
            <div class="type__info">
              <div class="type__number">02</div>
              <div class="type__descr">
                <div class="type__icon"><img src="/images/type-icon-2.svg"></div>
                <div class="type__text">
                  <div class="type__name">{{ $t('interface.typeboat2') }}</div>
                  <div class="type__stat" v-if="getYachtTypes[2]">{{ getYachtTypes[5].yachts_count }} {{ $t('yacht.yachts') }}</div>
<!--                  <div class="type__stat">{{ $t('interface.typeboat_text2') }}</div>-->
                </div>
              </div>
            </div>
            <div class="type__img"><img src="/images/type-2.webp"></div>
          </div>
          <div class="type__item">
            <div class="type__info">
              <div class="type__number">03</div>
              <div class="type__descr">
                <div class="type__icon"><img src="/images/type-icon-3.svg"></div>
                <div class="type__text">
                  <div class="type__name">{{ $t('interface.typeboat3') }}</div>
                  <div class="type__stat" v-if="getYachtTypes[2]">{{ getYachtTypes[2].yachts_count }} {{ $t('yacht.yachts') }}</div>
<!--                  <div class="type__stat">{{ $t('interface.typeboat_text3') }}</div>-->
                </div>
              </div>
            </div>
            <div class="type__img"><img src="/images/type-3.webp"></div>
          </div>
          <div class="type__item">
            <div class="type__info">
              <div class="type__number">04</div>
              <div class="type__descr">
                <div class="type__icon"><img src="/images/type-icon-4.svg"></div>
                <div class="type__text">
                  <div class="type__name">{{ $t('interface.typeboat4') }}</div>
                  <div class="type__stat" v-if="getYachtTypes[6]">{{ getYachtTypes[6].yachts_count }} {{ $t('yacht.yachts') }}</div>
<!--                  <div class="type__stat">{{ $t('interface.typeboat_text4') }}</div>-->
                </div>
              </div>
            </div>
            <div class="type__img"><img src="/images/type-4.webp"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="news">
      <div class="container">
        <div class="heading">
          <div class="heading__name">{{ pageData.news_name }}</div>
          <h2 class="heading__title">{{ pageData.news_title }}</h2>
        </div>
      </div>
      <div class="news__part">
        <div class="container">
          <div class="news__items">
            <a class="news__item" :href="`/${$i18n.locale}/${blog.slug}`" v-for="blog in blogList" :key="blog.id" @click="savePageState">
              <div class="news__image" v-if="blog.mainContent && blog.mainContent.image">
                <img :src="getBlogImage(blog)" :alt="blog.mainContent.image.alt || blog.title" :title="blog.title">
              </div>
              <div class="news__title">{{ blog.title }}</div>
              <div class="news__preview" v-if="blog.mainContent">{{ blog.mainContent.about }}
              </div>
              <div class="news__link">{{ $t('interface.more_info') }} ...</div>
            </a>
          </div>
        </div>
      </div>
      <div class="partners">
        <div class="container">
          <div class="heading">
            <h2 class="heading__name">{{ pageData.our_partners_name }}</h2>
          </div>
          <div class="partners__wrapper">
            <div class="swiper-button-prev"></div>
            <div class="partners__slider swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide"><img src="/images/partners/1-Navigare-Yachting.png"></div>
                <div class="swiper-slide"><img src="/images/partners/2-North-Sardinia-Sail.png"></div>
                <div class="swiper-slide"><img src="/images/partners/3-Dream-Yacht-Charter.png"></div>
                <div class="swiper-slide"><img src="/images/partners/4-Hermes-Yachting.png"></div>
                <div class="swiper-slide"><img src="/images/partners/5-Angelina-Tours.png"></div>
                <div class="swiper-slide"><img src="/images/partners/6-Istion-Yachting.png"></div>
                <div class="swiper-slide"><img src="/images/partners/7-Nomicos-Yachts.png"></div>
                <div class="swiper-slide"><img src="/images/partners/8-Pitter%20Yachtcharter.png"></div>
                <div class="swiper-slide"><img src="/images/partners/9-GR-Sailing.png"></div>
                <div class="swiper-slide"><img src="/images/partners/10-Alboran-Charter.png"></div>
                <div class="swiper-slide"><img src="/images/partners/11-Yacht-Voyage.png"></div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
  margin-right: 4px;
  margin-bottom: 2px;
}


</style>


