export const BASE = {
    methods: {
        /**
         * Converts a date string from "DD/MM/YYYY" format to "YYYY-MM-DD" format.
         * @param {string} dateString - The date string in "DD/MM/YYYY" format.
         * @returns {string} The date string in "YYYY-MM-DD" format.
         */
        convertDateFormatForRequestToDb(dateString) {
            const [day, month, year] = dateString.split('/');

            const formattedDay = day.padStart(2, '0');
            const formattedMonth = month.padStart(2, '0');

            return `${year}-${formattedMonth}-${formattedDay}`;
        },
        /**
         * Converts a date string from "YYYY-MM-DD" format to "DD/MM/YYYY" format.
         * @param {string} dateString - The date string in "YYYY-MM-DD" format.
         * @returns {string} The date string in "DD/MM/YYYY" format.
         */
        convertDateFormatFromDbToUrl(dateString) {
            const [year, month, day] = dateString.split('-');

            const formattedDay = day.padStart(2, '0');
            const formattedMonth = month.padStart(2, '0');

            return `${formattedDay}/${formattedMonth}/${year}`;
        },
        /**
         * Performs a search for boats based on the specified options and redirects to the search results page.
         * @param {object} options - The options for the boat search.
         * @param {string} options.destinations - The destinations selected for the yacht rental.
         * @param {Date} options.startDate - The start date of the yacht rental.
         * @param {Date} options.endDate - The end date of the yacht rental.
         * @param {Boolean} justCountry - Simple Search in a Country
         * @param dateIsSet
         * @param {string} options.boatType - The type of yacht selected for rental.
         */
        performSearchBoats(options, justCountry = false, dateIsSet = false) {
            const {...otherParams} = options;
            if (!justCountry) {
                if (this.destinationsNotSelected || !otherParams.destinations?.trim() || !this.isDestinationSelected) {
                    this.destinationsNotSelected = true;
                    this.queryOptions.destinations = '';
                    this.isSearchDestinationDropdownOpen = true;
                    return;
                }
                if (dateIsSet) {
                    otherParams.date_start = this.convertDateFormatForRequestToDb(otherParams.dateRange.startDate);
                    otherParams.date_end = this.convertDateFormatForRequestToDb(otherParams.dateRange.endDate);
                    delete otherParams.dateRange;
                }

            }

            let redirectUrl = '/'+this.$i18n.locale +`/search`;


            delete otherParams.daterange;
            delete otherParams.dateRange;
            delete otherParams.boatType;


            const queryParams = new URLSearchParams();

            for (const key in otherParams) {
                if (otherParams[key]) {
                    queryParams.append(key, otherParams[key]);
                }
            }

            redirectUrl += '?' + queryParams.toString();
            window.location.href = redirectUrl;
        },

    }
};