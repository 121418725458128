<template>
  <div v-html="content" class="rich-template" style="color: #6c6c6c"></div>
</template>

<script>
export default {
  name: 'RichTextContent',
  props: {
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style>
.rich-template ol li {
  margin-left: 15px;
  margin-bottom: 5px;
}

.rich-template ul li {
  margin-left: 15px;
  margin-bottom: 5px;
  list-style-type: disc;
}
</style>