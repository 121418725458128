<script>
import intlTelInput from "intl-tel-input";
import 'intl-tel-input/build/css/intlTelInput.css'
import {mapActions, mapGetters} from "vuex";
import FavouriteBoatCard from "@/components/partials/FavouriteBoatCard.vue";
import {capitalizeFirstLetter} from "../../utilities/service";

export default {
  name: "DashboardView",
  components: { FavouriteBoatCard },
  data() {
    return {
      activeLink: 0,
      pageTitle: "Dashboard",
      phonemaskInputExists: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      isPasswordVisible: false,
    }
  },
  computed:{
    ...mapGetters(["getFavouriteYachts", "getUser", "getToken"]),
    activeUser(){
      return { ...this.getUser };
    }
  },
  methods: {
    capitalizeFirstLetter,
    ...mapActions(["fetchFavouriteYachts"]),
    setActiveLink(index, title) {
      this.activeLink = index;
      this.pageTitle = title;

      const params = new URLSearchParams(window.location.search);

      params.set('title', title);
      params.set('index', index);

      history.replaceState(null, '', `?${params.toString()}`);
    },
    async updateAccountData() {
      const url = 'https://api.anydaycharter.com/api/update-profile';
      const userData = {
        display_name: this.activeUser.display_name,
        first_name: this.activeUser.first_name,
        last_name: this.activeUser.last_name,
        births_day: this.activeUser.births_day,
        phone: this.activeUser.phone,
      };

      const tokenBearer = this.getToken;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${tokenBearer}`
        },
        body: JSON.stringify(userData)
      };
      try {
        const response = await fetch(url, options);
        const data = await response.json();
      } catch (error) {
        console.error(error);
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    async updatePassword() {
      if (!this.oldPassword) {
        this.$notify({
          type: 'warning',
          title: 'Warning',
          text: 'Old password is not present.',
        });
        return;
      }
      if (this.newPassword.length<8) {
        this.$notify({
          type: 'warning',
          title: 'Warning',
          text: 'New password must be 8-symbols minimum.',
        });
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        this.$notify({
          type: 'warning',
          title: 'Warning',
          text: 'New password and confirmation do not match.',
        });
        return;
      }

      const url = 'https://api.anydaycharter.com/api/update-password';
      const authToken = localStorage.getItem('authToken');

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          old_password: this.oldPassword,
          password: this.newPassword,
          password_confirmation: this.confirmPassword,
        }),
      };

      try {
        const response = await fetch(url, options);
        const data = await response.json();
        if (data.success) {
          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Password updated successfully.',
          });
        } else {
          this.$notify({
            type: 'warning',
            title: 'warning',
            text: data.message || 'Failed to update password.',
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          title: 'Error',
          message: 'An error occurred while updating the password.',
        });
      }
    },
  },
  watch: {
    phonemaskInputExists(newVal) {
      if (newVal) {
        const input = document.querySelector(".phonemask");
        intlTelInput(input, {
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/20.0.4/js/utils.js",
          initialCountry: "it",
          nationalMode: true,
          showSelectedDialCode: true,
        });
      }
    }
  },
  mounted() {
    const intervalId = setInterval(() => {
      const input = document.querySelector(".phonemask");
      if (input) {
        this.phonemaskInputExists = true;
        clearInterval(intervalId);
      }
    }, 100);

    setTimeout(()=>{
      this.fetchFavouriteYachts();
    }, 1000)
    const params = new URLSearchParams(window.location.search);

    const storedTitle = params.get('title');
    const storedIndex = params.get('index');

    if (storedTitle && storedIndex) {
      this.activeLink = parseInt(storedIndex); // Преобразуем строку в число
      this.pageTitle = storedTitle;
    }
  }
}
</script>

<template>
  <div class="cabinet-page">
    <div class="container">
      <div class="page-head">
        <h1 class="page-head__title">{{ pageTitle }}</h1>
      </div>
      <div class="cabinet">
        <div class="cabinet__sidebar">
          <a href="javascript:void(0);" class="cabinet__sidebar-link"
             :class="{ 'active': activeLink === 0 }"
             @click="setActiveLink(0, 'Dashboard')">
            <div class="cabinet__sidebar-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.2525 20H26.6324C27.3216 20 27.8858 20.5624 27.8858 21.2517V38.7483C27.8858 39.4376 27.3216 40 26.6324 40H21.2525C20.5633 40 19.999 39.4376 19.999 38.7483V21.2517C19.999 20.5624 20.5633 20 21.2525 20Z"
                      stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M33.3667 32.1149H38.7466C39.4358 32.1149 40.0001 32.6774 40.0001 33.3666V38.7483C40.0001 39.4375 39.4358 39.9999 38.7466 39.9999H33.3667C32.6775 39.9999 32.1133 39.4375 32.1133 38.7483V33.3666C32.1133 32.6774 32.6775 32.1149 33.3667 32.1149Z"
                      stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M33.3667 20H38.7466C39.4358 20 40.0001 20.5624 40.0001 21.2517V26.6333C40.0001 27.3226 39.4358 27.8868 38.7466 27.8868H33.3667C32.6775 27.8868 32.1133 27.3226 32.1133 26.6333V21.2517C32.1133 20.5624 32.6775 20 33.3667 20Z"
                      stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
              </svg>
            </div>
            <div class="cabinet__sidebar-text">{{ $t('interface.dashboard') }}</div>
          </a>
          <a href="javascript:void(0);" class="cabinet__sidebar-link"
             :class="{ 'active': activeLink === 1 }"
             @click="setActiveLink(1, 'Booking')">
            <div class="cabinet__sidebar-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M38.7483 40H21.2517C20.5624 40 20 39.4376 20 38.7483V21.2517C20 20.5624 20.5624 20 21.2517 20H38.7483C39.4375 20 40 20.5624 40 21.2517V38.7483C40 39.4376 39.4375 40 38.7483 40Z"
                      stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M24.8965 24.896H35.1045" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M24.8965 30.0008H35.1045" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M24.8965 35.104H29.9996" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
              </svg>
            </div>
            <div class="cabinet__sidebar-text">{{ $t('interface.booking') }}</div>
          </a>
          <a href="javascript:void(0);" class="cabinet__sidebar-link"
             :class="{ 'active': activeLink === 2 }"
             @click="setActiveLink(2, 'Your list')">
            <div class="cabinet__sidebar-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M23.5421 29.9237L31.7099 21.7112C33.9916 19.4296 37.6907 19.4296 39.9706 21.7112C42.2522 23.9929 42.2522 27.6902 39.9706 29.9719L39.9634 29.979L29.9996 40L20.0286 29.9719C17.747 27.6902 17.747 23.9929 20.0286 21.7112C22.3085 19.4296 26.0076 19.4296 28.2875 21.7112"
                    stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
              </svg>
            </div>
            <div class="cabinet__sidebar-text">{{ $t('interface.your_list') }}</div>
          </a>
          <a href="javascript:void(0);" class="cabinet__sidebar-link"
             :class="{ 'active': activeLink === 3 }"
             @click="setActiveLink(3, 'Personal information')">
            <div class="cabinet__sidebar-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M28.2979 31.8082L22.8182 37.2899C22.1818 37.9243 22.0031 38.8297 22.3468 39.6585C22.6905 40.4893 23.4565 41 24.3541 41H35.6455C36.5431 41 37.309 40.4893 37.6527 39.6585C37.9965 38.8297 37.8158 37.9243 37.1814 37.2899L26.7463 26.8548C24.9492 25.0577 24.9492 22.145 26.7463 20.3478C28.5434 18.5507 31.4561 18.5507 33.2532 20.3478C35.0504 22.145 35.0504 25.0577 33.2532 26.8548L31.6997 28.4064"
                    stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
              </svg>
            </div>
            <div class="cabinet__sidebar-text">{{ $t('interface.personal_information') }}</div>
          </a>
          <a href="javascript:void(0);" class="cabinet__sidebar-link"
             :class="{ 'active': activeLink === 4 }"
             @click="setActiveLink(4, 'Settings')">
            <div class="cabinet__sidebar-icon">
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M29.9983 21.3153C34.7942 21.3153 38.684 25.2031 38.684 30.001C38.684 34.7969 34.7942 38.6848 29.9983 38.6848C25.2023 38.6848 21.3145 34.7969 21.3145 30.001C21.3145 25.2031 25.2023 21.3153 29.9983 21.3153Z"
                      stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M29.998 43V38.6848" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M17 30.001H21.3152" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M29.998 17V21.3152" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M42.9997 30.001H38.6826" stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
                <path d="M39.1929 39.1923L36.1406 36.1399" stroke="#BF8D37" stroke-width="2"
                      stroke-miterlimit="22.9256"></path>
                <path d="M20.8066 39.1923L23.857 36.1399" stroke="#BF8D37" stroke-width="2"
                      stroke-miterlimit="22.9256"></path>
                <path d="M20.8066 20.8078L23.857 23.8601" stroke="#BF8D37" stroke-width="2"
                      stroke-miterlimit="22.9256"></path>
                <path d="M39.1929 20.8078L36.1406 23.8601" stroke="#BF8D37" stroke-width="2"
                      stroke-miterlimit="22.9256"></path>
                <path
                    d="M29.9994 34.4154C27.5613 34.4154 25.585 32.439 25.585 30.001C25.585 27.5611 27.5613 25.5847 29.9994 25.5847"
                    stroke="#BF8D37" stroke-width="2" stroke-miterlimit="22.9256"></path>
              </svg>
            </div>
            <div class="cabinet__sidebar-text">{{ $t('user.settings') }}</div>
          </a><a class="cabinet__sidebar-other" href="#">{{ $t('user.change_password') }}</a><a class="cabinet__sidebar-other" href="#">{{ $t('user.newsletter') }}</a>
        </div>
        <div class="cabinet__body">
          <div class="cabinet__dashboard" v-if="activeLink === 0">
            <div class="cabinet__dashboard-name">{{ $t('user.hello') }} {{ capitalizeFirstLetter(activeUser.firstName) }} {{ capitalizeFirstLetter(activeUser.lastName) }},</div>
            <div class="cabinet__dashboard-text">{{ $t('user.nice_to_see_you') }}</div>
          </div>
          <div class="cabinet__list" v-if="activeLink === 2">

            <FavouriteBoatCard v-for="yacht in getFavouriteYachts" :yacht="yacht"/>
          </div>
          <div v-if="activeLink === 3">
            <form class="cabinet__data" @submit.prevent="updateAccountData">
              <div class="cabinet__data-item">
                <div class="form-group">
                  <label>{{ $t('user.display_name') }}</label>
                  <input type="text" v-model="activeUser.display_name">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.date_of_birth') }}</label>
                  <input type="date" v-model="activeUser.births_day">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.first_name') }}</label>
                  <input type="text" v-model="activeUser.first_name">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.last_name') }}</label>
                  <input type="text" v-model="activeUser.last_name">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.phone') }}</label>
                  <input class="phonemask" type="tel" v-model="activeUser.phone">
                </div>
              </div>
              <div class="cabinet__data-item">
                <div class="cabinet__data-title">{{ $t('user.address') }}</div>
                <div class="form-group">
                  <label>{{ $t('user.street') }}</label>
                  <input type="text">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.city') }}</label>
                  <input type="text">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.postal_code') }}</label>
                  <input type="text">
                </div>
                <div class="form-group">
                  <label>{{ $t('user.country') }}</label>
                  <input type="text">
                </div>
              </div>
              <button class="btn" type="submit"><span>{{ $t('actions.save') }}</span></button>
            </form>
          </div>
          <div v-if="activeLink === 4">
            <form class="cabinet__settings">
              <div class="cabinet__data-item">
                <div class="cabinet__data-title">{{ $t('user.email') }}</div>
                <div class="cabinet__data-mail"><img src="/images/mail.svg">{{ activeUser.email }}</div>
              </div>
              <div class="cabinet__data-item">
                <div class="cabinet__data-title">{{ $t('user.change_password') }}</div>

                <div class="form-group">
                  <label><img src="/images/required.svg">{{ $t('user.old_password') }}</label>
                  <input type="password" v-model="oldPassword" autocomplete="current-password">
                </div>

                <div class="form-group form-group--password">
                  <label><img src="/images/required.svg">{{ $t('user.new_password') }}</label>
                  <input :type="isPasswordVisible ? 'text' : 'password'" v-model="newPassword" autocomplete="new-password">
                  <div class="toggle-pass" @click="togglePasswordVisibility"></div>
                </div>

                <div class="form-group form-group--password">
                  <label><img src="/images/required.svg">{{ $t('user.password_confirm') }}</label>
                  <input :type="isPasswordVisible ? 'text' : 'password'" v-model="confirmPassword" autocomplete="new-password">
                  <div class="toggle-pass" @click="togglePasswordVisibility"></div>
                </div>

                <button class="btn" @click.prevent="updatePassword"><span>{{ $t('actions.save') }}</span></button>
              </div>
              <div class="cabinet__data-item">
                <div class="cabinet__data-title">{{ $t('actions.newsletter_subscription') }}</div>
                <label class="checkbox">
                  <input type="checkbox"><span class="checkbox__checkmark"></span><span class="checkbox__text">{{ $t('actions.line_thousands_of_other_sailors_i_would_like_to_receive_fresh_updates_and_special_discounts_by_email') }}</span>
                </label>
              </div>
            </form>
            <a class="cabinet__remove" href="#"><img src="/images/remove-acc.svg" alt="Remove account link"><span>{{ $t('actions.click_here') }} </span>{{ $t('actions.to_remove_your_account') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.toggle-pass {
  cursor: pointer;
}
</style>