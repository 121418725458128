<script>
export default {
  name: "FaqView"
}
</script>

<template>
  <div class="container">
    <div class="page-nav">
      <div class="breadcrumbs"><a class="breadcrumbs__link" href="#">Home</a><span class="breadcrumbs__separator">/</span><span class="breadcrumbs__current">How we work</span></div>
    </div>
    <div class="faq">
      <div class="faq__head">
        <div class="faq__head-icon"><img src="/images/faq.svg"></div>
        <div class="faq__head-wrapper">
          <h2 class="faq__head-title">How our online booking service works</h2>
          <div class="faq__head-subtitle">Quick answers to the most common questions</div>
        </div>
      </div>
      <div class="card__faq">
        <div class="card__faq-item">
          <div class="card__faq-name">What is a Transit Log? </div>
          <div class="card__faq-text">To rent the boat Bavaria C42 | New, you need a valid sailing license. You can always verify the validation of your licence with us.</div>
        </div>
        <div class="card__faq-item">
          <div class="card__faq-name">What licences do I need to rent boat Bavaria C42 | New?</div>
          <div class="card__faq-text">To rent the boat Bavaria C42 | New, you need a valid sailing license. You can always verify the validation of your licence with us.</div>
        </div>
        <div class="card__faq-item">
          <div class="card__faq-name">What payment methods are accepted for services and extras booked on the spot?</div>
          <div class="card__faq-text">To rent the boat Bavaria C42 | New, you need a valid sailing license. You can always verify the validation of your licence with us.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>